import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError, showSuccess} from '../../../common/components/ui/snack-bar/notificationSlice';
import {NcmCommonProtocol, NcmNewEntity, NcmProtocolsNetworkGroup} from '../../../services/NcmApiService';
import {AppDispatch, RootState} from '../../../store';

const priorityAndNameSorter = (a: NcmProtocolsNetworkGroup, b: NcmProtocolsNetworkGroup) => {
    if (a.priority === b.priority) {
        return (a.name.localeCompare(b.name));
    }

    return a.priority - b.priority;
};

interface ProtocolsSlice {
    networkGroups: NcmProtocolsNetworkGroup[];
    selectedOrganizationId: number;
    selectedNetworkGroupId: number | null;
    selectedProtocolName: string | null;
    guessNetworkGroup: string | null;
}

const initialState: ProtocolsSlice = {
    networkGroups: [],
    selectedOrganizationId: 0,
    selectedNetworkGroupId: null,
    selectedProtocolName: null,
    guessNetworkGroup: null,
};

const protocols = createSlice({
    name: 'protocols', initialState, reducers: {
        setNetworkGroups(state, {payload}: PayloadAction<NcmProtocolsNetworkGroup[]>) {
            const networkGroups = payload.sort(priorityAndNameSorter);
            const guessedNetworkGroup = networkGroups.find((i) => i.name === state.guessNetworkGroup);
            const selectedNetworkGroup = networkGroups.find((i) => i.id === state.selectedNetworkGroupId);

            if (guessedNetworkGroup) {
                state.guessNetworkGroup = null;
                state.selectedNetworkGroupId = guessedNetworkGroup.id;
                state.selectedProtocolName = state.selectedProtocolName || guessedNetworkGroup.protocolSet.protocols[0].name;
            } else if (selectedNetworkGroup) {
                state.selectedNetworkGroupId = selectedNetworkGroup.id;
                state.selectedProtocolName = state.selectedProtocolName || selectedNetworkGroup.protocolSet.protocols[0].name;
            } else if (networkGroups.length) {
                state.selectedNetworkGroupId = networkGroups[0].id;
                state.selectedProtocolName = state.selectedProtocolName || networkGroups[0].protocolSet.protocols[0].name;
            } else {
                state.selectedNetworkGroupId = null;
                state.selectedProtocolName = null;
            }

            state.networkGroups = networkGroups;
        },
        setSelectedOrganizationId(state, action: PayloadAction<number>) {
            state.selectedOrganizationId = action.payload;
            if (!action.payload) {
                state.networkGroups = [];
                state.selectedNetworkGroupId = null;
                state.selectedProtocolName = null;
            }
        },
        setSelectedNetworkGroupId(state, {payload}: PayloadAction<NcmProtocolsNetworkGroup>) {
            state.selectedNetworkGroupId = payload.id;
            state.selectedProtocolName = payload.protocolSet.protocols[0].name;
        },
        deleteNetworkGroupSuccess(state, {payload}: PayloadAction<number>) {
            state.networkGroups = state.networkGroups.filter(g => g.id !== payload);
            state.selectedNetworkGroupId = state.networkGroups[0].id;
        },
        setSelectedProtocolName(state, {payload}: PayloadAction<string>) {
            state.selectedProtocolName = payload;
        },
        setNetworkGroupAddresses(state, {payload}: PayloadAction<{networkGroupId: number, ipAddresses: string[]}>) {
            state.networkGroups.filter((i) => i.id === payload.networkGroupId)
                .forEach((i) => i.networkAddress.addresses = payload.ipAddresses);
        },
        setNetworkGroupName(state, {payload}: PayloadAction<{networkGroupId: number, name: string}>) {
            state.networkGroups.filter(g => g.id === payload.networkGroupId)
                .forEach((i) => i.name = payload.name);
        },
        setNetworkGroupProtocolEnabledState(state, {payload}: PayloadAction<{networkGroupId: number, protocolName: string, enabled: boolean}>) {
            const {networkGroupId, protocolName, enabled} = payload;

            state.networkGroups.filter((i) => i.id === networkGroupId)
                .flatMap((i) => i.protocolSet.protocols)
                .filter((i) => i.name === protocolName)
                .forEach((i) => i.enabled = enabled);
        },
        setGuessNetworkGroup(state, {payload}: PayloadAction<string | null>) {
            state.guessNetworkGroup = payload;
        },
        cleanUp: () => {
            return initialState;
        },
    },
});


export const fetchNetworkGroups = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const organizationId = getState().protocols.selectedOrganizationId;
        const networkGroupsResp = await TraverseApiService.ncm.getProtocolsNetworkGroups(organizationId);
        dispatch(setNetworkGroups(networkGroupsResp.data));
    } catch (err: FixType) {
        dispatch(showError('Failed to fetch Network groups', err));
        dispatch(setNetworkGroups([]));
    }
};

export const saveNetworkGroup = (networkGroup: NcmNewEntity<NcmProtocolsNetworkGroup>) => async (dispatch: AppDispatch) => {
    try {
        await TraverseApiService.ncm.saveProtocolsNetworkGroup(networkGroup);
        dispatch(setGuessNetworkGroup(networkGroup.name));
        await dispatch(fetchNetworkGroups());
        dispatch(showSuccess('Network group saved'));
    } catch (err) {
        dispatch(showError('Failed to save Network group', err));
    }
};

export const updateNetworkGroupName = (organizationId: number, networkGroupId: number, name: string) => async (dispatch: AppDispatch) => {
    try {
        await TraverseApiService.ncm.updateProtocolsNetworkGroup(organizationId, networkGroupId, {name});
        await dispatch(setNetworkGroupName({networkGroupId, name}));
        dispatch(showSuccess('Network group updated'));
    } catch (err) {
        dispatch(showError('Failed to update Network group', err));
    }
};

export const deleteNetworkGroup = (organizationId: number, networkGroupId: number) => async (dispatch: AppDispatch) => {
    try {
        await TraverseApiService.ncm.deleteProtocolsNetworkGroup(networkGroupId, organizationId);
        await dispatch(deleteNetworkGroupSuccess(networkGroupId));
        dispatch(showSuccess('Network group deleted'));
    } catch (err) {
        dispatch(showError('Failed to delete Network group', err));
    }
};

export const toggleProtocolEnabled = (organizationId: number, networkGroupId: number, protocolName: string, enabled: boolean) => async (dispatch: AppDispatch) => {
    try {
        await TraverseApiService.ncm.updateProtocol(organizationId, networkGroupId, protocolName, {enabled, name: protocolName});
        await dispatch(setNetworkGroupProtocolEnabledState({networkGroupId, protocolName, enabled}));

        if (enabled) {
            dispatch(showSuccess('Protocol enabled'));
        } else {
            dispatch(showSuccess('Protocol disabled'));
        }
    } catch (err) {
        if (enabled) {
            dispatch(showError('Failed to enable protocol', err));
        } else {
            dispatch(showError('Failed to disable protocol', err));
        }
    }
};

export const updateProtocolProperties = (organizationId: number, networkGroupId: number, protocol: NcmCommonProtocol) => async (dispatch: AppDispatch) => {
    try {
        await TraverseApiService.ncm.updateProtocol(organizationId, networkGroupId, protocol.name, protocol);
        await dispatch(fetchNetworkGroups());
        dispatch(showSuccess('Protocol updated'));
    } catch (err) {
        dispatch(showError('Failed to update Protocol', err));
    }
};

export const updateNetworkAddresses = (organizationId: number, networkGroupId: number, ipAddresses: string[]) => async (dispatch: AppDispatch) => {
    try {
        await TraverseApiService.ncm.updateProtocolsNetworkGroup(organizationId, networkGroupId, {ipAddresses});
        dispatch(setNetworkGroupAddresses({networkGroupId, ipAddresses}));
        dispatch(showSuccess('Network Group updated'));
    } catch (err) {
        dispatch(showError('Failed to update Network Group', err));
    }
};

export const {
    setGuessNetworkGroup,
    setNetworkGroups,
    setNetworkGroupAddresses,
    setNetworkGroupName,
    setNetworkGroupProtocolEnabledState,
    setSelectedOrganizationId,
    setSelectedNetworkGroupId,
    setSelectedProtocolName,
    deleteNetworkGroupSuccess,
    cleanUp,
} = protocols.actions;

export default protocols.reducer;