import {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import GlobalConfig from './GlobalConfig';

const Health = lazy(() => import('./health/Health'));
const IntegrationSettings = lazy(() => import('./IntegrationSettings'));
const MfaManagement = lazy(() => import('./mfa-management/MfaManagement'));
const UserManagement = lazy(() => import('./UserManagement'));

const Superuser = () => {
    return <Routes>
        <Route index element={<GlobalConfig/>}/>
        <Route path="global-config" element={<GlobalConfig/>}/>
        <Route path="health/*" element={
            <Suspense fallback={'...'}>
                <Health/>
            </Suspense>
        }/>
        <Route path="mfa-management" element={
            <Suspense fallback={'...'}>
                <MfaManagement/>
            </Suspense>
        }/>
        <Route path="user-management" element={
            <Suspense fallback={'...'}>
                <UserManagement/>
            </Suspense>
        }/>
        <Route path="integration-settings/*" element={
            <Suspense fallback={'...'}>
                <IntegrationSettings/>
            </Suspense>
        } />
    </Routes>;
};

export default Superuser;