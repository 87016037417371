import ApiV1, {ApiV1Response} from './ApiV1';

export type ItCompleteStatus = {
    itCompleteSsoEnabled: boolean;
    appUrl: string;
};

export type ItcRedirectUriDto = {
    redirectUri: string;
}

class ItcApiService {
    getStatus() {
        return ApiV1.get<ApiV1Response<ItCompleteStatus[]>>('/global-config/integration-settings/it-complete');
    }

    mapUnauthorizedUser(username: string, password: string) {
        return ApiV1.post<ApiV1Response<ItcRedirectUriDto[]>>('/it-complete/unauthorized-mapping', {username, password});
    }

    createJitUser() {
        return ApiV1.post<ApiV1Response<ItcRedirectUriDto[]>>('/it-complete/jit-user');
    }

    getItcProducts() {
        return ApiV1.get('/it-complete/products');
    }

    getItcSettings() {
        return ApiV1.get('/it-complete/user');
    }
}

export default new ItcApiService();
