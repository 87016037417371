import ApiV1 from './ApiV1';

export type SnmpVersion = 'V1' | 'V2C' | 'V3';

export type SnmpV3AuthenticationProtocol = 'NONE' | 'MD5' | 'SHA1' | 'SHA_256' | 'SHA_512';

export type SnmpV3PrivacyProtocol = 'NONE' | 'DES' | 'AES_128' | 'AES_256' | 'AES_256_CISCO';

export type MibSnmpOperation = 'get' | 'walk';

export interface MibSnmpQuery {
    deviceId: number;
    deviceAddress: string;
    snmpCommunity: string;
    snmpPort: number;
    snmpVersion: SnmpVersion;
    snmpAuth: SnmpV3AuthenticationProtocol;
    snmpPriv: SnmpV3PrivacyProtocol;
    oid: string;
    endOid?: string;
}

export interface MibSnmpEntry {
    name: string;
    oid: string;
    type: string;
    value: string;
}

export interface MibSnmpProperties {
    deviceAddress: string;
    snmpCommunity: string;
    port: number;
    snmpVersion: SnmpVersion;
    snmpAuth: SnmpV3AuthenticationProtocol;
    snmpPriv: SnmpV3PrivacyProtocol;
}

export interface MibFileContent {
    response: MibResponse;
}

interface MibResponse {
    code: string;
    mib: MibEntries;
}

export interface MibEntryBase {
    id: string;
    oid: MibEntry[];
}

interface MibEntries extends MibEntryBase {
    oidCount: number;
}

export interface MibEntry extends MibEntryBase {
    value: string;
    abstractType: string;
    access: string;
    description: string;
    status: string;
    type: string;
}

class MibApiService {
    getMibFiles() {
        return ApiV1.get<MibFileContent[]>('/devices/mib-files');
    }

    saveMibFiles(attachments: FormData) {
        return ApiV1.post('/devices/mib-files', attachments);
    }

    deleteMibFile(fileName: string) {
        return ApiV1.delete(`/devices/mib-files/${fileName}`);
    }

    deleteAllMibFiles() {
        return ApiV1.delete('/devices/mib-files');
    }

    getSnmpData(params: MibSnmpQuery) {
        return ApiV1.get<MibSnmpEntry[]>('/devices/snmp-data', {params});
    }

    getSnmpSettings(deviceId: number) {
        return ApiV1.get<MibSnmpProperties>(`/devices/${deviceId}/snmp-settings`);
    }
}

export default new MibApiService();
