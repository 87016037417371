import {createSlice} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {NcmVendor} from '../../../services/NcmApiService';
import {AppDispatch} from '../../../store';

interface VendorSlice {
    vendors: NcmVendor[];
}

const initialState: VendorSlice = {
    vendors: []
};

const vendorsSlice = createSlice({
    name: 'vendors',
    initialState,
    reducers: {
        setVendors: (state, action) => {
            state.vendors = action.payload;
        }
    }
});

const {
    setVendors
} = vendorsSlice.actions;

const getVendors = () => (dispatch: AppDispatch) => {
    TraverseApiService.ncm.findHardwareVersions()
        .then((response) => dispatch(setVendors(response.data)))
        .catch((response) => dispatch(showError('Failed to get hardware versions', response)));
};

export {
    getVendors
};

export default vendorsSlice.reducer;