import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {addResult} from '../results-panel/resultsSlice';
import {ApiSorting, NcmDevice} from '../../../services/NcmApiService';
import {AppDispatch, RootState} from '../../../store';

interface DeviceSlice {
    devices: NcmDevice[];
    totalDevices: number;
    deviceQuery: {field: string, query: string};
    sorting: ApiSorting;
    page: number;
    perPage: number;
    selectedDevices: NcmDevice[];
    editDeviceDialogOpened: boolean;
    editDevice?: NcmDevice;
}

const initialState: DeviceSlice = {
    devices: [],
    totalDevices: 0,
    deviceQuery: {
        field: 'interfaceIpAddress',
        query: '',
    },
    sorting: {sortBy: 'ipAddress', sortOrder: 'asc'},
    page: 0,
    perPage: 10,
    selectedDevices: [],
    editDeviceDialogOpened: false,
    editDevice: undefined,
};

const deviceSlice = createSlice({
    name: 'ncmDevices',
    initialState,
    reducers: {
        setDevices: (state, action) => {
            state.devices = action.payload;
        },
        setTotalDevices: (state, action) => {
            state.totalDevices = action.payload;
        },
        setSorting: (state, action) => {
            state.sorting = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPerPage: (state, action) => {
            state.perPage = action.payload;
        },
        toggleSelected: (state, {payload}: PayloadAction<NcmDevice>) => {
            const device = payload;
            const {selectedDevices} = state;

            const idx = selectedDevices.findIndex(selectedItem => selectedItem.id === device.id);
            if (idx >= 0) {
                state.selectedDevices = selectedDevices.filter(selectedItem => selectedItem.id !== device.id);
            } else {
                state.selectedDevices = [...selectedDevices, device];
            }
        },
        toggleAllSelected: (state) => {
            state.selectedDevices = state.selectedDevices.length === state.devices.length ? [] : [...state.devices];
        },
        openEditDeviceDialog: (state, action) => {
            state.editDeviceDialogOpened = true;
            state.editDevice = action.payload;
        },
        closeEditDeviceDialog: (state) => {
            state.editDeviceDialogOpened = false;
            state.editDevice = undefined;
        },
        setDeviceQuery: (state, action) => {
            state.deviceQuery = action.payload;
        },
        clearSelected: (state) => {
            state.selectedDevices = [];
        }
    }
});

const getDevices = (perPage: number, page: number, sorting: FixType, query: FixType) => (dispatch: AppDispatch) => {
    const pagination = {
        skip: perPage * page,
        limit: perPage
    };
    TraverseApiService.ncm.findDevices(query, pagination, sorting)
        .then((response) => {
            dispatch(setDevices(response.data.items));
            dispatch(clearSelected());
            if (page === 0) {
                dispatch(setTotalDevices(response.data.total));
            }
        })
        .catch((response) => dispatch(showError('Failed to get devices', response)));
};

const refreshDevices = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {perPage, page, sorting, deviceQuery} = getState().ncmDevices;
    dispatch(getDevices(perPage, page, sorting, deviceQuery));
};

const executeDeviceQuery = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {perPage, sorting, deviceQuery} = getState().ncmDevices;
    dispatch(setPage(0));
    dispatch(getDevices(perPage, 0, sorting, deviceQuery));
};

const updateDevice = (device: NcmDevice, adapterId: string | null) => (dispatch: AppDispatch) => {
    TraverseApiService.ncm.updateDevice(device.id, {adapterId})
        .then(() => {
            dispatch(closeEditDeviceDialog());
            dispatch(refreshDevices());
        })
        .catch((response) => dispatch(showError('Failed to update device', response)));
};

const addDeviceDetails = (device: NcmDevice) => (dispatch: AppDispatch) => {
    const result = {
        id: device.id,
        name: device.ipAddress,
        type: 'DEVICE_DETAILS',
        payload: device
    };
    dispatch(addResult(result));
};

export {
    getDevices,
    refreshDevices,
    executeDeviceQuery,
    updateDevice,
    addDeviceDetails
};

export const {
    setSorting,
    setDevices,
    setDeviceQuery,
    setPage,
    setPerPage,
    setTotalDevices,
    toggleSelected,
    toggleAllSelected,
    openEditDeviceDialog,
    closeEditDeviceDialog,
    clearSelected
} = deviceSlice.actions;

export default deviceSlice.reducer;