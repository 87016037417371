import ApiJson, {
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse,
    ApiJsonSearchCriterion,
    ApiJsonSortedRequest,
} from './ApiJson';

export enum PerspectiveType {
    TEST = 'TEST',
    DEVICE = 'DEVICE'
}

export type PerspectiveInfo = {
    serialNumber: number;
    name: string;
    type: PerspectiveType
    searchCriterias: ApiJsonSearchCriterion[];
    options: Record<string, string>;
}

export type ParsedPerspectiveInfo = Omit<PerspectiveInfo, 'searchCriterias'> & {
    searchCriterias: Record<string, string>;
};

type GetPerspectivesRequestParams = ApiJsonPagedRequest & ApiJsonSortedRequest & {
    type: PerspectiveType;
}
type GetPerspectivesResponse = ApiJsonPagedData & {
    perspectives: PerspectiveInfo[];
}

type SavePerspectiveResponse = {
    perspective: PerspectiveInfo;
}

type DeletePerspectiveRequestParams = {
    serialNumber: number;
}

class PerspectiveApiService {
    getPerspectives(data: GetPerspectivesRequestParams) {
        return ApiJson.post<ApiJsonResponse<GetPerspectivesResponse>>('/admin/perspective/getPerspectives', data);
    }

    createPerspective(data: PerspectiveInfo) {
        return ApiJson.post<ApiJsonResponse<SavePerspectiveResponse>>('/admin/perspective/createPerspective', data);
    }

    updatePerspective(data: PerspectiveInfo) {
        return ApiJson.post<ApiJsonResponse<SavePerspectiveResponse>>('/admin/perspective/updatePerspective', data);
    }

    deletePerspective(data: DeletePerspectiveRequestParams) {
        return ApiJson.post<ApiJsonResponse<void>>('/admin/perspective/deletePerspective', data);
    }
}

export default new PerspectiveApiService();