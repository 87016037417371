import {createSlice} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {FindArpEntriesParams, NcmArpEntry} from '../../../services/NcmApiService';
import {AppDispatch} from '../../../store';

interface ArpSearchSlice {
    query: string;
    arpEntries: NcmArpEntry[];
    loading: boolean;
}

const initialState: ArpSearchSlice = {
    query: '',
    arpEntries: [],
    loading: false
};

const arpSearchSlice = createSlice({
    name: 'arpSearch',
    initialState,
    reducers: {
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        setArpEntries: (state, action) => {
            state.arpEntries = action.payload;
        },
        appendArpEntries: (state, action) => {
            state.arpEntries = [...state.arpEntries, ...action.payload];
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

const findAllEntries = (query: string) => (dispatch: AppDispatch) => {
    dispatch(setArpEntries([]));
    dispatch(setLoading(true));

    const params: FindArpEntriesParams = {
        skip: 0,
        limit: 100,
        ipAddress: query
    };
    loadMore(dispatch, params);
};

const loadMore = (dispatch: AppDispatch, params: FindArpEntriesParams) => {
    TraverseApiService.ncm.findArpEntries(params)
        .then((response) => {
            dispatch(appendArpEntries(response.data));

            if (response.data.length === params.limit) {
                params.skip += params.limit;
                loadMore(dispatch, params);
            } else {
                dispatch(setLoading( false));
            }
        })
        .catch((response) => {
            dispatch(showError('Failed to load ARP Entries', response));
            dispatch(setLoading(false));
        });
};

export {
    findAllEntries
};

export const {
    setQuery,
    setArpEntries,
    setLoading,
    appendArpEntries
} = arpSearchSlice.actions;

export default arpSearchSlice.reducer;


