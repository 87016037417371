import styles from '../../../clf/LandingTemplate/styles.module.css';
import {useAppSelector} from '../../../store';
import LandingTemplate from '../../../clf/LandingTemplate';
import Title from '../../../clf/Title';
import Text from '../../../clf/Text';
import Button from '../../../clf/form/Button';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {SuccessImage} from '../../../common/icons/TraverseIcons';

const ItCompleteEmail = () => {
    const navigate = useNavigate();
    const session = useAppSelector(({session}) => session);

    const goNext = useCallback(() => navigate('/'), []);

    return <LandingTemplate onEnterKeyCallback={goNext}>
        <div className={styles.titleBlock}>
            <SuccessImage/>

            <Title
                variant={'page'}
                style={{textAlign: 'center'}}
            >
                Success
            </Title>

            <Text style={{color: 'var(--clf-text-color-secondary)'}}>
                You successfully logged in with KaseyaOne. Current email has been changed to <b>{session.data.user?.emailAddress}</b> according to provided email from KaseyaOne.
            </Text>
        </div>

        <div className={styles.form}>
            <Button
                size={'big'}
                onClick={goNext}
            >
                Done
            </Button>
        </div>
    </LandingTemplate>;
};

export default ItCompleteEmail;
