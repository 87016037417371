import ApiV1, {ApiV1Response} from './ApiV1';

export enum UserGroup {
    USER = 'user',
    ADMIN = 'admin',
    SUPER = 'super',
}

export interface AuthLogin {
    username: string;
    password: string
}

export interface AuthRepresentedUser {
    representedUserId: number;
}

export interface AuthUser {
    userId: number;
    userName: string;
    userGroup: UserGroup;
    emailAddress: string;
}

interface MfaStatus {
    enabled: boolean;
    confirmed: boolean;
    trustedDevice: boolean;
    mfaReminderEnabled: boolean;
}

export interface AuthStatus {
    emailVerified: boolean;
    authenticated: boolean;
    represented: boolean;
    passwordResetEnabled: boolean;
    mfaStatus: MfaStatus | null;
    loginUser: AuthUser | null;
    representedUser: AuthUser | null;
}

class AuthApiService {
    getStatus() {
        return ApiV1.get<ApiV1Response<AuthStatus[]>>('/auth');
    }

    login(payload: AuthLogin) {
        return ApiV1.post('/auth', payload);
    }

    logout() {
        return ApiV1.delete<ApiV1Response<AuthStatus[]>>('/auth');
    }

    setRepresentedUser(payload: AuthRepresentedUser) {
        return ApiV1.patch<ApiV1Response<AuthStatus[]>>('/auth', payload);
    }

}

export default new AuthApiService();
