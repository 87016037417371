import ApiJson, {
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse,
    ApiJsonSearchedRequest,
    ApiJsonSortedRequest, ApiJsonSearchOption, ApiJsonMessagedResponse,
} from './ApiJson';

export type DeviceStatusRequestedField
    = 'ACCOUNT_NAME'
    | 'ACCOUNT_SERIAL_NUMBER'
    | 'APPLICATION_PROFILES'
    | 'ASSET_ID'
    | 'CLEAR_ON_OK'
    | 'COMMENT'
    | 'CONFIG_BACKUP_ENABLED'
    | 'CONFIG_BACKUP_FREQUENCY'
    | 'DEVICE_ADDRESS'
    | 'DEVICE_DETAIL_URL'
    | 'DEVICE_STATUS'
    | 'DEVICE_TYPE'
    | 'DEVICE_TYPE_STR'
    | 'DEVICE_UPDATE_URL'
    | 'DISCOVERY_ERRORS'
    | 'EVENT_MGR_URL'
    | 'EVENT_STATUS'
    | 'EXPORTED'
    | 'FLAP_PREVENTION_WAIT_CYCLES'
    | 'HAS_DISCOVERY_ERRORS'
    | 'ISSUSPEND'
    | 'LOCATION_NAME'
    | 'MONITOR_CONFIG_COUNT'
    | 'MODEL'
    | 'PROCESS_COLLECTION_ENABLED'
    | 'PROCESS_COLLECTOR_TYPE'
    | 'PROCESS_MONITOR_CONFIG'
    | 'PROCESS_MONITOR_CONFIG_NAME'
    | 'REDISCOVERY_DELETED_TESTS_ACTION'
    | 'REDISCOVERY_ENABLED'
    | 'REDISCOVERY_FREQUENCY'
    | 'REDISCOVERY_NEW_TESTS_ACTION'
    | 'REDISCOVERY_UPDATED_TESTS_ACTION'
    | 'SHOW_ON_SUMMARY'
    | 'SMART_NOTIFY'
    | 'TAG1'
    | 'TAG2'
    | 'TAG3'
    | 'TAG4'
    | 'TAG5'
    | 'TEST_PROVISIONING_RUNNING'
    | 'VENDOR'
    | 'LINKED_TEMPLATE_RELATIONS';

export type DeviceFilterName
    = ApiJsonSearchOption.ACTION_PROFILE_SERIAL_NUMBER
    | ApiJsonSearchOption.CONTAINER_NAME
    | ApiJsonSearchOption.CONTAINER_SERIAL_NUMBER
    | ApiJsonSearchOption.DEPARTMENT_SERIAL_NUMBER
    | ApiJsonSearchOption.DEVICE_ADDRESS
    | ApiJsonSearchOption.DEVICE_MODEL
    | ApiJsonSearchOption.DEVICE_NAME
    | ApiJsonSearchOption.DEVICE_SERIAL_NUMBER
    | ApiJsonSearchOption.DEVICE_STATUS
    | ApiJsonSearchOption.DEVICE_TYPE
    | ApiJsonSearchOption.DGE_SERIAL_NUMBER
    | ApiJsonSearchOption.FREEFORM
    | ApiJsonSearchOption.LOCATION_SERIAL_NUMBER
    | ApiJsonSearchOption.SUSPEND
    | ApiJsonSearchOption.TAG_1
    | ApiJsonSearchOption.TAG_2
    | ApiJsonSearchOption.TAG_3
    | ApiJsonSearchOption.TAG_4
    | ApiJsonSearchOption.TAG_5
    | ApiJsonSearchOption.TEST_NAME
    | ApiJsonSearchOption.TEST_STATUS
    | ApiJsonSearchOption.TEST_SUB_TYPE
    | ApiJsonSearchOption.TEST_TYPE;

export type DeviceUpdatePropertyName
    = 'ADDRESS'
    | 'APPLICATION_PROFILES'
    | 'CLEAR_ON_OK'
    | 'COMMENT'
    | 'CONFIG_BACKUP_ENABLED'
    | 'CONFIG_BACKUP_FREQUENCY'
    | 'DEVICE_NAME'
    | 'DEVICE_TYPE'
    | 'DO_NOT_RESOLVE_ADDRESS'
    | 'FLAP_PREVENTION_WAIT_CYCLES'
    | 'MODEL'
    | 'PROCESS_COLLECTION_ENABLED'
    | 'PROCESS_COLLECTOR_TYPE'
    | 'PROCESS_MONITOR_CONFIG'
    | 'REDISCOVERY_DELETED_TESTS_ACTION'
    | 'REDISCOVERY_ENABLED'
    | 'REDISCOVERY_FREQUENCY'
    | 'REDISCOVERY_NEW_TESTS_ACTION'
    | 'REDISCOVERY_UPDATED_TESTS_ACTION'
    | 'SHOW_ON_SUMMARY'
    | 'SMART_NOTIFY'
    | 'SUSPEND'
    | 'TAG_1'
    | 'TAG_2'
    | 'TAG_3'
    | 'TAG_4'
    | 'TAG_5'
    | 'VENDOR';

export enum DeviceType {
    WINDOWS = 0,
    LINUX = 1,
    SWITCH = 2,
    ROUTER = 3,
    FIREWALL = 4,
    LOAD_BALANCER = 5,
    PROXY_SERVER = 6,
    VPN_CONCENTRATOR = 7,
    PRINTER = 8,
    WAP = 9,
    OTHER = 10,
    STORAGE = 11,
    VIRTUALIZATION = 12,
    CLOUD = 13
}

export enum RediscoveryAction {
    UPDATE = 1,
    IGNORE = 2,
    LOG_ONLY = 3,
    INVALID = 4,
    SUSPEND = 5,
}

export enum ProcessCollectorType {
    SNMP = 'SNMP',
    WMI = 'WMI',
}

export type DeviceFilters = Partial<Record<DeviceFilterName, string | undefined>>;

type GetStatusesRequestParams = ApiJsonPagedRequest & ApiJsonSearchedRequest & ApiJsonSortedRequest & {
    fieldsRequested?: DeviceStatusRequestedField[];
    recurseContainers?: boolean;
}

export interface DeviceStatusBase {
    accountSerialNumber?: number;
    applicationProfiles?: string[];
    clearOnOk?: boolean;
    comment?: string;
    configBackupEnabled?: boolean;
    configBackupFrequency?: number;
    deviceAddress?: string;
    deviceName?: string;
    deviceType?: DeviceType;
    doNotResolveAddress?: boolean;
    flapPreventionWaitCycles?: number;
    isSuspended?: boolean;
    locationName?: string;
    model?: string;
    processCollectionEnabled?: boolean;
    processCollectorType?: ProcessCollectorType;
    processMonitorConfig?: number;
    rediscoveryDeletedTestsAction?: RediscoveryAction;
    rediscoveryEnabled?: boolean;
    rediscoveryFrequency?: number;
    rediscoveryNewTestsAction?: RediscoveryAction;
    rediscoveryUpdatedTestsAction?: RediscoveryAction;
    serialNumber: number;
    showOnSummary?: boolean;
    smartNotify?: boolean;
    tag1?: string;
    tag2?: string;
    tag3?: string;
    tag4?: string;
    tag5?: string;
    vendor?: string;
}

export interface DeviceStatus extends DeviceStatusBase {
    // extra
    accountName?: string;
    deviceDetailUrl?: string;
    deviceStatus?: number;
    deviceTypeStr?: string;
    deviceUpdateUrl?: string;
    discoveryErrors?: {
        timestamp: number;
        timestampStr: string;
        message: string;
    }[];
    eventMgrUrl?: string;
    eventStatus?: number;
    exported?: boolean;
    hasDiscoveryErrors?: boolean;
    monitorConfigCount?: number;
    liveConnectEnabled?: boolean;
    onlineStatus?: number;
    testProvisioningRunning?: boolean;
    isMasterDevice?: boolean;
    isLinkedDevice?: boolean;
}

export interface DeviceStatusUpdate extends DeviceStatusBase {
    doNotResolveAddress?: boolean;
}

export interface DeviceStatusCreate extends Omit<DeviceStatusUpdate, 'serialNumber'> {
    serialNumber?: number;
    applyAutomationProfiles?: boolean;
    automaticTestDiscovery?: boolean;
}

const deviceUpdateFieldMapping: Partial<Record<keyof DeviceStatusUpdate, DeviceUpdatePropertyName>> = {
    deviceAddress: 'ADDRESS',
    applicationProfiles: 'APPLICATION_PROFILES',
    clearOnOk: 'CLEAR_ON_OK',
    comment: 'COMMENT',
    configBackupEnabled: 'CONFIG_BACKUP_ENABLED',
    configBackupFrequency: 'CONFIG_BACKUP_FREQUENCY',
    deviceName: 'DEVICE_NAME',
    deviceType: 'DEVICE_TYPE',
    doNotResolveAddress: 'DO_NOT_RESOLVE_ADDRESS',
    flapPreventionWaitCycles: 'FLAP_PREVENTION_WAIT_CYCLES',
    model: 'MODEL',
    processCollectionEnabled: 'PROCESS_COLLECTION_ENABLED',
    processCollectorType: 'PROCESS_COLLECTOR_TYPE',
    processMonitorConfig: 'PROCESS_MONITOR_CONFIG',
    rediscoveryDeletedTestsAction: 'REDISCOVERY_DELETED_TESTS_ACTION',
    rediscoveryEnabled: 'REDISCOVERY_ENABLED',
    rediscoveryFrequency: 'REDISCOVERY_FREQUENCY',
    rediscoveryNewTestsAction: 'REDISCOVERY_NEW_TESTS_ACTION',
    rediscoveryUpdatedTestsAction: 'REDISCOVERY_UPDATED_TESTS_ACTION',
    showOnSummary: 'SHOW_ON_SUMMARY',
    smartNotify: 'SMART_NOTIFY',
    isSuspended: 'SUSPEND',
    tag1: 'TAG_1',
    tag2: 'TAG_2',
    tag3: 'TAG_3',
    tag4: 'TAG_4',
    tag5: 'TAG_5',
    vendor: 'VENDOR'
};

export type DeviceDependency = {
    parentId: number;
    childId: number;
};

export type DependencyOperationStatus = {
    dependency: DeviceDependency;
    message: string;
}

export const deviceUpdateFieldMapper = (base: DeviceStatus, update: DeviceStatusUpdate): DeviceUpdateProperty[] => {
    return (Object.entries(deviceUpdateFieldMapping) as [keyof DeviceStatusUpdate, DeviceUpdatePropertyName][])
        .filter(([field]) => update[field] !== undefined && base[field] !== update[field])
        .map(([field, propertyName]) => ({
            propertyName,
            propertyValue: `${update[field]}`
        }));
};

export interface DeviceUpdateProperty {
    propertyName: DeviceUpdatePropertyName;
    propertyValue: string;
}

interface GetStatusesResponse extends ApiJsonPagedData {
    devices: DeviceStatus[] | null;
}

interface CreateDeviceResponse {
    device: DeviceStatus;
    message: string;
}

interface UpdateDependencyResponse {
    addErrors: DependencyOperationStatus[];
    removeErrors: DependencyOperationStatus[];
    addCount: number;
    removeCount: number;
    message: string;
}

type CreateExportedDeviceRequest = {
    sourceDeviceSerialNumber: number;
    destAccountSerialNumber: number;
    exportedDeviceName: string;
    testSerialNumbers: number[];
};

type UpdateExportedDeviceRequest = {
    sourceDeviceSerialNumber: number;
    exportedDeviceSerialNumber: number;
    exportedDeviceName: string;
    testSerialNumbers: number[];
};

class DeviceApiService {
    getStatuses(data: GetStatusesRequestParams) {
        return ApiJson.post<ApiJsonResponse<GetStatusesResponse>>('/device/getStatuses', data);
    }

    create(deviceStatus: DeviceStatusCreate) {
        return ApiJson.post<ApiJsonResponse<CreateDeviceResponse>>('/admin/device/create', deviceStatus);
    }

    update(ids: number[], deviceUpdateProperties: DeviceUpdateProperty[]) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/device/update', {
            searchCriterias: [{searchOption: ApiJsonSearchOption.DEVICE_SERIAL_NUMBER, searchTerms: ids.join(',')}],
            deviceUpdateProperties
        });
    }

    delete(ids: number[]) {
        return ApiJson.post<ApiJsonResponse<null>>('/admin/device/delete', {
            searchCriterias: [{searchOption: ApiJsonSearchOption.DEVICE_SERIAL_NUMBER, searchTerms: ids.join(',')}]
        });
    }

    clearScanError(deviceSerialNumbers: number[]) {
        return ApiJson.post<ApiJsonResponse<null>>('/admin/device/clearScanError', {deviceSerialNumbers});
    }

    move(ids: number[], accountSerialNumber: number, newDeviceName?: string) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/device/move', {
            accountSerialNumber,
            newDeviceName,
            searchCriterias: [{searchOption: ApiJsonSearchOption.DEVICE_SERIAL_NUMBER, searchTerms: ids.join(',')}]
        });
    }

    getDependencies(serialNumber: number) {
        return ApiJson.post<ApiJsonResponse<GetStatusesResponse>>('/device/getDeviceDependencies', {serialNumber});
    }

    updateDeviceDependency(addDependencies: DeviceDependency[], removeDependencies: DeviceDependency[]) {
        return ApiJson.post<ApiJsonResponse<UpdateDependencyResponse>>('/admin/device/updateDeviceDependency', {addDependencies, removeDependencies});
    }

    getExportedDevices(organizationId: number, deviceId: number) {
        return ApiJson.post<ApiJsonResponse<GetStatusesResponse>>('/device/getExportedDevices', {
            searchCriterias: [
                {searchOption: ApiJsonSearchOption.DEPARTMENT_SERIAL_NUMBER, searchTerms: organizationId},
                {searchOption: ApiJsonSearchOption.DEVICE_SERIAL_NUMBER, searchTerms: deviceId}
            ]
        });
    }

    createExportedDevice(data: CreateExportedDeviceRequest) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/device/createExportedDevice', data);
    }

    updateExportedDevice(data: UpdateExportedDeviceRequest) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/device/updateExportedDevice', data);
    }
}

export default new DeviceApiService();