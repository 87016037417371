const extractQueryParams = () => {
    const path = window.location.href;
    const queryParameters = path.slice(path.indexOf('?') + 1).split('&');
    const result: Record<string, string> = {};
    queryParameters
        .map((hash) => hash.split('='))
        .forEach((keyValue) => result[keyValue[0]] = keyValue[1]);

    return result;
};

const convertArrayToQuery = (paramName: string, array: string[]) => {
    const params = new URLSearchParams();
    array.forEach((item) => params.append(paramName, item));
    return params;
};

export const UrlUtils = {
    extractQueryParams,
    convertArrayToQuery
};

export const genHref = (location?: Partial<Location>, searchParams?: string): string => {
    let href = location?.pathname || '';

    if (searchParams) {
        href += `?${searchParams}`;
    }

    href += (location?.hash || '');

    return href;
};

export const isEmptyUrl = (url?: string): boolean => !url || url === '/';