import {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import PermissionRoute from '../PermissionRoute';
import {PermissionCategory, PermissionOperation} from '../../services/PermissionService';

const MyReports = lazy(() => import('./my-reports/MyReports'));
const ScheduledReports = lazy(() => import('./scheduled-reports/ScheduledReports'));

const Reports = () => {
    return <Routes>
        <Route element={<PermissionRoute category={PermissionCategory.REPORT} operation={PermissionOperation.READ}/>}>
            <Route path="my-reports" element={
                <Suspense fallback={'...'}>
                    <MyReports/>
                </Suspense>
            }/>
        </Route>

        <Route element={<PermissionRoute category={PermissionCategory.REPORT} operation={PermissionOperation.READ}/>}>
            <Route path="scheduled-reports/*" element={
                <Suspense fallback={'...'}>
                    <ScheduledReports/>
                </Suspense>
            }/>
        </Route>
    </Routes>;
};

export default Reports;