import ApiV1 from './ApiV1';
import {SortingOrder} from '../utils/SortUtils';

export type NcmNewEntity<T> = Omit<T, 'id'>;

export interface NcmAdapter {
    adapterId: string;
    description: string;
    restoreValidationRegex: string;
    shortName: string;
}

export interface NcmDevice {
    id: number;
    ipAddress: string;
    hostname: string;
    departmentName: string;
    deviceName: string;
    adapterId: string;
    model: string;
    backupStatus: string;
    managedNetwork: string;
    backupFrequency: number;
}

export interface NcmStoredConfiguration {
    ipAddress: string;
    hostName: string;
    deviceName: string;
    managedNetwork: string;
    departmentName: string;
    path: string;
    lastChanged: number;
}

export interface NcmDeviceConfigurationRevision {
    lastChangedMillis: number;
    lastChangedStr: string | FixType;
    path: string;
}

export interface NcmDeviceConfigurationRevisionDetails {
    author: string;
    crc32: number;
    lastChanged: string;
    lastChangedStr: string;
    mimeType: string;
    path: string;
    size: number;
    content: string;
}

export interface NcmArpEntry {
    interfaceName: string;
    ipAddress: string;
    macAddress: string;
    device: string;
}

export interface NcmDeviceTableArpEntry {
    interfaceName: string;
    ipAddress: string;
    macAddress: string;
    device: string;
    managedNetwork: string;
}

export interface NcmDeviceTableMacEntry {
    device: string;
    managedNetwork: string;
    macAddress: string;
    port: string;
    vlan: string;
}

export interface NcmClosestSwitch {
    error: number;
    hostIpAddress: string;
    hostMacAddress: string;
    arpEntry?: NcmDeviceTableArpEntry;
    macEntry?: NcmDeviceTableMacEntry;
}

export interface NcmVendor {
    version: string;
}

export interface FindArpEntriesParams {
    skip: number;
    limit: number;
    ipAddress: string;
}

export interface NcmPluginColumn {
    index: number;
    header: string;
    attributeMap: Record<string, string>;
}

export interface NcmPluginInput {
    index: number;
    header: string;
    attributeMap: Record<string, string>;
}

export interface NcmPluginMenu {
    label: string;
    tooltip: string;
}

export interface NcmPlugin {
    toolCategory: string;
    pluginType: string;
    toolName: string;
    supportedMode: string;
    otherProperties: Record<string, string>;
    columns: NcmPluginColumn[];
    inputs: NcmPluginInput[];
    menu: NcmPluginMenu;
    plugins: NcmPlugin[];
}

export interface NcmPluginCategory {
    category: string;
    plugins: NcmPlugin[];
    subCategories: NcmPluginCategory[];
}

export interface NcmNeighbor {
    ipAddress: string;
    localInterface: string;
    otherId: string;
    protocol: string;
    remoteInterface: string;
}

export interface NcmNetworkAddress {
    id?: number;
    addresses: string[];
}
export interface NcmNetworkCredentialOptions {
    username: string;
    password: string;
    enableUsername: string;
    enablePassword: string;
    roCommunityString: string;
    rwCommunityString: string;
    snmpUsername: string;
    snmpAuthPassword: string;
    snmpPrivPassword: string;
}

export interface NcmNetworkCredential {
    id: number;
    name: string;
    priority: number;
    credentialOptions: NcmNetworkCredentialOptions;
}

export interface NcmCredentialsNetworkGroup {
    id: number;
    departmentId: string;
    name: string;
    priority: number;
    default: boolean;
    networkAddress: NcmNetworkAddress;
    payloads: NcmNetworkCredential[];
}

export type NcmProtocolName = 'FTP' | 'HTTP' | 'HTTPS' | 'SCP' | 'SNMP' | 'SSH' | 'Telnet' | 'TFTP';

export interface NcmProtocol {
    name: NcmProtocolName;
    port: string;
    priority: number;
    enabled: boolean;
}

export interface NcmSshProtocol extends NcmProtocol {
    version: string;
}

export interface NcmSnmpProtocol extends NcmProtocol {
    retries: string;
    timeoutMs: string;
    v3Authentication: string;
    v3Encryption: string;
    version: string;
}

export type NcmCommonProtocol = NcmProtocol | NcmSshProtocol | NcmSnmpProtocol;

export interface NcmProtocolSet {
    protocols: NcmCommonProtocol[];
}

export interface NcmProtocolsNetworkGroup {
    id: number;
    departmentId: string;
    name: string;
    priority: number;
    default: boolean;
    networkAddress: NcmNetworkAddress;
    protocolSet: NcmProtocolSet;
}

export interface NcmAdapterLoggingSettings {
    adapterLoggingLevel: number;
    enableLoggingAdapterOperationsToFile: boolean;
    enableRecordingAdapterOperations: boolean;
}

export interface NcmDiscoveryJob {
    cronExpression: string;
    startTime: number;
    endTime: number;
}

export interface ApiSorting {
    sortBy: string;
    sortOrder: SortingOrder;
}

export interface ApiPagination {
    skip: number;
    limit: number;
}

class NcmApiService {
    findDevices(query: FixType, pagination: ApiPagination, sorting: ApiSorting) {
        const requestParams = {
            ...query,
            ...pagination,
            ...sorting,
            sortOrder: sorting.sortOrder.toUpperCase(),
        };
        return ApiV1.get<{items: NcmDevice[], total: number}>('/configuration/dataquery/ncm-devices', {params: requestParams});
    }

    createBackup = (deviceIds: number[]) => {
        return ApiV1.post('/configuration/ncm-devices/backups', deviceIds);
    };

    waitForBackupFinished = (eventsCount: number) => {
        return ApiV1.get('/configuration/ncm-devices/backups/executions', {params: {eventsCount}});
    };

    cancelBackup = (executionId: number) => {
        return ApiV1.post(`/configuration/ncm-devices/backups/executions/${executionId}/cancel`);
    };

    getDeviceStatus = (deviceId: number) => {
        return ApiV1.get(`/configuration/dataquery/ncm-devices/${deviceId}/status`);
    };

    updateDevice = (deviceId: number, updatedDevice: FixType) => {
        return ApiV1.patch(`/configuration/dataquery/ncm-devices/${deviceId}`, updatedDevice);
    };

    collectNeighboursData = (deviceId: number) => {
        return ApiV1.post(`/configuration/devices/${deviceId}/neighbors/collect-data`);
    };

    getDeviceNeighbors = (deviceId: number, managedNetwork: FixType) => {
        return ApiV1.get<NcmNeighbor[]>(`/configuration/devices/${deviceId}/neighbors`, {params: {managedNetwork}});
    };

    findNeighbors = (ipAddress: FixType, managedNetwork: FixType) => {
        return ApiV1.get<NcmNeighbor[]>('/configuration/devices/neighbors', {params: {ipAddress, managedNetwork}});
    };

    findHardwareVersions() {
        return ApiV1.get('/configuration/hardware');
    }

    findConfigurations(query: FixType) {
        return ApiV1.get<NcmStoredConfiguration[]>('/configuration/devices/configurations', {params: {query}});
    }

    getRevision(requestParams: FixType) {
        return ApiV1.get<NcmDeviceConfigurationRevisionDetails>('/configuration/devices/configurations/revisions', {params: requestParams});
    }

    getRevisionsDiff(requestParams: FixType) {
        return ApiV1.get('/configuration/devices/configurations/revisions/diff', {params: requestParams});
    }

    getCurrentRevisions(requestParams: FixType) {
        return ApiV1.get<NcmDeviceConfigurationRevision[]>('/configuration/devices/configurations/latest-revisions', {params: requestParams});
    }

    restoreRevision(deviceId: FixType, configPath: FixType, revisionTimestamp: FixType) {
        return ApiV1.post(`/configuration/devices/${deviceId}/configurations/restore`, {configPath, revisionTimestamp});
    }

    getChangeLog(params: FixType) {
        return ApiV1.get('/configuration/devices/configurations/change-logs', {params});
    }

    findArpEntries(params: FindArpEntriesParams) {
        return ApiV1.get<NcmArpEntry[]>('/configuration/tools/arp-entries', {params});
    }

    findClosestSwitch(address: string) {
        return ApiV1.get<NcmClosestSwitch>('/configuration/tools/closest-switch', {params: {address}});
    }

    findAvailableAdapters() {
        return ApiV1.get<NcmAdapter[]>('/configuration/adapters');
    }

    getPlugins() {
        return ApiV1.get<NcmPluginCategory[]>('/configuration/tools/plugins/');
    }

    executePlugin(request: FixType) {
        return ApiV1.post('/configuration/tools/plugins/executions', request);
    }

    getExecutionDetails(executionId: number) {
        return ApiV1.get(`/configuration/tools/plugins/executions/${executionId}`);
    }

    getFileStoreEntries(path: FixType) {
        return ApiV1.get('/configuration/tools/plugins/file-store', {params: {path}});
    }

    getSettings() {
        return ApiV1.get<NcmAdapterLoggingSettings>('/configuration/settings/adapter-logging-settings');
    }

    saveSettings(settings: NcmAdapterLoggingSettings) {
        return ApiV1.put<NcmAdapterLoggingSettings>('/configuration/settings/adapter-logging-settings', settings);
    }

    getScheduleDiscovery() {
        return ApiV1.get<NcmDiscoveryJob | null>('/configuration/settings/discovery-jobs');
    }

    updateScheduleDiscovery(scheduler: FixType) {
        return ApiV1.post<NcmDiscoveryJob | null>('/configuration/settings/discovery-jobs', scheduler);
    }

    deleteScheduleDiscovery() {
        return ApiV1.delete('/configuration/settings/discovery-jobs');
    }

    // credentials
    getCredentialsNetworkGroups(organizationId: FixType) {
        return ApiV1.get(`/configuration/tools/credentials/departments/${organizationId}/network-groups`);
    }

    saveCredentialsNetworkGroup(networkGroup: FixType) {
        return ApiV1.put('/configuration/tools/credentials/network-groups', networkGroup);
    }

    deleteCredentialsNetworkGroup(networkGroupId: FixType, organizationId: FixType) {
        return ApiV1.delete(`/configuration/tools/credentials/departments/${organizationId}/network-groups/${networkGroupId}`);
    }

    // protocols

    saveProtocolsNetworkGroup(networkGroup: NcmNewEntity<NcmProtocolsNetworkGroup>) {
        return ApiV1.put('/configuration/protocols/network-groups', networkGroup);
    }

    getProtocolsNetworkGroups(organizationId: number) {
        return ApiV1.get(`/configuration/protocols/departments/${organizationId}/network-groups`);
    }

    deleteProtocolsNetworkGroup(networkGroupId: number, organizationId: number) {
        return ApiV1.delete(`/configuration/protocols/departments/${organizationId}/network-groups/${networkGroupId}`);
    }

    updateProtocolsNetworkGroup(organizationId: number, networkGroupId: number, changes: FixType) {
        return ApiV1.patch(`/configuration/protocols/departments/${organizationId}/network-groups/${networkGroupId}`, changes);
    }

    updateProtocol(organizationId: number, networkGroupId: number, protocolName: string, protocol: {enabled: boolean, name: string}) {
        return ApiV1.patch(`/configuration/protocols/departments/${organizationId}/network-groups/${networkGroupId}/protocols/${protocolName}`, protocol);
    }
}

export default new NcmApiService();
