import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService, {SeveritySummaryItem} from '../../../../services/TraverseApiService';

interface SummaryData {
    devices: SeveritySummaryItem[];
    events: SeveritySummaryItem[];
}

interface SummaryPanelSlice {
    enabled: boolean;
    detached: boolean;
    data: SummaryData,
}

const initialState: SummaryPanelSlice = {
    enabled: !!localStorage.getItem('summaryPanelEnabled'),
    detached: false,
    data: {
        devices: [],
        events: [],
    }
};

export const getSummary = createAsyncThunk('summary/get', async (): Promise<SummaryData> => {
    // fetch severity summaries for both devices and events, also ignore severities without any count
    const [devices, events] = await Promise.all([
        TraverseApiService.getDevicesSeveritiesSummary()
            .then((response) => response.data.result.filter((i) => i.count > 0))
            .catch(() => []),
        TraverseApiService.getEventsSeveritiesSummary()
            .then((response) => response.data.result.filter((i) => i.count > 0))
            .catch(() => []),
    ]);

    return {devices, events};
});

const summaryPanelSlice = createSlice({
    name: 'summaryPanelSlice',
    initialState,
    reducers: {
        toggleEnabled: (state) => {
            state.enabled = state.detached || !state.enabled;
            localStorage.setItem('summaryPanelEnabled', state.enabled ? '1' : '');
        },
        setDetached: (state, {payload}: PayloadAction<boolean>) => {
            state.detached = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getSummary.pending, () => {
                // nothing to do here, ignore
            })
            .addCase(getSummary.fulfilled, (state, action) => {
                state.data = action.payload;
            })
            .addCase(getSummary.rejected, (state, action) => {
                console.log(action.error);
            });
    }
});

export const {toggleEnabled, setDetached} = summaryPanelSlice.actions;

export default summaryPanelSlice.reducer;