import {Route, Routes} from 'react-router-dom';
import ItCompleteAuthentication from './components/ItCompleteAuthentication';
import ItCompleteEmail from './components/ItCompleteEmail';
import PrivateRoute from '../PrivateRoute';

const ItComplete = () => {
    return <Routes>
        <Route path='auth' element={<ItCompleteAuthentication/>}/>
        <Route path='auth-jit' element={<ItCompleteAuthentication isJit={true}/>}/>
        <Route element={<PrivateRoute/>}>
            <Route path='email' element={<ItCompleteEmail/>}/>
        </Route>
    </Routes>;
};

export default ItComplete;