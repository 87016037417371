import styles from './styles.module.css';
import {InputHTMLAttributes, useEffect, useRef} from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    indeterminate?: boolean;
}

export default function Checkbox({label = '', indeterminate = false, checked = false, ...props}: CheckboxProps) {
    const checkRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (checkRef.current) {
            checkRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);

    return <label
        className={styles.wrap}
        onClick={(e) => e.stopPropagation()}
    >
        <input {...props} checked={checked} type={'checkbox'} ref={checkRef}/>
        <span className={styles.main}/>
        {label}
    </label>;
}