import dayjs from 'dayjs';
import {ScheduleDiscoverySlice} from '../scheduleDiscoverySlice';
import {NcmDiscoveryJob} from '../../../../../services/NcmApiService';

interface Configuration {
    TYPE: string;
}

interface WeeklyConfiguration extends Configuration {
    D1: number;
    D2: number;
    D3: number;
    D4: number;
    D5: number;
    D6: number;
    D7: number;
}

interface DailyConfiguration extends Configuration {
    I: number;
}

interface MonthlyByDayConfiguration extends Configuration {
    D: number;
    I: number;
}

interface MonthlyByWeekConfiguration extends Configuration {
    D: number;
    I: number;
    W: number | 'L';
}

interface ScheduleProperties {
    dayOfTheMonth: string;
    month: string;
    dayOfTheWeek: string;
    year: string;
    serialized: string;
}

const serializedDays: (keyof WeeklyConfiguration)[]= ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7'];

const parseConfiguration = (scheduler: NcmDiscoveryJob): Configuration => {
    return scheduler.cronExpression.replace(/.*\[(.*)]/, '$1')
        .split(',')
        .map((i) => i.split('='))
        .reduce((a, [i, v]) => ({...a, [i]: Number.isInteger(+v) ? +v : v}), {} as Configuration);
};

const restoreDailySchedule = (configuration: DailyConfiguration, state: ScheduleDiscoverySlice) => ({
    ...state,
    type: ScheduleBuilder.DAILY,
    recurEvery: configuration.I,
});

const restoreWeeklySchedule = (configuration: WeeklyConfiguration, state: ScheduleDiscoverySlice) => ({
    ...state,
    type: ScheduleBuilder.WEEKLY,
    weekdays: serializedDays
        .filter((i) => configuration[i] === 1)
        .map((i) => +i.substring(1)),
});

const restoreMonthlyWeekBasedSchedule = (configuration: MonthlyByWeekConfiguration, state: ScheduleDiscoverySlice) => ({
    ...state,
    type: ScheduleBuilder.MONTHLY,
    monthlyScheduleType: ScheduleBuilder.WEEK,
    monthToRecur: configuration.I,
    dayOfWeekToRecur: configuration.D,
    weekNumber: configuration.W,
});

const restoreMonthlyDayBasedSchedule = (configuration: MonthlyByDayConfiguration, state: ScheduleDiscoverySlice) => ({
    ...state,
    type: ScheduleBuilder.MONTHLY,
    monthlyScheduleType: ScheduleBuilder.DAY,
    monthToRecur: configuration.I,
    dayToRecur: configuration.D,
});

const restoreCronSchedule = (scheduler: NcmDiscoveryJob, state: ScheduleDiscoverySlice) => ({
    ...state,
    type: ScheduleBuilder.CRON,
    cronExpression: scheduler.cronExpression
});

const buildCronExpression = (state: ScheduleDiscoverySlice) => {
    const scheduleProperties = {
        dayOfTheMonth: '?',
        month: '*',
        dayOfTheWeek: '?',
        year: '*',
        serialized: ''
    };
    switch (state.type) {
    case ScheduleBuilder.ONCE:
        return prepareOnTimeSchedule(scheduleProperties, state);
    case ScheduleBuilder.DAILY:
        return prepareDailySchedule(scheduleProperties, state);
    case ScheduleBuilder.WEEKLY:
        return prepareWeeklySchedule(scheduleProperties, state);
    case ScheduleBuilder.MONTHLY:
        return prepareMonthlySchedule(scheduleProperties, state);
    case ScheduleBuilder.CRON:
        return state.cronExpression;
    default:
        return null;
    }
};

const prepareOnTimeSchedule = (scheduleProperties: ScheduleProperties, state: ScheduleDiscoverySlice) => {
    const startTime = dayjs(state.startTime);
    scheduleProperties.dayOfTheMonth = startTime.date().toString();
    scheduleProperties.month = (startTime.month() + 1).toString();
    scheduleProperties.year = startTime.year().toString();
    scheduleProperties.serialized = `TYPE=${ScheduleBuilder.ONCE}`;

    return prepareCronExpression(scheduleProperties, state);
};

const prepareDailySchedule = (scheduleProperties: ScheduleProperties, state: ScheduleDiscoverySlice) => {
    scheduleProperties.dayOfTheMonth = `*/${state.recurEvery}`;
    scheduleProperties.serialized = `TYPE=${ScheduleBuilder.DAILY},I=${state.recurEvery}`;
    return prepareCronExpression(scheduleProperties, state);
};


const prepareWeeklySchedule = (scheduleProperties: ScheduleProperties, state: ScheduleDiscoverySlice) => {
    scheduleProperties.dayOfTheWeek = state.weekdays.join(',');
    const selectedSerializedDays = serializedDays.map((i, k) => `${i}=${state.weekdays.includes(k + 1) ? 1 : 0}`);
    scheduleProperties.serialized = `TYPE=${ScheduleBuilder.WEEKLY},${selectedSerializedDays}`;

    return prepareCronExpression(scheduleProperties, state);
};

const prepareMonthlySchedule = (scheduleProperties: ScheduleProperties, state: ScheduleDiscoverySlice) => {
    const startTime = dayjs(state.startTime);
    const currentMonth = startTime.month();
    scheduleProperties.month =  `${currentMonth}/${state.monthToRecur}`;

    if (state.monthlyScheduleType === ScheduleBuilder.DAY) {
        scheduleProperties.dayOfTheMonth = state.dayToRecur.toString();
        scheduleProperties.serialized = `TYPE=${ScheduleBuilder.MDOM},I=${state.monthToRecur},D=${scheduleProperties.dayOfTheMonth}`;
    } else {
        if (state.weekNumber === 'L') {
            scheduleProperties.dayOfTheWeek = state.dayOfWeekToRecur + state.weekNumber;
        } else {
            scheduleProperties.dayOfTheWeek = `${state.dayOfWeekToRecur}#${state.weekNumber}`;
        }
        scheduleProperties.serialized = `TYPE=${ScheduleBuilder.MDOW},I=${state.monthToRecur},D=${state.dayOfWeekToRecur},W=${state.weekNumber}`;
    }

    return prepareCronExpression(scheduleProperties, state);
};

const prepareCronExpression = (scheduleProperties: ScheduleProperties, state: ScheduleDiscoverySlice) => {
    const startTime = dayjs(state.startTime);

    return [
        '0',
        startTime.minute(),
        startTime.hour(),
        scheduleProperties.dayOfTheMonth,
        scheduleProperties.month,
        scheduleProperties.dayOfTheWeek,
        scheduleProperties.year,
        `[${scheduleProperties.serialized}]`
    ].join(' ');
};

const ScheduleBuilder = {
    ONCE: 'ONCE',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    CRON: 'CRON',

    MDOW: 'MDOW',
    MDOM: 'MDOM',

    WEEK: 'WEEK',
    DAY: 'DAY',

    buildState: (scheduler: NcmDiscoveryJob, state: ScheduleDiscoverySlice) => {
        const extendedState = {...state};
        extendedState.startTime = scheduler.startTime;
        extendedState.endTime = scheduler.endTime;
        const configuration: Configuration = parseConfiguration(scheduler);
        switch (configuration.TYPE) {
        case ScheduleBuilder.ONCE:
            extendedState.type = ScheduleBuilder.ONCE;
            return extendedState;
        case ScheduleBuilder.DAILY:
            return restoreDailySchedule(configuration as DailyConfiguration, extendedState);
        case ScheduleBuilder.WEEKLY:
            return restoreWeeklySchedule(configuration as WeeklyConfiguration, extendedState);
        case ScheduleBuilder.MDOW:
            return restoreMonthlyWeekBasedSchedule(configuration as MonthlyByWeekConfiguration, extendedState);
        case ScheduleBuilder.MDOM:
            return restoreMonthlyDayBasedSchedule(configuration as MonthlyByDayConfiguration, extendedState);
        case undefined:
            return restoreCronSchedule(scheduler, extendedState);
        default:
            return null;
        }
    },

    buildScheduler: (state: ScheduleDiscoverySlice) => {
        return {
            startTime: state.startTime,
            endTime: state.endTime?.valueOf() ?? null,
            cronExpression: buildCronExpression(state)
        };
    }
};

export default ScheduleBuilder;