import axios, {AxiosResponse} from 'axios';

export enum ApiJsonSearchOption {
    ENABLED = 'ENABLED',
    SUSPEND = 'SUSPEND',

    ACTION_PROFILE_SERIAL_NUMBER = 'ACTION_PROFILE_SERIAL_NUMBER',
    ACCOUNT_TYPE = 'ACCOUNT_TYPE',
    ADAPTER_ID = 'ADAPTER_ID',
    ALL_SEVERITIES = 'ALL_SEVERITIES',
    AUTOMATION_RULE_SERIAL_NUMBER = 'AUTOMATION_RULE_SERIAL_NUMBER',
    AUDIT_LOG_START_TIME = 'AUDIT_LOG_START_TIME',
    AUDIT_LOG_END_TIME = 'AUDIT_LOG_END_TIME',
    AUDIT_LOG_ACTIVITY = 'AUDIT_LOG_ACTIVITY',
    AUDIT_LOG_DEPARTMENT = 'AUDIT_LOG_DEPARTMENT',
    AUDIT_LOG_OBJECT_TYPE = 'AUDIT_LOG_OBJECT_TYPE',
    AUDIT_LOG_OBJECT_NAME = 'AUDIT_LOG_OBJECT_NAME',
    AUDIT_LOG_USERNAME = 'AUDIT_LOG_USERNAME',

    CONTAINER_NAME = 'CONTAINER_NAME',
    CONTAINER_SERIAL_NUMBER = 'CONTAINER_SERIAL_NUMBER',
    CONTAINER_STATUS = 'CONTAINER_STATUS',

    DEPARTMENT_SERIAL_NUMBER = 'DEPARTMENT_SERIAL_NUMBER',
    DEPARTMENT_NAME = 'DEPARTMENT_NAME',
    DEVICE_SERIAL_NUMBER = 'DEVICE_SERIAL_NUMBER',
    DEVICE_NAME = 'DEVICE_NAME',
    DEVICE_ADDRESS = 'DEVICE_ADDRESS',
    DEVICE_TYPE = 'DEVICE_TYPE',
    DEVICE_MODEL = 'DEVICE_MODEL',
    DEVICE_STATUS = 'DEVICE_STATUS',
    DGE_SERIAL_NUMBER = 'DGE_SERIAL_NUMBER',
    DISCOVERY_CONFIGURATION_SERIAL_NUMBER = 'DISCOVERY_CONFIGURATION_SERIAL_NUMBER',
    DISCOVERY_NAME = 'DISCOVERY_NAME',
    DISCOVERY_TYPE = 'DISCOVERY_TYPE',

    ELEMENT_NAME = 'ELEMENT_NAME',
    ELEMENT_CATEGORY = 'ELEMENT_CATEGORY',

    FREEFORM = 'FREEFORM',

    IN_ALARM = 'IN_ALARM',
    INCLUDE_CONNECTING_DEVICES = 'INCLUDE_CONNECTING_DEVICES',

    LOCATION_SERIAL_NUMBER = 'LOCATION_SERIAL_NUMBER',
    LINKED_DEVICE_SERIAL_NUMBER = 'LINKED_DEVICE_SERIAL_NUMBER',

    MAP_NAME = 'MAP_NAME',
    MONITORCONFIG_TYPE = 'MONITORCONFIG_TYPE',
    MASTER_DEVICE_SERIAL_NUMBER = 'MASTER_DEVICE_SERIAL_NUMBER',

    OS_VERSION = 'OS_VERSION',

    PARENT_CONTAINER_SERIAL_NUMBER = 'PARENT_CONTAINER_SERIAL_NUMBER',
    PERMISSION_CLASS_SERIAL_NUMBER = 'PERMISSION_CLASS_SERIAL_NUMBER',

    RECURSIVE_CONTAINER_SERIAL_NUMBER = 'RECURSIVE_CONTAINER_SERIAL_NUMBER',
    RECURSIVE_CONTAINER = 'RECURSIVE_CONTAINER',

    REPORT_TYPE = 'REPORT_TYPE',
    ADHOC_REPORT_NAME = 'ADHOC_REPORT_NAME',
    REPORT_QUERY_SERIAL_NUMBER = 'REPORT_QUERY_SERIAL_NUMBER',
    REPORT_QUERY_NAME = 'REPORT_QUERY_NAME',
    SCHEDULED_REPORT_NAME = 'SCHEDULED_REPORT_NAME',
    SCHEDULED_REPORT_SERIAL_NUMBER = 'SCHEDULED_REPORT_SERIAL_NUMBER',
    SEVERITY_DISPLAY_TYPE = 'SEVERITY_DISPLAY_TYPE',

    SUBNET_SERIAL_NUMBER = 'SUBNET_SERIAL_NUMBER',

    TAG_1 = 'TAG_1',
    TAG_2 = 'TAG_2',
    TAG_3 = 'TAG_3',
    TAG_4 = 'TAG_4',
    TAG_5 = 'TAG_5',
    TEST_SERIAL_NUMBER = 'TEST_SERIAL_NUMBER',
    TEST_NAME = 'TEST_NAME',
    TEST_STATUS = 'TEST_STATUS',
    TEST_TYPE = 'TEST_TYPE',
    TEST_SUB_TYPE = 'TEST_SUB_TYPE',
    TEMPLATE_SERIAL_NUMBER = 'TEMPLATE_SERIAL_NUMBER',
    TEMPLATE_PROFILE_HASH = 'TEMPLATE_PROFILE_HASH',
    TEMPLATE_TYPE = 'TEMPLATE_TYPE',
    TEMPLATE_NAME = 'TEMPLATE_NAME',
    TIME_IN_STATE = 'TIME_IN_STATE',

    USER_SERIAL_NUMBER = 'USER_SERIAL_NUMBER',
    USER_NAME = 'USER_NAME',
    USER_LOGIN_ID = 'USER_LOGIN_ID',
    USER_EMAIL_ADDRESS = 'USER_EMAIL_ADDRESS',
    USER_SELECTED = 'USER_SELECTED',

    VENDOR_NAME = 'VENDOR_NAME',
    VM_STATUS = 'VM_STATUS',
}

export const getApiJsonSearchOptionName = (searchOption: ApiJsonSearchOption): string => {
    switch (searchOption) {
    case ApiJsonSearchOption.DEPARTMENT_SERIAL_NUMBER:
        return 'Organization';
    case ApiJsonSearchOption.DEVICE_MODEL:
        return 'Model / Version';
    default:
        return searchOption.replace('_SERIAL_NUMBER', '')
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    }
};

export type ApiJsonResponse<T> = {
    success: boolean;
    errorCode: number | null;
    errorMessage: string | null;
    timestamp: number;
    timestampStr: string;
    result: T;
}

export type ApiJsonPagedRequest = {
    paging?: {
        limit: number;
        page: number;
    };
}

export type ApiJsonSortedRequest = {
    sorting?: {
        sortField: string;
        sortDirection: string;
    };
}

export type ApiJsonSearchedRequest = {
    searchCriterias?: ApiJsonSearchCriterion[];
}

export type ApiJsonPagedData = {
    paging: {
        total: number;
    };
}

export type ApiJsonMessagedResponse = {
    message: string;
}

export type ApiJsonSearchCriterion = {
    searchOption: ApiJsonSearchOption;
    searchTerms: string;
}

const ApiJson = axios.create({
    baseURL: '/api/json',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
    }
});

ApiJson.interceptors.response.use(function (response: AxiosResponse<ApiJsonResponse<unknown>>) {
    if (response.status === 401 || response.data.errorCode === -401) {
        window.location.replace('/');
    }

    if (response.data.success === false) {
        return Promise.reject(response.data.errorMessage ?? 'unknown error');
    }

    return response;
}, function (error) {
    return Promise.reject(error);
});

export default ApiJson;
