import styles from './style.module.css';
import headerStyles from '../LayoutHeader/style.module.css';
import {useAppDispatch, useAppSelector} from '../../../../store';
import {useEffect} from 'react';
import {getProducts} from './slice';
import {enableGlobalItComplete} from '../../data/globalConfigurationSlice';
import Button from '../../../../clf/form/Button';
import {UserGroup} from '../../../../services/AuthApiService';

const AppLauncher = () => {
    const dispatch = useAppDispatch();
    const app = useAppSelector(({app}) => app);
    const auth = useAppSelector(({auth}) => auth);
    const session = useAppSelector(({session}) => session);
    const appLauncher = useAppSelector(({appLauncher}) => appLauncher);
    const enabled = app.data.itc && session.data.user && !auth.data.auth?.represented && session.data.itc?.itCompleteEnabled;

    useEffect(() => {
        dispatch(getProducts(enabled ? session.data.user?.serialNumber || 0 : 0));
    }, [enabled]);

    return !enabled
        ? (
            <div className={`${headerStyles.link} ${headerStyles.k1} ${headerStyles.dropDownTrigger}`} tabIndex={0}>
                <div className={headerStyles.dropDown}>
                    <div className={headerStyles.dropDownTitle}>Single sign-on</div>

                    <div className={styles.sso}>
                        {app.data.itc?.itCompleteSsoEnabled
                            ? <>
                                <Button
                                    onClick={() => window.location.replace(app.data.itc?.appUrl || '')}
                                >
                                    Log In with KaseyaOne
                                </Button>

                                <div className={styles.ssoHint}>Your Admin has enabled single sign-on.</div>
                            </>
                            : auth.data.auth?.representedUser?.userGroup === UserGroup.SUPER
                                ? <>
                                    <Button
                                        onClick={() => dispatch(enableGlobalItComplete())}
                                    >
                                        Enable Log In with KaseyaOne
                                    </Button>

                                    <div className={styles.ssoHint}>Once enabled, your users can enable Log In with
                                        KaseyaOne for themselves.
                                    </div>
                                </>
                                : <div className={styles.ssoHint}>You do not have access to SSO. Contact your Admin to enable Log In with KaseyaOne.</div>
                        }

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://helpdesk.kaseya.com/hc/en-gb/articles/360020946058-KaseyaOne-Overview"
                            className={styles.ssoLink}
                        >
                            Learn More
                        </a>
                    </div>
                </div>
            </div>
        )
        : (
            <div className={`${headerStyles.link} ${headerStyles.dropDownTrigger} ${headerStyles.applauncher}`} tabIndex={0}>
                <div className={styles.wrap}>
                    <div className={styles.head}>
                        <a className={styles.k1Link} target="_blank" rel="noreferrer" href="https://one.kaseya.com">Kaseya One</a>

                        <button className={styles.close} onClick={(e) => (e.target as HTMLButtonElement).blur()} />
                    </div>

                    <div className={styles.body}>
                        <div className={styles.section}>
                            <div className={styles.sectionTitle}>My IT Complete</div>

                            <div className={styles.grid}>
                                {appLauncher.data.main.map((i) => <a
                                    key={i.productId}
                                    className={styles.gridItem}
                                    href={i.landingPageUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        backgroundImage: `url("${i.productImageUrl || ''}")`
                                    }}
                                >
                                    <span>{i.name}</span>
                                </a>)}
                            </div>
                        </div>

                        <div className={styles.section}>
                            <div className={styles.sectionTitle}>Discover More</div>

                            <div className={styles.list}>
                                {appLauncher.data.more.map((i) => <a
                                    key={i.productId}
                                    className={styles.gridItem}
                                    href={i.landingPageUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        backgroundImage: `url("${i.productImageUrl || ''}")`
                                    }}
                                >
                                    <span>{i.name}</span>
                                    <span className={styles.gridItemDesc}>{i.description}</span>
                                </a>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
};

export default AppLauncher;
