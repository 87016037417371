import {Route, Routes} from 'react-router-dom';
import MfaConfirmation from './components/MfaConfirmation';
import MfaVerification from './components/MfaVerification';
import MfaConfirmed from './components/MfaConfirmed';
import MfaRemind from './components/MfaRemind';

const Mfa = () => {
    return <Routes>
        <Route path="confirmed" element={<MfaConfirmed />} />

        <Route path="remind" element={<MfaRemind />} />

        <Route path="confirmation" element={<MfaConfirmation />} />

        <Route path="verification" element={<MfaVerification />} />
    </Routes>;
};

export default Mfa;