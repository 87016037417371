import {createSlice} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {NcmClosestSwitch} from '../../../services/NcmApiService';
import {AppDispatch} from '../../../store';

interface ClosestSwitchSlice {
    query: string;
    closestSwitch: NcmClosestSwitch | null;
}

const initialState: ClosestSwitchSlice = {
    query: '',
    closestSwitch: null
};

const closestSwitchSlice = createSlice({
    name: 'closestSwitch',
    initialState,
    reducers: {
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        setClosestSwitch: (state, action) => {
            state.closestSwitch = action.payload;
        }
    }
});

const findClosestSwitch = (query: string) => (dispatch: AppDispatch) => {
    TraverseApiService.ncm.findClosestSwitch(query)
        .then((response) => dispatch(setClosestSwitch(response.data)))
        .catch((response) => dispatch(showError(response.data, response)));
};

export {
    findClosestSwitch
};

export const {
    setQuery,
    setClosestSwitch
} = closestSwitchSlice.actions;

export default closestSwitchSlice.reducer;