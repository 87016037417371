import styles from './style.module.css';

import {useAppDispatch, useAppSelector} from '../../../../store';
import {NavLink} from 'react-router-dom';
import {Severity} from '../../../../utils/Severity';
import useInterval from '../../../../hooks/useInterval';
import {getSummary, setDetached} from './slice';
import {useEffect, useMemo} from 'react';
import PortalPopup from '../PortalPopup';

const SUMMARY_FETCH_DELAY = 60 * 1000; // 60 seconds
const COUNT_MIL = 1000000; // number of events exceeds 1 million
const COUNT_BIL = 1000000000; // number of events exceeds 1 billion

const format = (count: number): string => {
    if (count < 9999)
        return `${count}`;

    if (count < COUNT_MIL)
        return `${Math.floor(count / (COUNT_MIL / 1000))} K`;

    if (count < COUNT_BIL)
        return `${Math.floor(count / (COUNT_BIL / 1000))} M`;

    return '1 G +';
};

const SummaryPanelContent = () => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector(({auth}) => auth.data.auth);
    const user = useAppSelector(({session}) => session.data.user);
    const summaryPanel = useAppSelector(({summaryPanel}) => summaryPanel);

    const userGroup = useMemo(() => auth?.representedUser?.userGroup, [auth?.representedUser]);

    useInterval(() => {
        dispatch(getSummary());
    }, userGroup && user ? SUMMARY_FETCH_DELAY : null);

    useEffect(() => {
        dispatch(getSummary());
    }, []);

    return !userGroup || !user ? null : <div className={`${styles.main} ${summaryPanel.detached ? styles.detached : ''}`}>
        <div className={styles.severityBlock}>
            <NavLink to={Severity.genDeviceStatusUrl()} className={styles.severityBlockTitle}>Devices:</NavLink>
            {summaryPanel.data.devices.map((i, k) => <NavLink
                key={k}
                to={Severity.genDeviceStatusUrl(i.severity)}
                className={`${styles[i.severity]} ${i.highlight ? styles.highlight : ''}`}
                title={i.severity}
            >
                {format(i.count)}
            </NavLink>)}
        </div>
        <div className={styles.severityBlock}>
            <NavLink to={Severity.genEventStatusUrl(userGroup, user)} className={styles.severityBlockTitle}>Events:</NavLink>
            {summaryPanel.data.events.map((i, k) => <NavLink
                key={k}
                to={Severity.genEventStatusUrl(userGroup, user, i.severity)}
                className={`${styles[i.severity]} ${i.highlight ? styles.highlight : ''}`}
                title={i.severity}
            >
                {format(i.count)}
            </NavLink>)}
        </div>
        {!summaryPanel.detached && <button className={styles.detach} onClick={() => dispatch(setDetached(!summaryPanel.detached))} title={'Detach panel'} />}
    </div>;
};

const SummaryPanel = () => {
    const dispatch = useAppDispatch();
    const summaryPanel = useAppSelector(({summaryPanel}) => summaryPanel);

    return !summaryPanel.enabled
        ? null
        : summaryPanel.detached
            ? <PortalPopup
                title={'Summary panel'}
                onClose={() => dispatch(setDetached(false))}
            ><SummaryPanelContent /></PortalPopup>
            : <SummaryPanelContent />;
};

export default SummaryPanel;
