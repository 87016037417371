import ApiJson, {
    ApiJsonMessagedResponse,
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse,
    ApiJsonSearchedRequest,
    ApiJsonSortedRequest,
} from './ApiJson';
import ApiV1, {ApiV1Response} from './ApiV1';

export enum DiscoveryIntervalUnit {
    HOUR = 'HOUR',
    DAY = 'DAY',
}

export enum CloudDiscoveryFrequencyUnit {
    MINUTES = 'minutes',
    HOURS = 'hours',
    DAYS = 'days',
}

export enum CloudType {
    AZURE = 'azure',
    AWS = 'aws',
}

export enum CloudDiscoveryOption {
    ONE_TIME = 'oneTime',
    RECURRING = 'recurring',
}

export enum CloudAutoProvisioningAction {
    CREATE = 'create',
    UPDATE = 'update',
    SUSPEND = 'suspend',
    DELETE = 'delete',
    IGNORE = 'ignore',
    LOG_ONLY = 'logOnly',
    INVALID = 'invalid',
    UNKNOWN = 'unknown',
}

export enum DiscoveryStatus {
    RUNNING = 0,
    SUCCESS = 1,
    SCHEDULED = 2,
    CANCELED = 3,
    STALE = 4,
    ERROR = 5,
}

export type DiscoverySessionInfo = {
    deletedJob: boolean;
    departmentName: string;
    departmentSerialNumber: number;
    discoveryConfigSerialNumber: number;
    discoveryStatus: number;
    discoveryStatusStr: string;
    discoveryType: number;
    discoveryTypeStr: string;
    lastScan: string;
    runOnce: boolean;
    sessionID: number;
    sessionName: string;
    userSerialNumber: number;
}

export type DiscoveryStatusInfo = {
    errorLog: string;
    locationName: string;
    noOfNewDiscoveredDevices: number;
    noOfProvisionedDevices: number;
    otherInfo: string;
    scopes: string[];
}

export type DiscoveryHistoryInfo = {
    message: string;
    time: string;
}

export type DiscoveryHistoryWrapperInfo =  {
    historyStatusList: DiscoveryHistoryInfo[];
    startTimestamp: number;
    startTimeStampStr: string;
    user: string;
    userType: string;
}

export type DiscoveryDeviceInfo =  {
    deviceId: number;
    deviceType: string;
    ipAddress: string;
    name: string;
    provisioned: boolean;
}

export type DiscoveryBase = {
    autoProvision: boolean;
    departmentSerialNumber: number;
    deviceTypes: number[];
    discoverTopology: boolean;
    emailAddress: string;
    includeDeviceTypes: boolean;
    interval: number;
    intervalUnit: DiscoveryIntervalUnit;
    locationName: string;
    maxHops: number;
    runOnce: boolean;
    scopes: string[];
    seedRouterIp: string;
    serialNumber?: number;
    sessionName: string;
    snmpSharedConfigs: number[];
    updateKnownDevicesTopologyOnly: boolean;
    useSeedRouter: boolean;
    vmwareSharedConfigs: number[];
}

export type DiscoveryBaseSave = DiscoveryBase & {
    serialNumber?: number;
}

export type DiscoveryDetailInfo = DiscoveryBase & {
    departmentName: string;
    devices: DiscoveryDeviceInfo[];
    discoveryConfigSerialNumber: number;
    discoveryStatus: number;
    discoveryStatusStr: string;
    discoveryType: number;
    discoveryTypeStr: string;
    history: DiscoveryHistoryWrapperInfo;
    lastScan: string;
    locationSerialNumber: number;
    provisioning: boolean;
    sessionID: number;
    status: DiscoveryStatusInfo;
    userSerialNumber: number;
}

export type ScheduleDiscoveryInfo = {
    message: string;
    name: string;
    status: string;
}

type DiscoveriesRequest = ApiJsonPagedRequest & ApiJsonSearchedRequest & ApiJsonSortedRequest;

type DiscoveriesResponse = ApiJsonPagedData & {
    sessions: DiscoverySessionInfo[];
}

type DiscoveryDetailResponse = {
    discovery: DiscoveryDetailInfo;
}

export type ScheduleDiscoveryRequest = Omit<DiscoveryBaseSave, 'sessionName' | 'scopes' | 'departmentSerialNumber'> & {
    accountSerialNumber: number;
    name: string;
    scope: string;
}

type ScheduleDiscoveryResponse = {
    discoveryInfos: ScheduleDiscoveryInfo;
}

type ProvisionDevicesRequest = {
    accountSerialNumber: number;
    devicesToProvision: DiscoveryDeviceInfo[];
    discoverySessionId: number;
}

type UpdateDiscoveryRequest = ScheduleDiscoveryRequest;

type UpdateDiscoveryResponse = ScheduleDiscoveryResponse;

type DeleteDiscoveryRequest = ApiJsonSearchedRequest;

type DeleteDiscoveryResponse = ScheduleDiscoveryResponse;

export enum CloudDiscoveryStatus {
    SCAN_RUNNING = 'Scan Running',
    SCHEDULED = 'scheduled',
}

export type CloudDiscovery = {
    serialNumber: number;
    name: string;
    organizationSerialNumber: number;
    locationSerialNumber: number;
    cloudType: CloudType;
    discoveryFrequency: number;
    discoveryFrequencyUnit: CloudDiscoveryFrequencyUnit;
    discoveryOption: CloudDiscoveryOption;
    autoProvisioningEnabled: boolean;
    newInstancesAction: CloudAutoProvisioningAction;
    suspendedInstancesAction: CloudAutoProvisioningAction;
    deletedInstancesAction: CloudAutoProvisioningAction;
    monitorConfigSerialNumber?: number;
    parameters?: Record<string, string>;

    organizationName: string;
    locationName: string;
    monitorConfigName: string;
    scanStatus: CloudDiscoveryStatus | string;
    nextScanTime: string;
};

export type CloudInstance = {
    serialNumber: number;
    instanceName: string;
    instanceId: string;
    instanceAttributeMap: Record<string, string>;
    attachedInstances: CloudInstance[];
    provisioned: boolean;
}

export type CloudInstanceAttributes = {
    cloudType: CloudType;
    cloudInstanceAttributes: Record<string, string>;
}

export enum MonitorInstanceParameterType {
    TEXT = 'text',
    PASSWORD = 'password',
    COMBOBOX = 'combobox',
    RADIO = 'radio',
}

export enum MonitorInstanceParameterClass {
    INTEGER = 'integer',
    STRING = 'string',
    BOOLEAN = 'boolean',
}

export type MonitorInstanceParameter = {
    name: string;
    displayString: string;
    required: boolean;
    defaultValue: string;
    options: {
        key: string;
        value: string;
    }[];
    parameterClass: MonitorInstanceParameterClass;
    parameterType: MonitorInstanceParameterType;
    editAllowed: boolean;
    size: number;
    subtype: string;
    hidden: boolean;
    comment: string;
}

export type MonitorInstanceParameters = {
    monitorType: CloudType;
    monitorInstanceParameters: MonitorInstanceParameter[];
}

class DiscoveryApiService {
    getSessions(data: DiscoveriesRequest) {
        return ApiJson.post<ApiJsonResponse<DiscoveriesResponse>>('/discovery/getSessions', data);
    }

    getDiscoveryDetails(discoverySessionId: number) {
        return ApiJson.post<ApiJsonResponse<DiscoveryDetailResponse>>('/discovery/getDiscoveryDetails', {discoverySessionId});
    }

    scheduleDiscovery(data: ScheduleDiscoveryRequest) {
        return ApiJson.post<ApiJsonResponse<ScheduleDiscoveryResponse>>('/discovery/scheduleDiscovery', data);
    }

    updateDiscovery(data: UpdateDiscoveryRequest) {
        return ApiJson.post<ApiJsonResponse<UpdateDiscoveryResponse>>('/discovery/updateDiscovery', data);
    }

    deleteDiscovery(data: DeleteDiscoveryRequest) {
        return ApiJson.post<ApiJsonResponse<DeleteDiscoveryResponse>>('/discovery/deleteDiscovery', data);
    }

    cancelDiscovery(discoverySessionIds: number[], sessionName?: string) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/discovery/cancelDiscovery', {discoverySessionIds, sessionName});
    }

    runDiscovery(serialNumber: number) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/discovery/runDiscovery', {serialNumber});
    }

    provisionDevices(data: ProvisionDevicesRequest) {
        return ApiJson.post<ApiJsonResponse<void>>('/discovery/provisionDevices', data);
    }

    getCloudDiscoveries() {
        return ApiV1.get<ApiV1Response<CloudDiscovery[]>>('/discovery/cloud');
    }

    createCloudDiscovery(cloudDiscovery: CloudDiscovery) {
        return ApiV1.post<ApiV1Response<CloudDiscovery[]>>('/discovery/cloud', cloudDiscovery);
    }

    getCloudDiscovery(serialNumber: number) {
        return ApiV1.get<ApiV1Response<CloudDiscovery[]>>(`/discovery/cloud/${serialNumber}`);
    }

    updateCloudDiscovery(cloudDiscovery: CloudDiscovery) {
        return ApiV1.put<ApiV1Response<CloudDiscovery[]>>(`/discovery/cloud/${cloudDiscovery.serialNumber}`, cloudDiscovery);
    }

    deleteCloudDiscovery(serialNumber: number) {
        return ApiV1.delete<ApiV1Response<CloudDiscovery[]>>(`/discovery/cloud/${serialNumber}`);
    }

    runCloudDiscovery(serialNumber: number) {
        return ApiV1.post<ApiV1Response<void>>(`/discovery/cloud/${serialNumber}/run`);
    }

    provisionCloudDiscovery(serialNumber: number, provisionIds: number[], unprovisionIds: number[]) {
        return ApiV1.post<ApiV1Response<void>>(`/discovery/cloud/${serialNumber}/provision`, {provisionIds, unprovisionIds});
    }

    getCloudInstances(serialNumber: number) {
        return ApiV1.get<ApiV1Response<CloudInstance[]>>(`/discovery/cloud/${serialNumber}/cloud-instances`);
    }

    getMonitorInstanceParameters(monitorType: CloudType) {
        return ApiV1.get<ApiV1Response<MonitorInstanceParameters[]>>(`/discovery/cloud/${monitorType}/monitor-instance-parameters`);
    }

    getCloudInstanceAttributes(cloudType: CloudType) {
        return ApiV1.get<ApiV1Response<CloudInstanceAttributes[]>>(`/discovery/cloud/${cloudType}/cloud-instance-attributes`);
    }
}

export default new DiscoveryApiService();
