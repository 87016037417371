import styles from './styles.module.css';
import {HTMLAttributes, useEffect, useRef} from 'react';
import {CompanyLogo} from '../../common/icons/TraverseIcons';

interface LoginProps extends HTMLAttributes<HTMLDivElement> {
    onEnterKeyCallback: () => void;
}

const LandingTemplate = ({onEnterKeyCallback, children}: LoginProps) => {
    const mainContentDiv = useRef<HTMLDivElement>(null);
    const currentYear = new Date().getFullYear();

    useEffect(()=>{
        mainContentDiv.current?.focus();
    }, []);

    return <div className={styles.background}>
        <a href="https://www.kaseya.com/" target="_blank" rel="noreferrer" className={styles.logo}>
            <CompanyLogo />
        </a>

        <div
            className={styles.content}
            ref={mainContentDiv}
            tabIndex={-1}
            onKeyDown={(event) => {
                // ignore all keys other than `Enter`
                if (event.key !== 'Enter') return;

                const target: HTMLElement = event.target as HTMLElement;

                // if `Enter` is pressed on a button/anchor, handle it as a click, otherwise perform a callback action
                if (['button', 'a'].includes(target.tagName.toLowerCase())) {
                    // by default `Enter` key submits a form, make sure to prevent this behaviour
                    event.preventDefault();
                    event.stopPropagation();
                    target.click();
                } else {
                    onEnterKeyCallback();
                }
            }}
        >
            <div className={styles.panel}>
                {children}
            </div>

            <div className={styles.copyright}>
                <a href="https://helpdesk.kaseya.com" target="_blank" rel="noreferrer">Help &amp; Support</a>

                <br/>

                <span>
                    &copy; Kaseya {currentYear}
                </span>
            </div>
        </div>
    </div>;
};

export default LandingTemplate;