import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService, {ApplicationInfo} from '../../../services/TraverseApiService';
import {LicenseStatus} from '../../../services/LicenseApiService';
import {ItCompleteStatus} from '../../../services/ItcApiService';

interface AppSlice {
    navigationVisible: boolean;
    initiated: boolean;
    newsAvailable: boolean;
    appVersion: string;
    info?: ApplicationInfo;
    itc?: ItCompleteStatus;
    license?: LicenseStatus;
}

const initialState: AsyncSlice<AppSlice> = {
    data: {
        navigationVisible: true,
        initiated: false,
        newsAvailable: false,
        appVersion: '',
    },
};

export const initApp = createAsyncThunk('app/init', async (): Promise<AppSlice> => {
    const license: LicenseStatus = (await TraverseApiService.license.getLicenseStatus()).data.result[0];

    const info: ApplicationInfo = (await TraverseApiService.getApplicationInfo()).data.result[0];

    const itc: ItCompleteStatus = (await TraverseApiService.itc.getStatus()).data.result[0];

    const appVersion = [info.releaseInfo.releaseVersion, info.releaseInfo.stage, info.releaseInfo.buildDate].join('-');

    const newsAvailable = localStorage.getItem('whatsNewVisited') !== appVersion;

    return {
        license,
        info,
        itc,
        appVersion,
        newsAvailable,
        navigationVisible: true,
        initiated: !license.expired,
    };
});

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setWhatsNewVisited: (state) => {
            localStorage.setItem('whatsNewVisited', state.data.appVersion);
            state.data.newsAvailable = false;
        },
        setItCompleteSsoEnabled: (state, {payload}: PayloadAction<boolean>) => {
            if (state.data.itc) {
                state.data.itc.itCompleteSsoEnabled = payload;
            }
        },
        setNavigationVisible: (state, {payload}: PayloadAction<boolean>) => {
            state.data.navigationVisible = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(initApp.pending, (state) => {
                state.error = undefined;
            })
            .addCase(initApp.fulfilled, (state, action) => {
                state.data = action.payload;
            })
            .addCase(initApp.rejected, (state, action) => {
                state.error = action.error;
            });
    }
});

export const {setWhatsNewVisited, setItCompleteSsoEnabled, setNavigationVisible} = appSlice.actions;

export default appSlice.reducer;