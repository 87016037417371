import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError, showSuccess} from '../ui/snack-bar/notificationSlice';
import {GlobalConfiguration} from '../../../services/GlobalConfigurationApiService';
import {
    BmsTicketPriority,
    BmsTicketQueue,
    BmsTicketStatus,
    BmsTicketType,
} from '../../../services/BmsApiService';
import {AppDispatch, RootState} from '../../../store';

export interface GlobalConfigurationSlice {
    loading: boolean;
    configuration?: GlobalConfiguration;
    bmsTicketTypes: BmsTicketType[];
    bmsTicketQueues: BmsTicketQueue[];
    bmsTicketStatuses: BmsTicketStatus[];
    bmsTicketPriorities: BmsTicketPriority[];
}

const initialState: GlobalConfigurationSlice = {
    loading: false,
    bmsTicketTypes: [],
    bmsTicketQueues: [],
    bmsTicketStatuses: [],
    bmsTicketPriorities: [],
};

export const fetchBmsTicketStatic = createAsyncThunk('bms/ticket/static', async () => {
    const [bmsTicketTypes, bmsTicketQueues, bmsTicketStatuses, bmsTicketPriorities] = await Promise.all([
        TraverseApiService.bms.getTicketTypes(),
        TraverseApiService.bms.getTicketQueues(),
        TraverseApiService.bms.getTicketStatuses(),
        TraverseApiService.bms.getTicketPriorities()
    ]);

    return {
        bmsTicketTypes: bmsTicketTypes.data.success ? bmsTicketTypes.data.result : [],
        bmsTicketQueues: bmsTicketQueues.data.success ? bmsTicketQueues.data.result : [],
        bmsTicketStatuses: bmsTicketStatuses.data.success ? bmsTicketStatuses.data.result : [],
        bmsTicketPriorities: bmsTicketPriorities.data.success ? bmsTicketPriorities.data.result : [],
    };
});

const globalConfigurationSlice = createSlice({
    name: 'globalConfiguration',
    initialState,
    reducers: {
        setGlobalConfiguration: (state, {payload}: PayloadAction<GlobalConfiguration>) => {
            state.configuration = payload;
        },
        setGlobalConfigurationLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.loading = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBmsTicketStatic.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBmsTicketStatic.fulfilled, (state, {payload}) => {
                state.loading = false;
                state.bmsTicketTypes = payload.bmsTicketTypes;
                state.bmsTicketQueues = payload.bmsTicketQueues;
                state.bmsTicketStatuses = payload.bmsTicketStatuses;
                state.bmsTicketPriorities = payload.bmsTicketPriorities;
            })
            .addCase(fetchBmsTicketStatic.rejected, (state) => {
                state.loading = false;
            });
    }
});

export const {setGlobalConfiguration, setGlobalConfigurationLoading} = globalConfigurationSlice.actions;

export const cleanupBmsIntegrationSettingsIfNeeded = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const configuration: GlobalConfiguration | null = getState().globalConfiguration.configuration ?? null;

    if (configuration?.bmsIntegrationSettings.bmsIntegrationEnabled === false && !!configuration.bmsIntegrationSettings.serverUrl) {
        dispatch(patchGlobalConfiguration(
            {bmsIntegrationSettings: {bmsIntegrationEnabled: false}},
            '',
            'Failed to cleanup BMS integration settings'
        ));
    }
};

export const getGlobalConfiguration = () => async (dispatch: AppDispatch) => {
    dispatch(setGlobalConfigurationLoading(true));

    try {
        let globalConfiguration = (await TraverseApiService.globalConfiguration.get()).data.result[0];

        if (globalConfiguration.bmsIntegrationSettings.bmsIntegrationEnabled === false && !!globalConfiguration.bmsIntegrationSettings.serverUrl) {
            const bmsIntegrationSettings = {bmsIntegrationEnabled: false};
            globalConfiguration = (await TraverseApiService.globalConfiguration.patch({bmsIntegrationSettings})).data.result[0];
        }

        dispatch(setGlobalConfiguration(globalConfiguration));
    } catch (e) {
        dispatch(showError('Failed to fetch global configuration', e));
    }

    dispatch(setGlobalConfigurationLoading(false));
};

export const patchGlobalConfiguration = (
    patch: DeepPartial<GlobalConfiguration>,
    successMessage = 'Global configuration saved',
    errorMessage = 'Failed to save global configuration',
    callback: Callback = () => undefined,
) => async (dispatch: AppDispatch) => {
    dispatch(setGlobalConfigurationLoading(true));

    try {
        const response = await TraverseApiService.globalConfiguration.patch(patch);

        dispatch(setGlobalConfiguration(response.data.result[0]));

        if (successMessage) {
            dispatch(showSuccess(successMessage));
        }

        callback();
    } catch (e) {
        dispatch(showError(errorMessage, e));
    }

    dispatch(setGlobalConfigurationLoading(false));
};

export const enableGlobalItComplete = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const itc = getState().app.data.itc;
    try {
        await TraverseApiService.globalConfiguration.registerItCompleteOidcClient();
        window.location.replace(itc?.appUrl ?? '');
    } catch (e) {
        dispatch(showError('Failed to enable IT Complete (SSO)', e));
    }
};

export default globalConfigurationSlice.reducer;