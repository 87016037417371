import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import TraverseApiService from '../../../../services/TraverseApiService';

interface AppLauncherProduct {
    productId: string;
    landingPageUrl: string;
    name: string;
    description: string;
    isPinned: boolean;
    productImageUrl: string;
}

interface AppLauncherSlice {
    main: AppLauncherProduct[];
    more: AppLauncherProduct[];
}


const initialState: AsyncSlice<AppLauncherSlice> = {
    data: {
        main: [],
        more: [],
    }
};

export const getProducts = createAsyncThunk('products/get', async (payload: number): Promise<AppLauncherSlice> => {
    const products: AppLauncherProduct[] = payload !== 0 ? (await TraverseApiService.itc.getItcProducts()).data : [];

    return {
        main: products.filter((i) => i.isPinned),
        more: products.filter((i) => !i.isPinned),
    };
});

const appLauncherSlice = createSlice({
    name: 'appLauncherSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getProducts.pending, () => {
                // nothing to do here, ignore
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.data = action.payload;
            })
            .addCase(getProducts.rejected, (state, action) => {
                console.log(action.error);
            });
    }
});

export default appLauncherSlice.reducer;