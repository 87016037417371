import {Navigate, Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../store';
import {useEffect} from 'react';
import {setLandingPage} from '../common/components/data/sessionSlice';
import {genHref, isEmptyUrl} from '../utils/UrlUtils';
import TraverseApiService from '../services/TraverseApiService';

const PrivateRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const {user, landingPage} = useAppSelector(({session}) => session.data);

    useEffect(() => {
        const currentPage = genHref(location, searchParams.toString()).replace('%23', '#') || '/';
        const isBlankPage = isEmptyUrl(currentPage);

        if (!user && !isBlankPage) {
            dispatch(setLandingPage(currentPage));
        } else if (user && landingPage === currentPage) {
            dispatch(setLandingPage());
        }

        if (user && !landingPage && isBlankPage) {
            const userLandingPage = TraverseApiService.user.getLandingPage(user);

            if (!isEmptyUrl(userLandingPage)) {
                navigate(userLandingPage);
            }
        }
    }, [user, location, searchParams]);

    return !user
        ? <Navigate to={'/'}/>
        : <Outlet/>;
};

export default PrivateRoute;