import {createSlice} from '@reduxjs/toolkit';
import {addResult} from '../results-panel/resultsSlice';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {
    NcmDevice,
    NcmDeviceConfigurationRevisionDetails,
    NcmPlugin,
    NcmPluginCategory,
} from '../../../services/NcmApiService';
import {AppDispatch, RootState} from '../../../store';

interface NcmPluginsSlice {
    pluginGroups: NcmPluginCategory[],
    pluginsDialogOpened: boolean,
    pluginResultLoading: boolean,
    selectedPlugin: NcmPlugin | null,
    selectedDevices: NcmDevice[],
    revision: NcmDeviceConfigurationRevisionDetails | null,
    jobParameters: []
}

const initialState: NcmPluginsSlice = {
    pluginGroups: [],
    pluginsDialogOpened: false,
    pluginResultLoading: false,
    selectedPlugin: null,
    selectedDevices: [],
    revision: null,
    jobParameters: []
};

const pluginsSlice = createSlice({
    name: 'ncmPlugins',
    initialState,
    reducers: {
        setPluginGroups: (state, action) => {
            state.pluginGroups = action.payload;
        },
        openPluginDialog: (state) => {
            state.pluginsDialogOpened = true;
        },
        closePluginDialog: (state) => {
            state.pluginsDialogOpened = false;
        },
        setSelectedPlugin: (state, action) => {
            state.selectedPlugin = action.payload;
        },
        setSelectedDevices: (state, action) => {
            state.selectedDevices = action.payload;
        },
        startLoading: (state) => {
            state.pluginResultLoading = true;
        },
        finishLoading: (state) => {
            state.pluginResultLoading = false;
        },
        setRevision: (state, action) => {
            state.revision = action.payload;
        },
        setJobParameters: (state, action) => {
            state.jobParameters = action.payload;
        }
    }
});

const {
    setPluginGroups,
    openPluginDialog,
    closePluginDialog,
    startLoading,
    setSelectedPlugin,
    setSelectedDevices,
    finishLoading,
    setRevision,
    setJobParameters
} = pluginsSlice.actions;

const loadPluginGroups = () => (dispatch: AppDispatch) => {
    TraverseApiService.ncm.getPlugins()
        .then((response) => dispatch(setPluginGroups(response.data)))
        .catch((response) => dispatch(showError('Failed to load plugins', response)));
};

const executePlugin = (jobParameters: FixType) => (dispatch: AppDispatch, getState: () => RootState) => {
    const {selectedPlugin, selectedDevices} = getState().ncmPlugins;

    if (!selectedPlugin) {
        return;
    }

    const request = buildRequest(selectedPlugin, jobParameters, selectedDevices);
    dispatch(startLoading());
    TraverseApiService.ncm.executePlugin(request)
        .then((response) => {
            dispatch(addExecutionResult(selectedPlugin, response.data));
            dispatch(closePluginDialog());
        })
        .catch((response) => {
            dispatch(finishLoading());
            dispatch(showError('Failed to execute plugin', response));
        });
};

const addExecutionResult = (selectedPlugin: NcmPlugin, results: FixType) => (dispatch: AppDispatch) => {
    dispatch(finishLoading());
    const result = {
        id: results.length ? results[0].id : '',
        name: selectedPlugin.toolName,
        type: 'TOOL_EXECUTION',
        payload: {
            results, selectedPlugin
        }
    };
    dispatch(addResult(result));
};

const buildRequest = (plugin: NcmPlugin, jobParameters: FixType, devices: FixType) => {
    const requestParameters = Object.keys(jobParameters).map((key) => jobParameters[key]);
    const deviceIds = devices.map((device: FixType) => device.id).join(',') + ',';
    requestParameters.push({
        key: 'ipResolutionData',
        value: deviceIds
    });
    requestParameters.push({
        key: 'ipResolutionScheme',
        value: 'deviceSn'
    });
    requestParameters.push({
        key: 'tool',
        value: plugin.toolName
    });
    return {
        jobName: plugin.toolName,
        jobType: 'Script Tool Job',
        jobParameters: requestParameters
    };
};

const openPlugin = (plugin: NcmPlugin, devices?: NcmDevice[]) => (dispatch: AppDispatch, getState: () => RootState) => {
    const {selectedDevices} = getState().ncmDevices;

    dispatch(openPluginDialog());
    dispatch(setSelectedPlugin(plugin));
    dispatch(setSelectedDevices(devices || selectedDevices));
    if (plugin && !plugin.inputs.length) {
        dispatch(executePlugin([]));
    }
};

const fetchRevision = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {selectedDevices} = getState().ncmPlugins;

    if (selectedDevices.length !== 1) {
        return;
    }
    const [device] = selectedDevices;
    const requestParams = {
        ipAddress: device.ipAddress,
        emeraldDeviceSN: device.id,
        managedNetwork: device.managedNetwork,
        configPath: '/Zyrion-Element-Document'
    };
    TraverseApiService.ncm.getRevision(requestParams)
        .then((response) => dispatch(setRevision(response.data || null)))
        .catch((response) => dispatch(showError('Failed to get revision', response)));
};

const initJobParameters = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {selectedPlugin} = getState().ncmPlugins;

    if (!selectedPlugin) {
        return;
    }

    const initialState = selectedPlugin.inputs.reduce((result: FixType, input: FixType) => {
        const fieldName = 'input.' + input.header;
        return {
            ...result,
            [fieldName]: {
                value: null,
                key: fieldName,
            }
        };
    }, {});
    dispatch(setJobParameters(initialState));
};

const updateJobParameter = (key: FixType, value: FixType) => (dispatch: AppDispatch, getState: () => RootState) => {
    const {jobParameters} = getState().ncmPlugins;
    dispatch(setJobParameters({...jobParameters, [key]: value}));
};

export {
    loadPluginGroups,
    openPlugin,
    executePlugin,
    closePluginDialog,
    fetchRevision,
    initJobParameters,
    updateJobParameter
};

export default pluginsSlice.reducer;