import styles from './style.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';
import Dropdown from '../../../../clf/Dropdown';
import {faCircle, faCircleCheck} from '@fortawesome/pro-solid-svg-icons';

const PasswordStrengthItem = ({matched, text}: {matched: boolean, text: string}) => {
    return <div>
        <FontAwesomeIcon
            icon={matched ? faCircleCheck : faCircle}
            style={{color: matched ? 'var(--clf-color-success)' : 'var(--clf-text-color-secondary)'}}
        /> {text}
    </div>;
};

type PasswordStrengthProps = {
    anchor?: HTMLElement;
    password: string;
    setStrong: (strong: boolean) => void;
    onClose: () => void;
};

const PasswordStrength = ({anchor, password, setStrong, onClose}: PasswordStrengthProps) => {
    const [uppercaseMatched, setUppercaseMatched] = useState(false);
    const [lowercaseMatched, setLowercaseMatched] = useState(false);
    const [numberMatched, setNumberMatched] = useState(false);
    const [specialMatched, setSpecialMatched] = useState(false);
    const [lengthMatched, setLengthMatched] = useState(false);

    useEffect(() => {
        const isUpper = /[A-Z]/.test(password);
        setUppercaseMatched(isUpper);

        const isLower = /[a-z]/.test(password);
        setLowercaseMatched(isLower);

        const isNumber = /[0-9]/.test(password);
        setNumberMatched(isNumber);

        const isSpecial = /[^A-Za-z0-9]/.test(password);
        setSpecialMatched(isSpecial);

        const isLength = /^.{16,}$/.test(password);
        setLengthMatched(isLength);

        setStrong(isUpper && isLower && isNumber && isSpecial && isLength);
    }, [password]);

    return !anchor ? null : <Dropdown
        parent={anchor}
        onClose={onClose}
    >
        <div className={styles.passwordStrength}>
            <PasswordStrengthItem
                matched={uppercaseMatched}
                text={'One uppercase character'}
            />

            <PasswordStrengthItem
                matched={numberMatched}
                text={'One number'}
            />

            <PasswordStrengthItem
                matched={lowercaseMatched}
                text={'One lowercase character'}
            />

            <PasswordStrengthItem
                matched={specialMatched}
                text={'One special character'}
            />

            <PasswordStrengthItem
                matched={lengthMatched}
                text={'16 characters minimum'}
            />
        </div>
    </Dropdown>;
};

export default PasswordStrength;
