import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ScheduleBuilder from './utils/ScheduleBuilder';
import TraverseApiService from '../../../../services/TraverseApiService';
import {showError, showSuccess} from '../../../../common/components/ui/snack-bar/notificationSlice';
import {AppDispatch, RootState} from '../../../../store';
import dayjs from 'dayjs';

export interface ScheduleDiscoverySlice {
    startTime: number;
    endTime: number | null;
    type: string; // TODO IMPROVE!
    recurEvery: number;
    weekdays: number[];
    monthlyScheduleType: string;
    monthToRecur: number;
    dayToRecur: number;
    weekNumber: number | 'L';
    dayOfWeekToRecur: number;
    cronExpression: string;
    discoveryEnabled: boolean;
    endTimeEnabled: boolean;
}

const initialState: ScheduleDiscoverySlice = {
    startTime: dayjs().startOf('minute').valueOf(),
    endTime: null,
    type: ScheduleBuilder.ONCE,
    recurEvery: 1,
    weekdays: [],
    monthlyScheduleType: ScheduleBuilder.DAY,
    monthToRecur: 1,
    dayToRecur: 1,
    weekNumber: 1,
    dayOfWeekToRecur: 1,
    cronExpression: '',
    discoveryEnabled: false,
    endTimeEnabled: false
};

const scheduleSlice = createSlice({
    name: 'scheduleDiscovery',
    initialState,
    reducers: {
        toggleDiscoveryEnabled: (state, {payload}: PayloadAction<boolean>) => {
            state.discoveryEnabled = payload;
            if (state.discoveryEnabled) {
                state.startTime = dayjs().startOf('minute').valueOf();
            }
        },
        setStartTime: (state, {payload}: PayloadAction<number>) => {
            state.startTime = payload;
        },
        setEndTime: (state, {payload}: PayloadAction<number>) => {
            state.endTime = payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setRecurEvery: (state, action) => {
            state.recurEvery = action.payload;
        },
        setWeekdays: (state, action) => {
            state.weekdays = action.payload;
        },
        setMonthToRecur: (state, action) => {
            state.monthToRecur = action.payload;
        },
        setDayToRecur: (state, action) => {
            state.dayToRecur = action.payload;
        },
        setWeekNumber: (state, action) => {
            state.weekNumber = action.payload;
        },
        setDayOfWeekToRecur: (state, action) => {
            state.dayOfWeekToRecur = action.payload;
        },
        setMonthlyScheduleType: (state, action) => {
            state.monthlyScheduleType = action.payload;
        },
        setCronExpression: (state, action) => {
            state.cronExpression = action.payload;
        },
        updateEndTimeEnabled: (state, {payload}: PayloadAction<boolean>) => {
            state.endTimeEnabled = payload;
            state.endTime = state.endTimeEnabled ? dayjs(state.startTime).add(1, 'year').valueOf() : null;
        },
        setScheduleProperties: (state, action) => {
            if (!action.payload) {
                return;
            }
            const schedule = ScheduleBuilder.buildState(action.payload, state);
            Object.assign(state, schedule);
            state.endTimeEnabled = state.endTime !== null;
            state.discoveryEnabled = true;
        }
    }
});

const getScheduleDiscovery = () => (dispatch: AppDispatch) => {
    TraverseApiService.ncm.getScheduleDiscovery()
        .then((response) => dispatch(setScheduleProperties(response.data)))
        .catch((response) => dispatch(showError('Failed to get schedule', response)));
};

const saveScheduleDiscovery = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const scheduleDiscovery = getState().scheduleDiscovery;

    if (scheduleDiscovery.discoveryEnabled) {
        const scheduler = ScheduleBuilder.buildScheduler(scheduleDiscovery);

        TraverseApiService.ncm.updateScheduleDiscovery(scheduler)
            .then(() => dispatch(showSuccess('Schedule saved')))
            .catch((response) => dispatch(showError('Failed to save schedule', response)));
    } else {
        TraverseApiService.ncm.deleteScheduleDiscovery()
            .then(() => dispatch(showSuccess('Schedule deleted')))
            .catch((response) => dispatch(showError('Failed to delete schedule', response)));
    }
};

export {
    getScheduleDiscovery,
    saveScheduleDiscovery
};

export const {
    toggleDiscoveryEnabled,
    setStartTime,
    setEndTime,
    setType,
    setRecurEvery,
    setWeekdays,
    setMonthToRecur,
    setDayToRecur,
    setWeekNumber,
    setDayOfWeekToRecur,
    setMonthlyScheduleType,
    setCronExpression,
    updateEndTimeEnabled,
    setScheduleProperties
} = scheduleSlice.actions;

export default scheduleSlice.reducer;