import {combineReducers} from 'redux';
import protocolsReducer from '../components/ncm/protocols/protocolsSlice';
import credentialsReducer from '../components/ncm/credentials/credentialsSlice';
import notificationReducer from '../common/components/ui/snack-bar/notificationSlice';
import scheduleDiscoveryReducer from '../components/ncm/settings/schedule-discovery/scheduleDiscoverySlice';
import closestSwitchReducer from '../components/ncm/closest-switch/closestSwitchSlice';
import arpSearchReducer from '../components/ncm/arp-search/arpSearchSlice';
import ncmDevicesReducer from '../components/ncm/devices/devicesSlice';
import ncmDevicesSearchReducer from '../components/ncm/devices-search/deviceSearchSlice';
import ncmDevicesVendorsReducer from '../components/ncm/devices/vendorsSlice';
import ncmAdaptersReducer from '../components/ncm/adapters/adaptersSlice';
import ncmBackupReducer from '../components/ncm/backups/backupSlice';
import ncmPluginsReducer from '../components/ncm/plugins/pluginsSlice';
import ncmResultsReducer from '../components/ncm/results-panel/resultsSlice';
import ncmRevisionsReducer from '../components/ncm/revisions/search/revisionsSlice';
import ncmCompareRevisionsReducer from '../components/ncm/revisions/compare/compareRevisionsSlice';
import ncmNeighborsReducer from '../components/ncm/neighbors/neighborsSlice';
import appReducer from '../common/components/data/appSlice';
import authReducer from '../common/components/data/authSlice';
import globalConfigurationReducer from '../common/components/data/globalConfigurationSlice';
import sessionReducer from '../common/components/data/sessionSlice';
import headerReducer from '../common/components/ui/LayoutHeader/slice';
import summaryPanelReducer from '../common/components/ui/SummaryPanel/slice';
import appLauncherReducer from '../common/components/ui/AppLauncher/slice';
import dgeManagementReducer from '../components/superuser/DgeManagement/slice';
import mibReducer from '../components/mib-browser/slice';

export default combineReducers({
    protocols: protocolsReducer,
    credentials: credentialsReducer,
    notification: notificationReducer,
    scheduleDiscovery: scheduleDiscoveryReducer,
    closestSwitch: closestSwitchReducer,
    arpSearch: arpSearchReducer,
    mib: mibReducer,
    ncmDevices: ncmDevicesReducer,
    ncmAdapters: ncmAdaptersReducer,
    ncmBackup: ncmBackupReducer,
    ncmPlugins: ncmPluginsReducer,
    ncmResults: ncmResultsReducer,
    ncmRevisions: ncmRevisionsReducer,
    ncmCompareRevisions: ncmCompareRevisionsReducer,
    ncmDevicesSearch: ncmDevicesSearchReducer,
    ncmDevicesVendors: ncmDevicesVendorsReducer,
    ncmNeighbors: ncmNeighborsReducer,
    app: appReducer,
    auth: authReducer,
    globalConfiguration: globalConfigurationReducer,
    session: sessionReducer,
    header: headerReducer,
    summaryPanel: summaryPanelReducer,
    appLauncher: appLauncherReducer,
    dgeManagement: dgeManagementReducer,
});
