import styles from './styles.module.css';
import {HTMLAttributes} from 'react';

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
    label: string;
    required?: boolean;
}

interface PseudoLabelProps extends HTMLAttributes<HTMLDivElement> {
    label: string;
    required?: boolean;
}

export default function Label({children, label, required = false, ...props}: LabelProps) {
    return <label {...props} className={`${styles.main} ${required && styles.required}`}>
        {children}
        <span className={styles.label}>{label}</span>
    </label>;
}

export function PseudoLabel({children, label, required = false, ...props}: PseudoLabelProps) {
    return <div {...props} className={`${styles.main} ${required && styles.required}`}>
        {children}
        <span className={styles.label}>{label}</span>
    </div>;
}