import ApiV1, {ApiV1Response} from './ApiV1';

type ScheduledMaintenance = {
    name: string;
}

class MaintenanceApiService {
    getMaintenances() {
        return ApiV1.get<ApiV1Response<ScheduledMaintenance[]>>('/maintenances');
    }

    getDeviceMaintenances(id: number) {
        return ApiV1.get<ApiV1Response<ScheduledMaintenance[]>>(`/maintenances/device/${id}`);
    }

    setDeviceMaintenances(id: number, scheduledMaintenanceNames: string[]) {
        return ApiV1.put<ApiV1Response<ScheduledMaintenance[]>>(`/maintenances/device/${id}`, scheduledMaintenanceNames);
    }
}

export default new MaintenanceApiService();
