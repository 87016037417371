import styles from '../../../clf/LandingTemplate/styles.module.css';
import {useEffect, useState} from 'react';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {useNavigate} from 'react-router-dom';
import {MfaRemindLogo} from '../../../common/icons/TraverseIcons';
import Checkbox from '../../../clf/form/Checkbox';
import LandingTemplate from '../../../clf/LandingTemplate';
import Title from '../../../clf/Title';
import Text from '../../../clf/Text';
import {useAppDispatch, useAppSelector} from '../../../store';
import Button from '../../../clf/form/Button';
import {skipMfaReminder} from '../../../common/components/data/authSlice';

const MfaRemind = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.auth.data.auth);
    const [remember, setRemember] = useState(false);

    const enableMfa = () => navigate('/mfa/confirmation');

    const redirectToApp = () => {
        if (remember) {
            TraverseApiService.mfa.disableReminder()
                .then(() => dispatch(skipMfaReminder()))
                .catch((response) => dispatch(showError('Failed to disable 2FA reminder', response)));
        } else {
            dispatch(skipMfaReminder());
        }
    };

    useEffect(() => {
        if (!auth?.authenticated || !auth.mfaStatus?.mfaReminderEnabled || auth.mfaStatus.enabled) {
            navigate('/');
        }
    }, [auth]);

    return <LandingTemplate onEnterKeyCallback={enableMfa}>
        <div className={styles.titleBlock}>
            <MfaRemindLogo/>

            <Title
                variant={'page'}
                style={{textAlign: 'center'}}
            >
                Your Security Matters
            </Title>

            <Text style={{color: 'var(--clf-text-color-secondary)'}}>
                With two-factor authentication (2FA) you will protect your account using your password and another device.
            </Text>
        </div>

        <Checkbox
            label="Don't remind me for 30 days"
            checked={remember}
            onChange={() => setRemember(!remember)}
        />

        <div className={styles.form}>
            <Button
                size={'big'}
                onClick={enableMfa}
            >
                Get Started
            </Button>

            <Button
                variant={'tertiary'}
                size={'big'}
                onClick={redirectToApp}
            >
                Skip for Now
            </Button>
        </div>
    </LandingTemplate>;
};

export default MfaRemind;
