import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {AppDispatch} from '../../../store';
import {Organization} from '../../../services/OrganizationApiService';
import {DgeLocation} from '../../../services/LocationApiService';
import {Dge} from '../../../services/DgeApiService';

interface AppSlice {
    organizations: Organization[];
    locations: DgeLocation[];
    dges: Dge[];
}

const initialState: AsyncSlice<AppSlice> = {
    loading: false,
    data: {
        organizations: [],
        locations: [],
        dges: [],
    },
};

const dgeManagementSlice = createSlice({
    name: 'dgeManagement',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setOrganizations: (state, {payload}: PayloadAction<Organization[]>) => {
            state.data.organizations = payload.filter((i) => !i.canUseAllLocations);
        },

        setLocations: (state, {payload}: PayloadAction<DgeLocation[]>) => {
            state.data.locations = payload;
        },
        setCreatedLocation: (state, {payload}: PayloadAction<DgeLocation>) => {
            state.data.locations = [...state.data.locations, payload];
        },
        setUpdatedLocation: (state, {payload}: PayloadAction<DgeLocation>) => {
            state.data.locations = state.data.locations.map((i) => i.serialNumber === payload.serialNumber ? payload : i);
        },
        setDeletedLocation: (state, {payload}: PayloadAction<number>) => {
            state.data.locations = state.data.locations.filter((i) => i.serialNumber !== payload);
        },

        setDges: (state, {payload}: PayloadAction<Dge[]>) => {
            state.data.dges = payload;
        },
        setCreatedDge: (state, {payload}: PayloadAction<Dge>) => {
            state.data.dges = [...state.data.dges, payload];
        },
        setUpdatedDge: (state, {payload}: PayloadAction<Dge>) => {
            state.data.dges = state.data.dges.map((i) => i.serialNumber === payload.serialNumber ? payload : i);
        },
        setDeletedDge: (state, {payload}: PayloadAction<number>) => {
            state.data.dges = state.data.dges.filter((i) => i.serialNumber !== payload);
        },

        reset: (state) => {
            state.data = {
                organizations: [],
                locations: [],
                dges: [],
            };
        },
    },
});

const actions = dgeManagementSlice.actions;

export const {
    setCreatedLocation,
    setUpdatedLocation,
    setDeletedLocation,
    setCreatedDge,
    setUpdatedDge,
    setDeletedDge,
    reset
} = actions;

export const fetchAll = () => async (dispatch: AppDispatch) => {
    dispatch(actions.setLoading(true));

    const organizations = TraverseApiService.organization.getUserGroupOrganizations()
        .then((response) => dispatch(actions.setOrganizations(response.data.sort((a, b) => a.name.localeCompare(b.name)))))
        .catch((response) => dispatch(showError('Failed to fetch organizations', response)));

    const locations = TraverseApiService.location.getAll()
        .then((response) => dispatch(actions.setLocations(response.data.result.sort((a, b) => a.name.localeCompare(b.name)))))
        .catch((response) => dispatch(showError('Failed to fetch locations', response)));

    const dges = TraverseApiService.dge.getAll()
        .then((response) => dispatch(actions.setDges(response.data.result.sort((a, b) => a.name.localeCompare(b.name)))))
        .catch((response) => dispatch(showError('Failed to fetch DGEs', response)));

    Promise.all([organizations, locations, dges])
        .then(() => dispatch(actions.setLoading(false)));
};

export default dgeManagementSlice.reducer;