import styles from './styles.module.css';
import {HTMLAttributes, ReactNode} from 'react';

interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
    variant?: 'page' | 'block' | 'card';
    children?: ReactNode;
}

const Title = ({children, variant = 'page', ...props}: TitleProps) => {
    switch (variant) {
    case 'page':
        return <h1 {...props} className={styles.main}>{children}</h1>;
    case 'block':
        return <h2 {...props} className={styles.main}>{children}</h2>;
    case 'card':
        return <h3 {...props} className={styles.main}>{children}</h3>;
    }
};

export default Title;