import { useEffect, useRef } from 'react';

export default (callback: FixType, delay: number | null) => {
    const savedCallback = useRef<FixType>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        if (delay !== null) {
            const id = setInterval(() => savedCallback.current && savedCallback.current(), delay);

            return () => {
                clearInterval(id);
            };
        }
    }, [delay]);
};