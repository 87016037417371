import ApiV1, {ApiV1Response} from './ApiV1';

export interface BmsOrganization {
    id: number;
    name: string;
    locationId: number;
}

export interface BmsOrganizationMapping {
    depId: number;
    depName: string;
    accId: number;
    accName: string;
    accLocationId: number;
}

export interface BmsTicketQueue {
    id: number;
    name: string;
}

export interface BmsTicketPriority {
    id: number;
    name: string;
}

export interface BmsTicketType {
    id: number;
    name: string;
}

export interface BmsTicketStatus {
    id: number;
    name: string;
}

class BmsApiService {
    private cache: FixType = {};

    private getCached<T>(path: string) {
        return this.cache[path] || (this.cache[path] = ApiV1.get<T>(path));
    }

    testConnection(serverUrl: string, companyName: string, username: string, password: string) {
        return ApiV1.post<ApiV1Response<undefined>>('/bms/test-connection', {serverUrl, companyName, username, password});
    }

    getMappings() {
        return ApiV1.get<ApiV1Response<BmsOrganizationMapping[]>>('/bms/acc-to-dep-mappings');
    }

    setMappings(mappings: BmsOrganizationMapping[]) {
        return ApiV1.post<ApiV1Response<undefined>>('/bms/acc-to-dep-mappings', mappings);
    }

    getOrganizations() {
        return ApiV1.get<ApiV1Response<BmsOrganization[]>>('/bms/accounts');
    }

    getTicketQueues() {
        return this.getCached<ApiV1Response<BmsTicketQueue[]>>('/bms/ticket/queues');
    }

    getTicketPriorities() {
        return this.getCached<ApiV1Response<BmsTicketPriority[]>>('/bms/ticket/priorities');
    }

    getTicketTypes() {
        return this.getCached<ApiV1Response<BmsTicketType[]>>('/bms/ticket/types');
    }

    getTicketStatuses() {
        return this.getCached<ApiV1Response<BmsTicketStatus[]>>('/bms/ticket/statuses');
    }
}

export default new BmsApiService();
