import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {NcmAdapter} from '../../../services/NcmApiService';
import {AppDispatch} from '../../../store';

interface AdaptersSlice {
    adapters: NcmAdapter[];
    adapterToIds: Record<string, NcmAdapter>;
}

const initialState: AdaptersSlice = {
    adapters: [],
    adapterToIds: {},
};

const adaptersSlice = createSlice({
    name: 'adaptersSlice',
    initialState,
    reducers: {
        setAdapters: (state, action: PayloadAction<NcmAdapter[]>) => {
            state.adapters = action.payload;
        },
        setAdaptersToIds: (state, action: PayloadAction<Record<string, NcmAdapter>>) => {
            state.adapterToIds = action.payload;
        }
    }
});

const {
    setAdaptersToIds,
    setAdapters
} = adaptersSlice.actions;

const getAdapters = () => (dispatch: AppDispatch) => {
    TraverseApiService.ncm.findAvailableAdapters()
        .then((response) => {
            const adaptersById = response.data.reduce((a, i) => ({...a, [i.adapterId]: i}), {});
            response.data.sort(comparator);
            dispatch(setAdaptersToIds(adaptersById));
            dispatch(setAdapters(response.data));
        })
        .catch((response) => showError('Failed to load Adapters', response));
};

const comparator = (a: NcmAdapter, b: NcmAdapter) => {
    if (a.shortName > b.shortName) {
        return 1;
    }
    if (a.shortName < b.shortName) {
        return -1;
    }
    return 0;
};

export {
    getAdapters
};

export default adaptersSlice.reducer;