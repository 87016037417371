import { createTheme } from '@mui/material/styles';

export const selectedColor = 'rgb(55,102,103, 0.25)';

let THEME = createTheme({
    spacing: 5,
    palette: {
        primary: {
            main: '#376667'
        },
        secondary: {
            main: '#fff'
        },
        common: {
            warn: '#ffcf33',
            danger: '#d22f2d'
        }
    },
    typography: {
        fontSize: 14,
        fontFamily: 'Roboto',
        button: {
            textTransform: 'capitalize',
            fontSize: 12
        },
        body1: {
            fontSize: 12
        },
        body2: {
            fontSize: 12
        },
        h5: {
            fontSize: 20
        },
        h6: {
            fontSize: 16
        },
        subtitle1: {
            fontSize: 14,
            fontWeight: 500
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    height: '100vh'
                },
                body: {
                    height: '100vh'
                },
                '#root': {
                    height: '100vh',
                    backgroundColor: '#f7f7f7',
                    minWidth: '750px',
                    display: 'flex',
                    flexFlow: 'column',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    font: 'var(--clf-font-main)',
                    color: 'var(--clf-text-color-secondary)',
                },
                shrink: {
                    fontSize: 12
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: 10,
                    paddingLeft: 20
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 20
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: 20
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 0
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    minWidth: '80px',
                    borderRadius: 2
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'rgb(55,102,103, 0.25) !important'
                    }
                }
            }
        },

        MuiTable: {
            styleOverrides: {
                root: {
                    tableLayout: 'fixed',
                    borderCollapse: 'collapse',
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {

            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'var(--clf-background-color-hovered) !important'
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#EDF6F7 !important'
                    },
                },
                head: {
                    boxShadow: 'var(--clf-shadow-table-header)'
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    minHeight: '40px',
                    padding: '8px 16px',
                    borderBottom: '1px solid var(--clf-border-color-primary)',
                    font: 'var(--clf-font-main)',
                    color: 'var(--clf-text-color)'
                },
                head: {
                    minHeight: '48px',
                    padding: '13px 16px',
                    fontWeight: '700',
                },
                body: {
                    // overflow: 'hidden',
                    // whiteSpace: 'nowrap', //'normal',
                    // wordWrap: 'break-word',
                    // textOverflow: 'ellipsis',
                },
            }
        },

        MuiPagination: {
            styleOverrides: {
                ul: {
                    flexFlow: 'row nowrap',
                    gap: '6px',
                }
            },
        },

        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    width: '30px',
                    height: '30px',
                    margin: 0,
                    padding: 0,
                    borderRadius: '4px',
                    background: 'transparent',
                    font: 'var(--clf-font-main)',

                    '&.Mui-selected': {
                        background: 'rgba(55, 102, 103, 0.15)',
                        color: '#376667',
                        fontWeight: '700',
                    },

                    '&:hover': {
                        background: 'var(--clf-background-color-hovered)',
                    },
                },
            }
        },

        MuiTablePagination: {
            styleOverrides: {
                toolbar: {
                    justifyContent: 'space-between',
                    gap: '24px',
                    minHeight: '0',
                    padding: '12px !important',
                },
                displayedRows: {
                    order: '-1',
                    margin: '0 auto 0 0',
                    font: 'var(--clf-font-main)',
                },
                actions: {
                    order: '2',
                    font: 'var(--clf-font-main)',
                },
                selectRoot: {
                    order: '3',
                    margin: 0,
                },
                selectLabel: {
                    order: '4',
                    margin: '0 0 0 -12px',
                    font: 'var(--clf-font-main)',
                }
            }
        },

        MuiMenu: {
            styleOverrides: {
                list: {
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '2px',
                    padding: '8px 0',
                    borderRadius: '4px',

                    background: '#FFFFFF',
                    boxShadow: 'var(--clf-shadow-sm)',
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    overflow: 'hidden',
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    gap: '8px',
                    height: '32px',
                    padding: '0 8px',
                    font: 'var(--clf-font-main)',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',

                    '&.default': {
                        background: 'var(--clf-background-color-selected)',
                    },

                    '&:hover': {
                        background: 'var(--clf-background-color-hovered)',
                    },
                }
            }
        },

        MuiInput: {
            styleOverrides: {
                root: {

                }
            }
        },

        MuiSelect: {
            styleOverrides: {
                icon: {

                },
                select: {
                    padding: '5px 44px 5px 8px !important',
                    font: 'var(--clf-font-main)',
                },
                root: {
                    '& fieldset': {
                        borderColor: 'var(--clf-border-color) !important',
                    },
                    '&:hover fieldset': {
                        borderColor: 'var(--clf-border-color-hovered) !important',
                    },
                    '&.Mui-focused fieldset': {
                        border: '1px solid var(--clf-color-main) !important',
                    },
                    '&.MuiInputBase-readOnly': {
                        backgroundColor: 'var(--clf-background-color-disabled) !important',
                    },
                    '&.MuiInputBase-readOnly fieldset': {
                        borderColor: 'var(--clf-background-color-disabled) !important',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'var(--clf-background-color-disabled) !important',
                    },
                    '&.Mui-disabled fieldset': {
                        borderColor: 'var(--clf-border-color-disabled) !important',
                    },
                },
            }
        },

        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    padding: '5px 36px 5px 8px !important',
                },
                input: {
                    padding: '0 !important',
                    font: 'var(--clf-font-main)',
                    height: 'auto',
                },
                paper: {
                    marginTop: '4px',
                    borderRadius: '4px',

                    background: '#FFFFFF',
                    boxShadow: 'var(--clf-shadow-sm)',
                },
                listbox: {
                    padding: '4px 0',
                },
                option: {
                    overflow: 'hidden',
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    gap: '8px',
                    height: '32px',
                    padding: '0 8px !important',
                    font: 'var(--clf-font-main)',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',

                    '&.MuiAutocomplete-option[aria-selected="true"]': {
                        background: 'var(--clf-background-color-selected)',
                    },

                    '&.Mui-focused': {
                        background: 'var(--clf-background-color-hovered)',
                    },
                }
            }
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none'
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'var(--clf-border-color) !important',
                        },
                        '&:hover fieldset': {
                            borderColor: 'var(--clf-border-color-hovered) !important',
                        },
                        '&.Mui-focused fieldset': {
                            border: '1px solid var(--clf-color-main) !important',
                        },
                        '&.MuiInputBase-readOnly': {
                            backgroundColor: 'var(--clf-background-color-disabled) !important',
                        },
                        '&.MuiInputBase-readOnly fieldset': {
                            borderColor: 'var(--clf-background-color-disabled) !important',
                        },
                        '&.Mui-disabled': {
                            backgroundColor: 'var(--clf-background-color-disabled) !important',
                        },
                        '&.Mui-disabled fieldset': {
                            borderColor: 'var(--clf-border-color-disabled) !important',
                        },
                    },
                },
            }
        },

        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(54, 62, 78, 0.7)',
                },
                invisible: {
                    backgroundColor: 'transparent',
                }
            }
        },

        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: '420px',
                }
            }
        },

        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '24px 24px 12px 24px',
                    font: 'var(--clf-font-title-block)'
                }
            }
        },

        MuiDialogContent: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexFlow: 'column',
                    gap: '24px',
                    padding: '12px 24px !important',
                }
            }
        },

        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    font: 'var(--clf-font-submain)',
                    color: 'var(--clf-text-color)',
                }
            }
        },

        MuiDialogActions: {
            styleOverrides: {
                root: {
                    gap: '12px',
                    padding: '20px 24px 24px',

                    '&>:not(:first-of-type)': {
                        marginLeft: '0'
                    }
                }
            }
        },

        MuiTabs: {
            styleOverrides: {
                root: {
                    flexShrink: '0',
                    flexGrow: '0',
                    position: 'relative',
                    minHeight: '0',
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        left: '0',
                        bottom: '0',
                        width: '100%',
                        borderBottom: '2px solid var(--clf-border-color-primary)',
                    }
                }
            }
        },

        MuiTab: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    gap: '6px',
                    height: '32px',
                    minHeight: '0',
                    padding: '0 16px',
                    font: 'var(--clf-font-main-bolder)',
                    color: 'var(--clf-text-color-secondary)',

                    '&.Mui-selected': {
                        color: 'var(--clf-text-color)'
                    },
                },
                disabled: {

                }
            }
        },
    }
});

export default THEME;