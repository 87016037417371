import styles from './styles.module.css';
import {CSSProperties, ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import Portal from '../Portal';

interface DropdownProps {
    parent: HTMLElement;
    position?: 'auto' | 'top' | 'right' | 'bottom' | 'left';
    align?: 'auto' | 'start' | 'end';
    children?: ReactNode;
    onClose?: () => void;
}

const offset = 4;

const Dropdown = ({parent, children, onClose, position = 'bottom', align = 'start'}: DropdownProps) => {
    const ref = useRef<HTMLSpanElement>(null);
    const [refMinWidth, setRefMinWidth] = useState(0);

    const style = useMemo(() => {
        const rect = parent.getBoundingClientRect();
        const minWidth = Math.max(rect.width, refMinWidth);
        const tmp: CSSProperties = {
            position: 'fixed',
            minWidth,
            zIndex: 1 +     Array.from(document.querySelectorAll('body *'))
                .map((i) => parseFloat(window.getComputedStyle(i).zIndex))
                .filter((i) => !isNaN(i))
                .reduce((a, i) => Math.max(a, i), 0)
        };

        if (position === 'bottom') {
            tmp.top = rect.bottom + offset;
        } else if (position === 'top') {
            tmp.bottom = window.innerHeight - (window.scrollY + rect.top) + offset;
        } else if (position === 'right') {
            tmp.left = rect.right + offset;
        } else if (position === 'left') {
            tmp.right = window.innerWidth - (window.scrollX + rect.left) + offset;
        }

        if (align === 'start') {
            if (position === 'bottom' || position === 'top') {
                if (window.innerWidth >= rect.left + minWidth) {
                    tmp.left = rect.left;
                } else {
                    tmp.right = window.innerWidth - rect.right;
                }
            } else {
                tmp.top = rect.top;
            }
        } else {
            if (position === 'bottom' || position === 'top') {
                tmp.right = window.innerWidth - (window.scrollX + rect.right);
            } else {
                tmp.bottom = window.innerHeight - (window.scrollY + rect.bottom);
            }
        }
        return tmp;
    }, [align, parent, refMinWidth]);

    useEffect(() => {
        setRefMinWidth(ref.current?.getBoundingClientRect().width ?? 0);
    }, [ref.current]);

    return <Portal>
        <div
            className={styles.overlay}
            style={{zIndex: style.zIndex}}
            onClick={onClose}
        />
        <span
            ref={ref}
            className={styles.container}
            style={style}
        >
            {children}
        </span>
    </Portal>;
};

export default Dropdown;