import ApiV1 from './ApiV1';
import ApiJson, {
    ApiJsonMessagedResponse,
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse,
    ApiJsonSearchedRequest, ApiJsonSearchOption,
    ApiJsonSortedRequest,
} from './ApiJson';
import {LocationInfo} from './LocationApiService';
import {UserGroup} from './AuthApiService';

export enum OrganizationClassType {
    ADMIN = 'ADMIN',
    USER = 'USER',
}

export type OrganizationFilterName
    = ApiJsonSearchOption.DEPARTMENT_NAME
    | ApiJsonSearchOption.ENABLED
    | ApiJsonSearchOption.FREEFORM
    | ApiJsonSearchOption.USER_NAME;

export type OrganizationFilters = Partial<Record<OrganizationFilterName, string | undefined>>;

export interface Organization {
    serialNumber: number;
    name: string;
    canUseAllLocations: boolean;
    groupType: UserGroup;
}

export interface SeverityCount {
    severity: number;
    count: number;
}

export interface OrganizationStatus {
    departmentSerialNumber: number;
    departmentName: string;
    departmentStatus: number;
    eventStatus: number;
    deviceSeverityCounts: SeverityCount[];
    eventSeverityCounts: SeverityCount[];
    testSeverityCounts: SeverityCount[];
    deviceCount: number;
    testCount: number;
}

interface GetStatusesResponse extends ApiJsonPagedData {
    departments: OrganizationStatus[];
}

export type GetStatusesSeverityDisplayType = 'ALL_SEVERITIES' | 'IN_ALARM' | 'USER_SELECTED';

type GetStatusesRequestedField = 'EVENT_STATUS'
    | 'DEVICE_COUNT'
    | 'TEST_COUNT'
    | 'DEVICE_SEVERITY_COUNTS'
    | 'EVENT_SEVERITY_COUNTS'
    | 'TEST_SEVERITY_COUNTS';

export const getStatusesSeverityDisplayTypeToNameMap: Record<GetStatusesSeverityDisplayType, string> = {
    ALL_SEVERITIES: 'All Severities',
    IN_ALARM: 'In Alarm',
    USER_SELECTED: 'User Selected',
};

type GetStatusesRequest = ApiJsonPagedRequest & ApiJsonSortedRequest & {
    fieldsRequested?: GetStatusesRequestedField[];
    departmentNameExp?: string;
    severityDisplayType?: GetStatusesSeverityDisplayType;
}

export type OrganizationDescriptor = {
    address1: string;
    address2: string;
    city: string;
    className: string;
    classSerialNumber: number;
    classType: OrganizationClassType;
    companyName: string;
    contactEmail: string;
    contactPhone: string;
    country: string;
    creationTime: number;
    customDomain: string;
    customerId: string;
    deptLogoFile: string;
    deptProductLogoFile: string;
    enabled: boolean;
    isSuperAccount: boolean;
    locationInfoList: LocationInfo[];
    mfaEnabled: boolean;
    name: string;
    numDevices: number;
    numSessions: number;
    numSubnets: number;
    numTests: number;
    numUsers: number;
    serialNumber: number;
    state: string;
    useAllLocations: boolean;
    whySuspended: string;
    zip: string;
}

export type OrganizationInfo = {
    address1: string;
    address2: string;
    city: string;
    className: string;
    companyName: string;
    contactEmail: string;
    contactPhone: string;
    country: string;
    locationInfoList: LocationInfo[];
    name: string;
    state: string;
    useAllLocations: boolean;
    zip: string;

    colorTheme?: string;
    customDomain?: string;
    deptLogoFile?: string;
    deptProductLogoFile?: string;
}

type GetOrganizationDescriptorsRequest = ApiJsonPagedRequest & ApiJsonSortedRequest & ApiJsonSearchedRequest;

type GetOrganizationDescriptorsResponse = ApiJsonPagedData & {
    accountDescriptors: OrganizationDescriptor[];
}

type OrganizationDescriptorResponse = ApiJsonPagedData & {
    accountDescriptor: OrganizationDescriptor;
}

type UpdateMfaResponse = {
    departmentSerialNumber: number;
    newMfaStatus: boolean;
}

class OrganizationApiService {
    getOrganizations(departmentName = '') {
        return ApiV1.get<Organization[]>('/departments', {params: {departmentName}});
    }

    getUserGroupOrganizations() {
        return ApiV1.get<Organization[]>('/departments/user-group');
    }

    getStatuses(data: GetStatusesRequest) {
        return ApiJson.post<ApiJsonResponse<GetStatusesResponse>>('/department/getStatuses', data);
    }

    getDescriptors(data: GetOrganizationDescriptorsRequest) {
        return ApiJson.post<ApiJsonResponse<GetOrganizationDescriptorsResponse>>('/admin/account/list', data);
    }

    create(accountInfo: OrganizationInfo) {
        return ApiJson.post<ApiJsonResponse<OrganizationDescriptorResponse>>('/admin/account/create', {accountInfo});
    }

    update(serialNumber: number, accountInfo: OrganizationInfo) {
        return ApiJson.post<ApiJsonResponse<OrganizationDescriptorResponse>>('/admin/account/update', {serialNumber, accountInfo});
    }

    delete(data: ApiJsonSearchedRequest) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/account/delete', data);
    }

    suspend(serialNumber: number, reason: string) {
        return ApiJson.post<ApiJsonResponse<OrganizationDescriptorResponse>>('/admin/account/suspend', {serialNumber, reason});
    }

    resume(serialNumber: number) {
        return ApiJson.post<ApiJsonResponse<OrganizationDescriptorResponse>>('/admin/account/resume', {serialNumber});
    }

    enableMfa(departmentSerialNumber: number) {
        return ApiJson.post<ApiJsonResponse<UpdateMfaResponse>>('/admin/account/enable-mfa', {departmentSerialNumber});
    }

    disableMfa(departmentSerialNumber: number) {
        return ApiJson.post<ApiJsonResponse<UpdateMfaResponse>>('/admin/account/disable-mfa', {departmentSerialNumber});
    }
}

export default new OrganizationApiService();
