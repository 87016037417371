import {createSlice} from '@reduxjs/toolkit';
import {addResult} from '../../results-panel/resultsSlice';
import TraverseApiService from '../../../../services/TraverseApiService';
import {showError} from '../../../../common/components/ui/snack-bar/notificationSlice';
import {AppDispatch, RootState} from '../../../../store';
import {NcmDeviceConfigurationRevisionDetails, NcmStoredConfiguration} from '../../../../services/NcmApiService';

interface RevisionsSlice {
    query: string;
    results: NcmStoredConfiguration[];
}

const initialState: RevisionsSlice = {
    query: '',
    results: []
};

const revisionsSlice = createSlice({
    name: 'revisionsSlice',
    initialState,
    reducers: {
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        setResults: (state, action) => {
            state.results = action.payload;
        }
    }
});

const {
    setResults,
    setQuery
} = revisionsSlice.actions;

const showRevision = (revisionInfo: NcmStoredConfiguration) => (dispatch: AppDispatch) => {
    const requestParams = {
        ipAddress: revisionInfo.ipAddress,
        managedNetwork: revisionInfo.managedNetwork,
        configPath: revisionInfo.path,
        revisionDate: revisionInfo.lastChanged
    };
    TraverseApiService.ncm.getRevision(requestParams)
        .then((response) => showRevisionContent(revisionInfo, response.data, dispatch))
        .catch((response) => showError('Failed to load current revision', response));
};

const showRevisionContent = (revisionInfo: NcmStoredConfiguration, revision: NcmDeviceConfigurationRevisionDetails, dispatch: AppDispatch) => {
    const result = {
        id: revisionInfo.path + revisionInfo.lastChanged,
        name: revisionInfo.path,
        type: 'REVISION_DETAILS',
        payload: {revision, revisionInfo}
    };
    dispatch(addResult(result));
};

const findRevisions = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const query = getState().ncmRevisions.query;
    TraverseApiService.ncm.findConfigurations(query)
        .then((response) => dispatch(setResults(response.data)))
        .catch((response) => dispatch(showError('Failed to get stored configurations', response)));
};

export {
    setQuery,
    findRevisions,
    showRevision
};

export default revisionsSlice.reducer;