import ApiV1, {ApiV1Response} from './ApiV1';
import {AuthUser, UserGroup} from './AuthApiService';
import {UserInfo, UserRole} from './UserApiService';

export enum PermissionOperation {
    READ    = 'read',
    CREATE  = 'create',
    UPDATE  = 'update',
    DELETE  = 'delete',
    SUSPEND = 'suspend',
    RESUME  = 'resume',
}

export enum PermissionCategory {
    ACTION        = 'action',
    CLOUD         = 'cloud',
    CONFIG_MGMT   = 'configmgmt',
    CONTAINER     = 'container',
    DEPARTMENT    = 'account',
    DEVICE        = 'device',
    LIMIT         = 'limit',
    MAP           = 'map',
    PERSPECTIVE   = 'perspective',
    REPORT        = 'report',
    SERVICE_CLASS = 'serviceclass',
    TEST          = 'test',
    USER          = 'user',
    SUPER         = 'super'
}

export interface OrganizationPermission {
    organizationSerialNumber: number;
    permissions: CategoryOperationPermission[];
}

export type CategoryOperationPermission = Record<PermissionOperation, boolean> & {category: PermissionCategory};

class PermissionService {
    permissions: OrganizationPermission[] = [];
    superuser = false;
    admin = false;
    readonly = false;
    defaultOrganization = 0;

    async fetchPermissions(userInfo: UserInfo, authUser: AuthUser) {
        try {
            this.defaultOrganization = userInfo.departmentSerialNumber;
            this.readonly = userInfo.role === UserRole.READ_ONLY;
            this.superuser = authUser.userGroup === UserGroup.SUPER;
            this.admin = authUser.userGroup === UserGroup.ADMIN;
            this.permissions = (await ApiV1.get<ApiV1Response<OrganizationPermission[]>>('/user/permissions')).data.result;
        } catch (e) {
            console.log('failed to fetch permissions', e);
        }
    }

    check(category: PermissionCategory, operation: PermissionOperation, organization?: number): boolean {
        if (this.superuser) {
            return true;
        }

        if (this.readonly && operation !== PermissionOperation.READ) {
            return false;
        }

        if (this.admin && !organization) {
            return !!this.permissions
                .flatMap((i) => i.permissions)
                .find((i) => i.category === category)?.[operation];
        }

        return !!this.permissions
            .find((i) => i.organizationSerialNumber === (organization ?? this.defaultOrganization))?.permissions
            .find((i) => i.category === category)?.[operation];
    }
}

export default new PermissionService();