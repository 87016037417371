import styles from '../../clf/LandingTemplate/styles.module.css';

import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {clearError, login} from '../../common/components/data/authSlice';
import {AppDispatch, useAppDispatch, useAppSelector} from '../../store';
import TraverseApiService from '../../services/TraverseApiService';
import {showError} from '../../common/components/ui/snack-bar/notificationSlice';
import LandingTemplate from '../../clf/LandingTemplate';
import Label from '../../clf/form/Label';
import TextInput from '../../clf/form/TextInput';
import Button from '../../clf/form/Button';
import {fetchProductLogo} from '../../common/components/data/sessionSlice';
import Banner from '../../clf/Banner';

export const ProductLogo = () => {
    const dispatch = useAppDispatch();
    const {productLogo} = useAppSelector(({session}) => session.data);

    useEffect(() => {
        dispatch(fetchProductLogo());
    }, []);

    return <img
        src={productLogo}
        alt="Product logo"
        className={styles.productLogo}
    />;
};

const ItCompleteBlock = ({enabled, appUrl}: {enabled: boolean, appUrl: string}) => {
    const dispatch = useAppDispatch();

    const loginWithItComplete = () => async (dispatch: AppDispatch) => {
        try {
            const error = (await TraverseApiService.globalConfiguration.updateItCompleteOidcClient()).data.error?.message;

            if (error) {
                dispatch(showError(error));
            } else {
                window.location.replace(appUrl);
            }
        } catch (e) {
            dispatch(showError('Failed to login with IT Complete (SSO)', e));
        }
    };

    return !enabled ? null : (
        <>
            <div className={styles.or}>Or</div>

            <Button
                size={'big'}
                variant={'secondary'}
                className={styles.k1Button}
                onClick={() => dispatch(loginWithItComplete())}
            >
                Log In with KaseyaOne
            </Button>

            <a
                target="_blank"
                rel="noreferrer"
                href="https://helpdesk.kaseya.com/hc/en-gb/articles/360020946058-KaseyaOne-Overview"
                className={styles.link}
            >
                Learn More about KaseyaOne
            </a>
        </>
    );
};

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const app = useAppSelector(({app}) => app);
    const authError = useAppSelector(({auth}) => auth.error);
    const auth = useAppSelector(({auth}) => auth.data.auth);
    const [username, setUsername] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);

    const loginRequest = () => {
        if (username && password) {
            dispatch(login({username, password}));
        } else {
            setUsername((i) => i ?? '');
            setPassword((i) => i ?? '');
        }
    };

    useEffect(() => {
        if (auth?.representedUser) {
            navigate('/');
        }
    }, [auth]);

    return <LandingTemplate
        onEnterKeyCallback={loginRequest}
    >
        <ProductLogo/>

        <div className={styles.form}>
            {authError && <Banner
                severity={'critical'}
                variant={'block'}
                text={authError.message}
                onClose={() => dispatch(clearError())}
            />}

            <Label label={'Username'}>
                <TextInput
                    value={username ?? ''}
                    invalid={username === ''}
                    onChange={(event) => setUsername(event.target.value)}
                />
            </Label>

            <Label label={'Password'}>
                <TextInput
                    type="password"
                    invalid={password === ''}
                    value={password ?? ''}
                    onChange={(event) => setPassword(event.target.value)}
                />
            </Label>
        </div>

        <div className={styles.form}>
            <Button
                size={'big'}
                onClick={loginRequest}
            >Log In</Button>

            <ItCompleteBlock enabled={!!app.data.itc?.itCompleteSsoEnabled} appUrl={app.data.itc?.appUrl ?? ''}/>
        </div>
    </LandingTemplate>;
};

export default LoginPage;
