import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {LicenseScopePermission} from '../../../utils/LicenseKey';
import {showError} from '../ui/snack-bar/notificationSlice';
import {AppDispatch} from '../../../store';
import {AuthUser} from '../../../services/AuthApiService';
import {UserInfo} from '../../../services/UserApiService';

export interface AuthUserItComplete {
    itCompleteEnabled: boolean;
    itCompleteLogoutUrl: string;
}

interface SessionSlice {
    companyLogo?: string;
    productLogo?: string;
    landingPage?: string;
    user?: UserInfo;
    itc?: AuthUserItComplete;
    scope?: LicenseScopePermission;
}

const initialState: AsyncSlice<SessionSlice> = {
    data: {},
};

export const initSession = createAsyncThunk('session/init', async (user: AuthUser | null): Promise<SessionSlice> => {
    const session: SessionSlice = {};

    const [userResponse, itcResponse, scopesResponse] = await Promise.all([
        TraverseApiService.user.getCurrentUser(),
        TraverseApiService.itc.getItcSettings(),
        TraverseApiService.license.getLicenseScope()
    ]);

    const [userInfo] = userResponse.data.result;
    userInfo.departmentSerialNumber = +userInfo.departmentSerialNumber; // todo cast it to number here, but need to fix it on a server side

    session.user = userInfo;
    session.itc = itcResponse.data.result[0];
    session.scope = Object.values(scopesResponse.data.result[0]).reduce((a, i) => ({...a, ...i}), {});

    user && await TraverseApiService.permissions.fetchPermissions(session.user, user);

    return session;
});

export const fetchItcMapping = createAsyncThunk('itcMapping/get', async (): Promise<AuthUserItComplete> => {
    return (await TraverseApiService.itc.getItcSettings()).data.result[0];
});

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setCompanyLogo: (state, action) => {
            state.data.companyLogo = action.payload;
        },
        setProductLogo: (state, action) => {
            state.data.productLogo = action.payload;
        },
        setLandingPage: (state, {payload}: PayloadAction<string | undefined>) => {
            if (state.data.landingPage !== payload) {
                state.data.landingPage = payload;
            }
        },
        setItCompleteEnabled: (state, {payload}: PayloadAction<boolean>) => {
            if (state.data.itc) {
                state.data.itc.itCompleteEnabled = payload;
            }
        },
        clearSession: (state) => {
            state.data.user = undefined;
            state.data.itc = undefined;
            state.data.scope = undefined;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(initSession.pending, (state) => {
                state.error = undefined;
                state.data.user = undefined;
                state.data.itc = undefined;
                state.data.scope = undefined;
            })
            .addCase(initSession.fulfilled, (state, action) => {
                state.data = {...state.data, ...action.payload};
            })
            .addCase(initSession.rejected, (state, action) => {
                state.error = action.error;
            })

            .addCase(fetchItcMapping.pending, (state) => {
                state.data.itc = undefined;
            })
            .addCase(fetchItcMapping.fulfilled, (state, action) => {
                state.data.itc = action.payload;
            })
            .addCase(fetchItcMapping.rejected, (state, action) => {
                state.error = action.error;
            });
    }
});

export const {setCompanyLogo, setProductLogo, setLandingPage, setItCompleteEnabled, clearSession} = sessionSlice.actions;

export const fetchCompanyLogo = () => (dispatch: AppDispatch) => {
    TraverseApiService.getLogo('department')
        .then((response) => dispatch(setCompanyLogo(response.data.result[0].relativePath)))
        .catch((response) => dispatch(showError('Failed to load Product Logo', response)));
};

export const fetchProductLogo = () => (dispatch: AppDispatch) => {
    TraverseApiService.getLogo('product')
        .then((response) => dispatch(setProductLogo(response.data.result[0].relativePath)))
        .catch((response) => dispatch(showError('Failed to load Company Logo', response)));
};

export default sessionSlice.reducer;