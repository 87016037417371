import {createSlice} from '@reduxjs/toolkit';

import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {AppDispatch, RootState} from '../../../store';

interface NcmResultsSlice {
    results: FixType[];
    selectedResultIndex: number;
}

const initialState: NcmResultsSlice = {
    results: [],
    selectedResultIndex: 0
};

const resultsSlice = createSlice({
    name: 'ncmResults',
    initialState,
    reducers: {
        appendResult: (state, {payload}) => {
            state.results = [...state.results, payload];
            state.selectedResultIndex = state.results.length === 0 ? 0 : state.results.length - 1;
        },
        removeResultByIndex: (state, action) => {
            const index = action.payload;

            state.results.splice(index, 1);

            if (index <= state.selectedResultIndex && state.selectedResultIndex !== 0) {
                state.selectedResultIndex -= 1;
            }
        },
        setSelectedResultIndex: (state, action) => {
            state.selectedResultIndex = action.payload;
        },
        reorder: (state, action) => {
            state.results = action.payload;
        }
    }
});

const {
    reorder,
    appendResult,
    removeResultByIndex,
    setSelectedResultIndex
} = resultsSlice.actions;

const addResult = (result: FixType) => (dispatch: AppDispatch, getState: () => RootState) => {
    const index = getState().ncmResults.results.findIndex(({id}: FixType) => result.id === id);
    if (index === -1) {
        dispatch(appendResult(result));
    } else {
        dispatch(showError('Item is already added to the results'));
    }
};

export {
    reorder,
    addResult,
    removeResultByIndex,
    setSelectedResultIndex
};

export default resultsSlice.reducer;