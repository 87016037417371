import styles from '../../../clf/LandingTemplate/styles.module.css';
import {useCallback, useEffect, useMemo, useState} from 'react';
import TraverseApiService from '../../../services/TraverseApiService';
import {useNavigate} from 'react-router-dom';
import {QrCodeErrorImage} from '../../../common/icons/TraverseIcons';
import {patchAuthStatus} from '../../../common/components/data/authSlice';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import LandingTemplate from '../../../clf/LandingTemplate';
import Title from '../../../clf/Title';
import Button from '../../../clf/form/Button';
import TextInput from '../../../clf/form/TextInput';
import {useAppDispatch, useAppSelector} from '../../../store';
import {MfaDetails} from '../../../services/MfaApiService';
import BackToLogin from '../../../common/components/ui/buttons/BackToLogin';

const MfaConfirmation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.auth.data.auth);
    const [mfaDetails, setMfaDetails] = useState<MfaDetails>();
    const [showQr, setShowQr] = useState(true);
    const [qrError, setQrError] = useState(false);
    const [token, setToken] = useState<string>('');
    const [tokenError, setTokenError] = useState<string>('');

    const getMfaQrCode = useCallback(() => {
        setQrError(false);

        TraverseApiService.mfa.getRegistrationData()
            .then((response) => setMfaDetails(response.data.result[0]))
            .catch(() => setQrError(true));
    }, []);

    const qrBlock = useMemo(() => {
        if (qrError) {
            return <>
                <span>
                    Use the app to scan QR code.
                    &nbsp;
                    <a className={styles.link} onClick={getMfaQrCode}>Try Again</a>
                </span>

                <div className={styles.qrError}>
                    <QrCodeErrorImage/>

                    Failed to generate QR code.
                </div>
            </>;
        } else if (showQr) {
            return <>
                Use the app to scan QR code.
                &nbsp;
                <a className={styles.link} onClick={() => setShowQr(false)}>Can&apos;t scan?</a>

                <img
                    src={mfaDetails?.qrCode}
                    className={styles.qrCode}
                    alt="qrCode"
                />
            </>;
        } else {
            return <>
                Enter the code into your 2FA app.
                &nbsp;
                <a className={styles.link} onClick={() => setShowQr(true)}>View QR Code</a>

                <TextInput
                    readOnly={true}
                    value={mfaDetails?.rawKey}
                />
            </>;
        }
    }, [showQr, qrError, mfaDetails]);

    const confirmMfa = () => {
        if (!token) {
            return setTokenError('Can\'t be empty');
        }

        TraverseApiService.mfa.confirm(token)
            .then(() => {
                if (auth?.mfaStatus) {
                    dispatch(patchAuthStatus({authenticated: true, mfaStatus: {...auth.mfaStatus, confirmed: true}}));
                }
            })
            .catch((response) => dispatch(showError('Failed to confirm authentication code', response)));
    };

    useEffect(() => {
        if (!auth?.representedUser || !auth.mfaStatus?.enabled || auth.authenticated) {
            return navigate('/');
        } else if (auth.mfaStatus?.confirmed) {
            return navigate('/mfa/confirmed');
        } else {
            getMfaQrCode();
        }
    }, [auth]);

    useEffect(() => setTokenError(''), [token]);

    return <LandingTemplate onEnterKeyCallback={confirmMfa}>
        <Title
            variant={'page'}
            style={{textAlign: 'center'}}
        >
            Set Up 2FA
        </Title>

        <ol className={styles.list}>
            <li>Download and install a supported <b>TOTP Authentication App</b>.</li>

            <li>{qrBlock}</li>

            <li>
                Enter the code generated by the app.

                <TextInput
                    style={{textAlign: 'center', fontWeight: 'bold'}}
                    value={token}
                    invalid={!!tokenError}
                    onChange={(event) => setToken(event.target.value)}
                    hint={tokenError}
                />
            </li>
        </ol>

        <div className={styles.form}>
            <Button
                size={'big'}
                onClick={confirmMfa}
            >
                Next
            </Button>

            <BackToLogin/>
        </div>
    </LandingTemplate>;
};

export default MfaConfirmation;
