import styles from './styles.module.css';
import {CSSProperties, HTMLAttributes, useCallback, useMemo, useRef, useState} from 'react';

interface TooltipProps extends HTMLAttributes<HTMLSpanElement> {
    enabled?: boolean;
    position?: 'auto' | 'top' | 'right' | 'bottom' | 'left';
    align?: 'auto' | 'start' | 'end';
    text: string;
    ignore?: boolean;
}

const offset = 4;

export default function Tooltip({
    enabled = true,
    position = 'bottom',
    align = 'start',
    text,
    children,
    ignore = true,
    ...props
}: TooltipProps) {
    const ref = useRef<HTMLSpanElement>(null);
    const [active, setActive] = useState(false);

    const hintStyles: CSSProperties = useMemo(() => {
        if (!ref.current) {
            return {};
        }

        const rect = ref.current.getBoundingClientRect();
        const tmp: CSSProperties = {
            position: 'fixed',
            minWidth: rect.width,
            zIndex: 1 + Array.from(document.querySelectorAll('body *'))
                .map((i) => parseFloat(window.getComputedStyle(i).zIndex))
                .filter((i) => !isNaN(i))
                .reduce((a, i) => Math.max(a, i), 0),
        };

        if (position === 'bottom') {
            tmp.top = rect.bottom + offset;
        } else if (position === 'top') {
            tmp.bottom = window.innerHeight - (window.scrollY + rect.top) + offset;
        } else if (position === 'right') {
            tmp.left = rect.right + offset;
            tmp.minWidth = 0;
        } else if (position === 'left') {
            tmp.right = window.innerWidth - (window.scrollX + rect.left) + offset;
            tmp.minWidth = 0;
        }

        if (align === 'start') {
            if (position === 'bottom' || position === 'top') {
                tmp.left = rect.left;
            } else {
                tmp.top = rect.top;
            }
        } else if (position === 'bottom' || position === 'top') {
            tmp.right = window.innerWidth - (window.scrollX + rect.right);
        } else {
            tmp.bottom = window.innerHeight - (window.scrollY + rect.bottom);
        }

        return tmp;
    }, [ref.current, position, active]);

    const onHover = useCallback((hovered: boolean) => setActive(enabled && hovered), [setActive, enabled]);

    return <span
        {...props}
        ref={ref}
        className={styles.wrap}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
    >
        {children}
        {active && <span
            className={`${styles.main} ${ignore && styles.ignore}`}
            style={hintStyles}
        >{text}</span>}
    </span>;
}