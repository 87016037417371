import {createSlice} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError, showSuccess} from '../../../common/components/ui/snack-bar/notificationSlice';
import {NcmNetworkCredential, NcmCredentialsNetworkGroup, NcmNewEntity} from '../../../services/NcmApiService';
import {AppDispatch, RootState} from '../../../store';
import {PayloadAction} from '@reduxjs/toolkit/dist/createAction';

const priorityAndNameSorter = <T extends  {priority: number, name: string}>(a: T, b: T) => {
    if (a.priority === b.priority) {
        return (a.name.localeCompare(b.name));
    }

    return a.priority - b.priority;
};

interface CredentialsSlice {
    selectedOrganizationId: number;
    networkGroups: NcmCredentialsNetworkGroup[];
    selectedNetworkGroupId: number | null;
    selectedCredentialId: number | null;
    guessNetworkGroup: string | null;
}

const initialState: CredentialsSlice = {
    selectedOrganizationId: 0,
    networkGroups: [],
    selectedNetworkGroupId: null,
    selectedCredentialId: null,
    guessNetworkGroup: null,
};

const credentials = createSlice({
    name: 'credentials',
    initialState,
    reducers: {
        setNetworkGroups(state, {payload}: PayloadAction<NcmCredentialsNetworkGroup[]>) {
            const networkGroups: NcmCredentialsNetworkGroup[] = payload.sort(priorityAndNameSorter);
            const guessedNetworkGroup = networkGroups.find((i) => i.name === state.guessNetworkGroup);
            const selectedNetworkGroup = networkGroups.find((i) => i.id === state.selectedNetworkGroupId);

            if (guessedNetworkGroup) {
                state.guessNetworkGroup = null;
                state.selectedNetworkGroupId = guessedNetworkGroup.id;
            } else if (selectedNetworkGroup) {
                state.selectedNetworkGroupId = selectedNetworkGroup.id;
            } else if (networkGroups.length) {
                state.selectedNetworkGroupId = networkGroups[0].id;
            } else {
                state.selectedNetworkGroupId = null;
            }

            networkGroups.forEach((i) => i.payloads = i.payloads.sort(priorityAndNameSorter));

            state.networkGroups = networkGroups;
        },
        setSelectedOrganizationId(state, action: PayloadAction<number>) {
            state.selectedOrganizationId = action.payload;
            if (!action.payload) {
                state.networkGroups = [];
                state.selectedNetworkGroupId = null;
            }
        },
        setSelectedNetworkGroupId(state, action) {
            state.selectedNetworkGroupId = action.payload.id;
            state.selectedCredentialId = null;
        },
        selectCredential(state, action: PayloadAction<NcmNetworkCredential>) {
            if (state.selectedCredentialId === action.payload.id) {
                state.selectedCredentialId = null;
            } else {
                state.selectedCredentialId = action.payload.id;
            }
        },
        setGuessNetworkGroup(state, {payload}: PayloadAction<string | null>) {
            state.guessNetworkGroup = payload;
        },
        cleanUp: () => {
            return initialState;
        },
    },
});


export const fetchNetworkGroups = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const organizationId = getState().credentials.selectedOrganizationId;
        const networkGroupsResp = await TraverseApiService.ncm.getCredentialsNetworkGroups(organizationId);
        dispatch(setNetworkGroups(networkGroupsResp.data));
    } catch (err) {
        dispatch(showError('Failed to fetch Network groups', err));
        dispatch(setNetworkGroups([]));
    }
};

export const saveNetworkGroup = (networkGroup: NcmNewEntity<NcmCredentialsNetworkGroup>) => async (dispatch: AppDispatch) => {
    try {
        await TraverseApiService.ncm.saveCredentialsNetworkGroup(networkGroup);
        dispatch(setGuessNetworkGroup(networkGroup.name));
        dispatch(setSelectedNetworkGroupId(networkGroup));
        dispatch(showSuccess('Network group saved'));
    } catch (err) {
        dispatch(showError('Failed to save Network group', err));
    }
};

export const updateNetworkGroup = (networkGroup: NcmCredentialsNetworkGroup) => async (dispatch: AppDispatch) => {
    try {
        await TraverseApiService.ncm.saveCredentialsNetworkGroup(networkGroup);
        await dispatch(fetchNetworkGroups());
        dispatch(showSuccess('Network group updated'));
    } catch (err) {
        dispatch(showError('Failed to update Network group', err));
    }
};

export const deleteNetworkGroup = (organizationId: number, networkGroupId: number) => async (dispatch: AppDispatch) => {
    try {
        await TraverseApiService.ncm.deleteCredentialsNetworkGroup(networkGroupId, organizationId);
        dispatch(showSuccess('Network group deleted'));
    } catch (err) {
        dispatch(showError('Failed to delete Network group', err));
    }
};


export const {
    setGuessNetworkGroup,
    setNetworkGroups,
    setSelectedNetworkGroupId,
    setSelectedOrganizationId,
    selectCredential,
    cleanUp,
} = credentials.actions;

export default credentials.reducer;