import {createSlice} from '@reduxjs/toolkit';
import {AppDispatch} from '../../../../store';
import {AlertColor} from '@mui/material/Alert/Alert';

interface NotificationSlice {
    show: boolean;
    severity: AlertColor;
    message: string;
    details?: FixType;
    validationError?: FixType;
}

const initialState: NotificationSlice = {
    show: false,
    severity: 'success',
    message: ''
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        showErrorMessage(state, action) {
            state.show = true;
            state.severity = 'error';
            state.message = action.payload.message;
            state.details = action.payload.details;
        },
        showSuccess(state, action) {
            state.show = true;
            state.severity = 'success';
            state.message = action.payload;
        },
        hideNotification(state) {
            state.show = false;
            state.message = '';
            state.details = null;
            state.validationError = null;
        }
    }
});

const {
    showErrorMessage,
    showSuccess,
    hideNotification
} = notificationSlice.actions;

const showError = (message: string, response: FixType = undefined) => (dispatch: AppDispatch) => {
    let details;

    if (response?.isAxiosError) {
        response = response.response;
    }

    if (typeof response === 'string') {
        details = response;
    }

    switch (response?.status) {
    case 403: // permissions error
        details = buildPermissionsError(response.data.properties);
        break;
    case 400: // validation error
        details = buildValidationError(response.data.properties);
        break;
    case 401: // session validation error
        window.location.replace('/');
        break;
    }

    dispatch(showErrorMessage({message, details}));
};

const hasMessages = (properties: FixType) => Array.isArray(properties);

const buildValidationError = (properties: FixType) => {
    if (hasMessages(properties)) {
        return properties.map((property: FixType) => `${property.path}: ${property.message}`).join('\n');
    }
};

const buildPermissionsError = (properties: FixType) => {
    if (hasMessages(properties)) {
        return properties[0].message;
    }
};

export {
    showError,
    showSuccess,
    hideNotification
};

export default notificationSlice.reducer;