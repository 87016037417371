import {createPortal} from 'react-dom';
import {useEffect, useMemo} from 'react';

const PortalPopup = ({children, onClose, title = ''}: FixType) => {
    const container = useMemo(() => document.createElement('div'), []);

    useEffect(() => {
        // When container is ready
        if (container) {
            // Create window
            const popup = window.open(
                '',
                title,
                'status=no,location=no,resizable=yes,menubar=no,scrollbars=no,width=500,height=100,left=200,top=200'
            );

            if (popup) {
                // append base so that all relative hrefs work correctly
                const base = document.createElement('base');
                base.href = window.location.origin;
                popup.document.head.appendChild(base);

                // copy styles
                [...window.document.styleSheets]
                    .forEach((i) => i.ownerNode ? popup.document.head.appendChild(i.ownerNode.cloneNode(true)) : undefined);

                popup.document.title = title;

                // Append container
                popup.document.body.appendChild(container);

                popup.addEventListener('beforeunload', onClose);

                const unloadHandler = () => {
                    popup?.close();
                };

                window.addEventListener('beforeunload', unloadHandler);

                return () => {
                    window.removeEventListener('beforeunload', unloadHandler);
                    popup?.close();
                };
            }
        }
    }, [container]);

    return createPortal(children, container);
};

export default PortalPopup;