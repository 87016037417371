import ApiV1, {ApiV1Response} from './ApiV1';
import {UserShowSeverities} from '../utils/Severity';
import ApiJson, {
    ApiJsonMessagedResponse,
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse,
    ApiJsonSearchedRequest,
    ApiJsonSearchOption,
    ApiJsonSortedRequest,
} from './ApiJson';

export enum UserRole {
    READ_ONLY = 'Read-Only',
    READ_WRITE = 'Read-Write',
}

export enum UserViewType {
    NORMAL = 'NORMAL',
    COMPACT = 'COMPACT',
}

export enum UserAudibleAlert {
    NONE,
    PAGE,
    BEEP,
    SELECT,
    SMASH0,
    SMASH1,
    SMASH2,
}

export type UserFilterName
    = ApiJsonSearchOption.DEPARTMENT_NAME
    | ApiJsonSearchOption.DEPARTMENT_SERIAL_NUMBER
    | ApiJsonSearchOption.ENABLED
    | ApiJsonSearchOption.FREEFORM
    | ApiJsonSearchOption.PERMISSION_CLASS_SERIAL_NUMBER
    | ApiJsonSearchOption.USER_EMAIL_ADDRESS
    | ApiJsonSearchOption.USER_LOGIN_ID
    | ApiJsonSearchOption.USER_NAME
    | ApiJsonSearchOption.USER_SERIAL_NUMBER;

export type UserFilters = Partial<Record<UserFilterName, string | undefined>>;

export type UserInfo = {
    audibleAlertOption: UserAudibleAlert;
    className: string;
    classType: string;
    comment: string;
    departmentName: string;
    departmentSerialNumber: number; // todo it is returned as a string!
    emailAddress: string;
    filterEnabled: boolean;
    firstName: string;
    highlightRecentEvents: boolean;
    lastName: string;
    locale: string;
    loginName: string;
    loginUrl: string;
    loginUrlOther: string;
    maxAlarmsToDisplay: number;
    maxToDisplay: number;
    phoneDay: string;
    phoneEvening: string;
    phoneMobile: string;
    refreshInterval: number;
    role: UserRole;
    serialNumber: number;
    showMessageEvents: boolean;
    showSeverities: UserShowSeverities;
    showSLAAlerts: boolean;
    showTestResults: boolean;
    suspended: boolean;
    timeZone: string;
    viewType: UserViewType;
    isItCompleteJitUser: boolean;
};

export type UserInfoOld = {
    adminUser: boolean;
    audibleAlertOption: UserAudibleAlert;
    availableFeatures: string[];
    comment: string;
    creationTime: number;
    departmentName: string;
    departmentSerialNumber: number;
    emailAddress: string;
    enabled: boolean;
    filterEnabled: boolean;
    firstName: string;
    forceUserToUpdatePasswordEnabled: boolean;
    highlightRecentEvents: boolean;
    internalAuth: boolean;
    lastLoginTime: number;
    lastName: string;
    localeCountry: string;
    localeDisplayCountry: string;
    localeDisplayLanguage: string;
    localeLanguage: string;
    localeLanguageTag: string;
    loginName: string;
    loginUrl: string;
    loginUrlOther: string;
    maxMessagesToDisplay: number;
    maxToDisplay: number;
    mfaEnabled: boolean;
    mfaResetEnabled: boolean;
    numSessions: number;
    pager: string;
    permissionClassName: string;
    permissionClassSerialNumber: number;
    phoneDay: string;
    phoneEvening: string;
    phoneMobile: string;
    refreshInterval: number;
    represented: boolean;
    representedUserLoginName: string;
    role: UserRole;
    serialNumber: number;
    showMessageEvents: boolean;
    shownSeverities: number[];
    showSLAAlerts: boolean;
    showTestResults: boolean;
    superUser: boolean;
    timeZone: string;
    timeZoneOffset: number;
    userName: string;
    viewType: UserViewType;
    isItCompleteJitUser: boolean;
};

export type UserCreateRequest = {
    accountSerialNumber: number;
    comment: string;
    emailAddress: string;
    firstName: string;
    internalAuth: boolean;
    lastName: string;
    locale: string;
    loginName: string;
    mobilePhone: string;
    pager: string;
    phoneDay: string;
    phoneEvening: string;
    role: UserRole;
    timeZone: string;
    userPassword: string;
    userPasswordConfirm: string;
};

export type UserUpdateRequest = {
    audibleAlertOption: UserAudibleAlert;
    comment: string;
    emailAddress: string;
    filterEnabled: boolean;
    firstName: string;
    forceUserToUpdatePasswordEnabled: boolean;
    highlightRecentEvents: boolean;
    internalAuth: boolean;
    lastName: string;
    locale: string;
    loginUrl: string;
    loginUrlOther: string;
    maxMessagesToDisplay: number;
    maxToDisplay: number;
    mfaEnabled: boolean;
    mfaResetEnabled: boolean;
    mobilePhone: string;
    pager: string;
    phoneDay: string;
    phoneEvening: string;
    refreshInterval: number;
    role: UserRole;
    showCritical: boolean;
    showMessageEvents: boolean;
    showOk: boolean;
    showSLAAlerts: boolean;
    showSuspended: boolean;
    showTestResults: boolean;
    showTransient: boolean;
    showUnconfigured: boolean;
    showUnknown: boolean;
    showUnreachable: boolean;
    showWarning: boolean;
    timeZone: string;
    userCurrentPassword: string;
    userPassword: string;
    userPasswordConfirm: string;
    userSerialNumber: number;
    viewType: UserViewType;
}

export type UsersToMove = {
    userIds: number[];
    organizationId: number;
    role: UserRole;
};

type UsersMoveResult = {
    usersFailedToMove: {loginName: string, reason: string}[];
    organizationTo: string;
};

type GetUsersRequest = ApiJsonPagedRequest & ApiJsonSearchedRequest & ApiJsonSortedRequest;

type GetUsersResponse = ApiJsonPagedData & {
    users: UserInfoOld[];
};

type GetUserResponse = ApiJsonPagedData & {
    userInfo: UserInfoOld;
};

type IsExternalAuthAvailableResponse = {
    isExternalAuthAvailable: boolean;
};

class UserApiService {
    getLandingPage(user: UserInfo) {
        return ((user.loginUrl === 'useLoginUrlOther' ? user.loginUrlOther : user.loginUrl) ?? '').replace(/^(\/#\/)/, '');
    }

    getCurrentUser() {
        return ApiV1.get<ApiV1Response<UserInfo[]>>('/user');
    }

    resetPassword(payload: {currentPassword: string, newPassword: string}) {
        return ApiV1.post<ApiV1Response<undefined>>('/user/credentials', payload);
    }

    verify(emailAddress: string, code: string) {
        return ApiV1.post<ApiV1Response<undefined>>('/user/verification', {emailAddress, code});
    }

    sendCode(emailAddress: string) {
        return ApiV1.get<ApiV1Response<undefined>>(`/user/verification/totp?emailAddress=${emailAddress}`);
    }

    getCurrentUserOld() {
        return ApiJson.post<ApiJsonResponse<GetUserResponse>>('/admin/user/getCurrentUser', {});
    }

    list(data: GetUsersRequest) {
        return ApiJson.post<ApiJsonResponse<GetUsersResponse>>('/admin/user/list', data);
    }

    delete(data: ApiJsonSearchedRequest) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/user/delete', data);
    }

    create(data: UserCreateRequest) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/user/create', data);
    }

    update(data: UserUpdateRequest) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/user/update', data);
    }

    isExternalAuthAvailable() {
        return ApiJson.post<ApiJsonResponse<IsExternalAuthAvailableResponse>>('/admin/user/isExternalAuthAvailable', {});
    }

    move(data: UsersToMove) {
        return ApiV1.put<ApiV1Response<UsersMoveResult[]>>('/user/move', data);
    }
}

export default new UserApiService();
