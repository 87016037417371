import {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import PermissionRoute from '../PermissionRoute';
import {PermissionCategory, PermissionOperation} from '../../services/PermissionService';

const Organizations = lazy(() => import('./organizations/Organizations'));

const Status = () => {
    return <Routes>
        <Route element={<PermissionRoute category={PermissionCategory.DEPARTMENT} operation={PermissionOperation.READ}/>}>
            <Route path="organizations" element={
                <Suspense fallback={'...'}>
                    <Organizations/>
                </Suspense>
            }/>
        </Route>
    </Routes>;
};

export default Status;