import styles from './style.module.css';

import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useAppDispatch, useAppSelector} from '../../../../store';
import {useCallback, useMemo} from 'react';
import {toggleSideMenuCollapsed} from '../LayoutHeader/slice';
import Tooltip from '../../../../clf/Tooltip';
import {faChevronLeft} from '@fortawesome/pro-regular-svg-icons';

const SideMenu = () => {
    const dispatch = useAppDispatch();
    const header = useAppSelector(({header}) => header);
    const toggleSideMenu = useCallback(() => dispatch(toggleSideMenuCollapsed()), []);
    const mainClass = useMemo(
        () => `${styles.main} ${header.sideMenuCollapsed && styles.collapsed}`,
        [header.sideMenuCollapsed]
    );

    return <>
        {!!header.submenu.length && <nav className={mainClass}>
            <button
                className={styles.toggle}
                onClick={toggleSideMenu}
            >
                <FontAwesomeIcon icon={faChevronLeft}/>
            </button>

            {header.submenu.map((a) => <Tooltip
                key={a.href}
                position={'right'}
                enabled={header.sideMenuCollapsed}
                text={a.name}
            >
                <NavLink
                    className={`${styles.link} ${a.href === header.curSubmenu ? styles.active : ''} `}
                    to={a.href}
                >
                    {a.icon && <FontAwesomeIcon icon={a.icon} fixedWidth={true}/>}
                    <span>{a.name}</span>
                </NavLink>
            </Tooltip>)}
        </nav>}
    </>;
};

export default SideMenu;
