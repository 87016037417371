import {logout} from '../../data/authSlice';
import Button from '../../../../clf/form/Button';
import {useAppDispatch} from '../../../../store';

export default function BackToLogin() {
    const dispatch = useAppDispatch();

    return <Button
        type={'button'}
        variant={'tertiary'}
        size={'big'}
        onClick={() => dispatch(logout())}
    >
        Back to Login
    </Button>;
}