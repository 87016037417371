import styles from './style.module.css';

import {NavLink, useLocation, useSearchParams} from 'react-router-dom';
import {useEffect, useMemo} from 'react';
import {setUser, setCurrent, setMenu} from './slice';
import {useAppDispatch, useAppSelector} from '../../../../store';
import {logout} from '../../data/authSlice';
import {genHref} from '../../../../utils/UrlUtils';
import {fetchProductLogo} from '../../data/sessionSlice';
import {toggleEnabled as toggleSummaryPanelEnabled} from '../SummaryPanel/slice';
import AppLauncher from '../AppLauncher';
import {setWhatsNewVisited} from '../../data/appSlice';

const LayoutHeader = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const app = useAppSelector(({app}) => app.data);
    const auth = useAppSelector(({auth}) => auth.data.auth);
    const {user, itc, scope, productLogo} = useAppSelector(({session}) => session.data);
    const header = useAppSelector(({header}) => header);
    const summaryPanel = useAppSelector(({summaryPanel}) => summaryPanel);

    const logoClassName = useMemo(() => `${styles.logo} ${productLogo?.endsWith('default_product_logo.svg') ? styles.logoDef : ''}`, [productLogo]);

    // define a logo
    useEffect(() => {
        dispatch(fetchProductLogo());
    }, [auth]);

    useEffect(() => {
        dispatch(setUser({
            shortName: user?.loginName,
            fullName: (user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : user?.loginName),
            email: user?.emailAddress,
            iconStyle: auth?.represented ? styles.eye : styles.user
        }));
    }, [auth, user]);

    useEffect(() => {
        dispatch(setMenu({group: auth?.representedUser?.userGroup, scope, userInfo: user}));
    }, [auth, scope]);

    useEffect(() => {
        dispatch(setCurrent(genHref(location, searchParams.toString())));
    }, [auth, scope, location, searchParams]);

    return <header className={styles.header}>
        <a
            href="/"
            className={logoClassName}
            style={{
                backgroundImage: `url(${productLogo || ''})`,
            }}
        />

        {!!header.menu && <nav className={styles.nav}>
            {header.menu.map((a, i) =>
                <NavLink
                    key={i}
                    className={`${styles.link} ${a.href === header.curMenu ? styles.active : ''} `}
                    to={a.href}
                >
                    {a.name}
                </NavLink>
            )}
        </nav>}

        <div className={styles.controls}>
            <a
                className={`${styles.link} ${styles.summary} ${summaryPanel.enabled ? styles.active : ''}`}
                title={summaryPanel.enabled ? 'Hide Summary Pane' : 'Show Summary Pane'}
                onClick={() => dispatch(toggleSummaryPanelEnabled())}
            />

            <div className={`${styles.link} ${styles.dropDownTrigger} ${app.newsAvailable ? styles.marked : ''} ${styles.info}`} tabIndex={0}>
                <div className={styles.dropDown}>
                    <div className={styles.dropDownTitle}>Help</div>
                    <div>
                        <a className={styles.dropDownLink} target="_blank" rel="noreferrer" href="https://www.kaseya.com/customer-success/training/">Kaseya University</a>
                        <a className={styles.dropDownLink} target="_blank" rel="noreferrer" href="https://helpdesk.kaseya.com">Knowledge Base</a>
                        <a className={styles.dropDownLink} target="_blank" rel="noreferrer" href="https://help.kaseya.com">User Guide</a>
                        <a className={styles.dropDownLink} target="_blank" rel="noreferrer" href="https://www.community.connectit.com/kaseya/categories/kaseya-ideas-portal">Suggest A Feature</a>
                    </div>

                    <div className={styles.dropDownTitle}>Legal</div>
                    <div>
                        <a className={styles.dropDownLink} target="_blank" rel="noreferrer" href="https://www.kaseya.com/legal/website-terms-of-use/">Terms of Use</a>
                        <a className={styles.dropDownLink} target="_blank" rel="noreferrer" href="https://www.kaseya.com/legal/kaseya-privacy-statement/">Privacy Policy</a>
                    </div>

                    <hr/>

                    <div className={`${styles.release} ${app.newsAvailable ? styles.hasNews : ''}`}>
                        <span>
                            {app.appVersion}
                        </span>

                        <a
                            className={styles.releaseButton}
                            target="_blank"
                            rel="noreferrer"
                            href={app.info?.whatsNewUrl}
                            onClick={() => dispatch(setWhatsNewVisited())}
                        >What’s New</a>
                    </div>
                </div>
            </div>

            <div className={`${styles.link} ${styles.dropDownTrigger} ${header.iconStyle}`} tabIndex={0}>
                {header.shortName}
                <div className={styles.dropDown}>
                    <div className={`${styles.dropDownHead} ${header.iconStyle}`}>
                        <div>
                            <b className={styles.dropDownHeadTitle}>{header.fullName}</b>
                            {!!header.email && <span className={styles.dropDownHeadHint}>{header.email}</span>}
                        </div>
                    </div>
                    <hr/>

                    <a
                        className={styles.dropDownLink}
                        onClick={() => dispatch(logout(app.itc?.itCompleteSsoEnabled && itc?.itCompleteEnabled ? itc?.itCompleteLogoutUrl : undefined))}
                    >Log Out</a>
                </div>
            </div>

            <AppLauncher />
        </div>
    </header>;
};

export default LayoutHeader;
