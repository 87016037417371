import {createSlice} from '@reduxjs/toolkit';

interface DeviceSearchSlice {
    deviceSearchType: string;
    selectedAdapterId: string | null;
    selectedAction: string;
    selectedVersion: string;
    model: string;
    selectedVendor: string;
}

const initialState: DeviceSearchSlice = {
    deviceSearchType: '',
    selectedAdapterId: null,
    selectedAction: '>',
    selectedVersion: '',
    model: '',
    selectedVendor: '',
};

const deviceSearchSlice = createSlice({
    name: 'deviceSearchSlice',
    initialState,
    reducers: {
        setDeviceSearchType: (state, action) => {
            state.deviceSearchType = action.payload;
        },
        setSelectedAdapterId: (state, action) => {
            state.selectedAdapterId = action.payload;
        },
        setSelectedAction: (state, action) => {
            state.selectedAction = action.payload;
        },
        setSelectedVersion: (state, action) => {
            state.selectedVersion = action.payload;
        },
        setModel: (state, action) => {
            state.model = action.payload;
        },
        setSelectedVendor: (state, action) => {
            state.selectedVendor = action.payload;
        },
    }
});

export const {
    setDeviceSearchType,
    setSelectedAdapterId,
    setSelectedAction,
    setSelectedVersion,
    setModel,
    setSelectedVendor
} = deviceSearchSlice.actions;

export default deviceSearchSlice.reducer;