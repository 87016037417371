import axios, {AxiosResponse} from 'axios';

export type ApiV1Response<T> = {
    success: boolean;
    result: T;
    error: null | {
        message: string;
    };
};

const ApiV1 = axios.create({
    baseURL: '/api/v1',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
    }
});

ApiV1.interceptors.response.use(function (response: AxiosResponse<ApiV1Response<unknown>>) {
    if (response.status === 401) {
        window.location.replace('/');
    }

    if (response.data.success === false) {
        return Promise.reject(response.data.error?.message ?? 'unknown error');
    }

    return response;
}, function (error) {
    return Promise.reject(error);
});

export default ApiV1;
