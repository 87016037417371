import ApiV1, {ApiV1Response} from './ApiV1';
import {UrlUtils} from '../utils/UrlUtils';
import {MfaGlobalSettings} from './GlobalConfigurationApiService';

export interface MfaStatistics {
    serialNumber: number;
    username: string;
    title: string;
    department: string;
    mfaEnabled: boolean;
    trustedDevicesCount: number;
}

export interface MfaDetails {
    qrCode: string;
    rawKey: string;
}

class MfaApiService {
    getGlobalConfig() {
        return ApiV1.get<ApiV1Response<MfaGlobalSettings[]>>('/global-config/mfa');
    }

    disableReminder() {
        return ApiV1.delete('/mfa/reminder');
    }

    rememberDevice() {
        return ApiV1.post('/mfa/trusted-device');
    }

    getRegistrationData() {
        return ApiV1.post<ApiV1Response<MfaDetails[]>>('/mfa/registration');
    }

    confirm(oneTimeToken: string) {
        return ApiV1.post('/mfa/registration/confirmation', {oneTimeToken});
    }

    verify(oneTimeToken: string) {
        return ApiV1.post('/mfa/verification', {oneTimeToken});
    }

    getStatistics(groupType: string | null, departmentSerialNumber: number | null) {
        return ApiV1.get<MfaStatistics[]>('/mfa/statistics', {params: {groupType, departmentSerialNumber}});
    }

    reset(selectedUsers: number[]) {
        return ApiV1.put('/mfa', selectedUsers);
    }

    disable(data: number[]) {
        return ApiV1.delete('/mfa', {data});
    }

    enable(selectedUsers: number[]) {
        return ApiV1.post('/mfa', selectedUsers);
    }

    forgetDevices(data: number[]) {
        return ApiV1.delete('/mfa/trusted-device', {data});
    }
}

export default new MfaApiService();
