import ApiV1, {ApiV1Response} from './ApiV1';
import ApiJson, {ApiJsonPagedData, ApiJsonResponse} from './ApiJson';

export interface DgeLocation {
    serialNumber: number;
    name: string;
    streetAddress: string;
    city: string;
    state: string;
    comments: string;
    dgeCount: number;
    accountIds: number[] | null;
}

export interface LocationInfo {
    name: string;
    serialNumber: number;
    state: string,
    city: string,
    streetAddress: string,
    comments: string,
    dges: [
        {
            serialNumber: number,
            creationTime: number,
            defaultSubnet: string
        }
    ],
    isExtension: false,
    creationTime: number
}

interface GetLocationsResponse extends ApiJsonPagedData {
    locations: LocationInfo[];
}

class LocationApiService {
    getAll() {
        return ApiV1.get<ApiV1Response<DgeLocation[]>>('/locations');
    }

    getOne(id: number) {
        return ApiV1.get<ApiV1Response<DgeLocation[]>>(`/locations/${id}`);
    }

    create(location: DgeLocation) {
        return ApiV1.post<ApiV1Response<DgeLocation[]>>('/locations', location);
    }

    update(location: DgeLocation) {
        return ApiV1.put<ApiV1Response<DgeLocation[]>>('/locations', location);
    }

    delete(id: number) {
        return ApiV1.delete<ApiV1Response<DgeLocation[]>>(`/locations/${id}`);
    }

    getLocations(departmentSerialNumber?: number, serialNumbers?: number[]) {
        return ApiJson.post<ApiJsonResponse<GetLocationsResponse>>('/location/getLocations', {departmentSerialNumber, serialNumbers});
    }
}

export default new LocationApiService();
