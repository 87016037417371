import styles from '../../../clf/LandingTemplate/styles.module.css';
import {useCallback, useEffect, useState} from 'react';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import LandingTemplate from '../../../clf/LandingTemplate';
import {useAppDispatch, useAppSelector} from '../../../store';
import Title from '../../../clf/Title';
import Text from '../../../clf/Text';
import Label from '../../../clf/form/Label';
import TextInput from '../../../clf/form/TextInput';
import Button from '../../../clf/form/Button';
import BackToLogin from '../../../common/components/ui/buttons/BackToLogin';
import {getAuthStatus} from '../../../common/components/data/authSlice';
import {useNavigate} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import {ApiV1Response} from '../../../services/ApiV1';
import {ItcRedirectUriDto} from '../../../services/ItcApiService';

type ItCompleteAuthenticationParams = { isJit?: boolean };

const ItCompleteAuthentication = ({isJit = false}: ItCompleteAuthenticationParams) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector(({auth}) => auth.data.auth);
    const [username, setUsername] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);

    const redirectCallback = useCallback(async (response: AxiosResponse<ApiV1Response<ItcRedirectUriDto[]>>) => {
        const redirectUri = new URL(response.data.result[0].redirectUri);
        await dispatch(getAuthStatus());
        navigate(redirectUri.href.replace(redirectUri.origin, '').replace(/^\/#\//, '/'));
    }, []);

    const enableItComplete = async () => {
        if (!username || !password) {
            setUsername((oldValue) => oldValue ?? '');
            setPassword((oldValue) => oldValue ?? '');

            return;
        }

        TraverseApiService.itc.mapUnauthorizedUser(username, password)
            .then(async (response) => redirectCallback(response))
            .catch((response) => dispatch(showError('Failed to create mapping between user and KaseyaOne user', response)));
    };

    const createJitUser = async () => {
        TraverseApiService.itc.createJitUser()
            .then((response) => redirectCallback(response))
            .catch((response) => dispatch(showError('Failed to create user with KaseyaOne', response)));
    };

    useEffect(() => {
        if (auth?.representedUser) {
            navigate('/');
        }
    }, [auth]);

    return <LandingTemplate onEnterKeyCallback={enableItComplete}>
        <div className={styles.titleBlock}>
            <Title
                variant={'page'}
                style={{textAlign: 'center'}}
            >
                Enable IT Complete
            </Title>

            <Text style={{color: 'var(--clf-text-color-secondary)'}}>
                Enter your existing Traverse username and password to enable login with IT Complete.
            </Text>
        </div>

        <div className={styles.form}>
            <Label label={'Username'}>
                <TextInput
                    value={username ?? ''}
                    invalid={username === ''}
                    onChange={(event) => setUsername(event.target.value)}
                    hint={username === '' ? 'Can\'t be empty' : ''}
                />
            </Label>

            <Label label={'Password'}>
                <TextInput
                    type="password"
                    value={password ?? ''}
                    invalid={password === ''}
                    onChange={(event) => setPassword(event.target.value)}
                    hint={password === '' ? 'Can\'t be empty' : ''}
                />
            </Label>
        </div>

        <div className={styles.form}>
            <Button
                size={'big'}
                onClick={enableItComplete}
            >
                Enable
            </Button>

            <div className={styles.or}>Or</div>

            {isJit && <Button
                variant={'secondary'}
                size={'big'}
                className={styles.k1Button}
                onClick={createJitUser}
            >
                Create User with KaseyaOne
            </Button>}

            <BackToLogin/>
        </div>
    </LandingTemplate>;
};

export default ItCompleteAuthentication;