import styles from '../../clf/LandingTemplate/styles.module.css';
import {ReactNode, useEffect, useState} from 'react';
import TraverseApiService from '../../services/TraverseApiService';
import {showError, showSuccess} from '../../common/components/ui/snack-bar/notificationSlice';
import LandingTemplate from '../../clf/LandingTemplate';
import Title from '../../clf/Title';
import Text from '../../clf/Text';
import Label from '../../clf/form/Label';
import Button from '../../clf/form/Button';
import TextInput from '../../clf/form/TextInput';
import {useAppDispatch, useAppSelector} from '../../store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Banner from '../../clf/Banner';
import PasswordStrength from '../../common/components/ui/PasswordStrength';
import {faCheck} from '@fortawesome/pro-regular-svg-icons';
import {patchAuthStatus} from '../../common/components/data/authSlice';
import BackToLogin from '../../common/components/ui/buttons/BackToLogin';
import {useNavigate} from 'react-router-dom';

const PasswordReset = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector(({auth}) => auth.data.auth);
    const [anchor, setAnchor] = useState<HTMLElement>();
    const [currentPassword, setCurrentPassword] = useState<string | null>(null);
    const [newPassword, setNewPassword] = useState<string | null>(null);
    const [newHint, setNewHint] = useState<ReactNode>('');
    const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
    const [confirmHint, setConfirmHint] = useState<ReactNode>('');
    const [confirmPassed, setConfirmPassed] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [error, setError] = useState('');

    const resetPassword = () => {
        if (!currentPassword || !newPassword || !strongPassword || !confirmPassed) {
            setCurrentPassword((oldValue) => oldValue ?? '');
            setNewPassword((oldValue) => oldValue ?? '');
            setConfirmPassword((oldValue) => oldValue ?? '');

            return;
        }

        TraverseApiService.user.resetPassword({currentPassword, newPassword})
            .then(() => {
                dispatch(patchAuthStatus({passwordResetEnabled: false}));
                dispatch(showSuccess('Password updated!'));
            })
            .catch((response) => dispatch(showError('Failed to update password', response)));
    };

    useEffect(() => {
        if (!auth?.authenticated || !auth.passwordResetEnabled) {
            navigate('/');
        }
    }, [auth]);

    useEffect(() => {
        let confirmPassed = false;
        let confirmHint: ReactNode = '';
        let newHint: ReactNode = '';

        if (confirmPassword === null) {
            // do nothing
        } else if (confirmPassword === '') {
            confirmHint = 'Can\'t be empty';
        } else if (confirmPassword !== newPassword) {
            confirmHint = 'Passwords don\'t match';
        } else if (confirmPassword === currentPassword) {
            confirmHint = 'New password can\'t be equal to current password';
        } else if (confirmPassword) {
            confirmHint = <span style={{color: 'var(--clf-color-success)'}}><FontAwesomeIcon icon={faCheck}/> Passwords match</span>;
            confirmPassed = true;
        }

        if (strongPassword) {
            newHint = <span style={{color: 'var(--clf-color-success)'}}><FontAwesomeIcon icon={faCheck}/> Strong password</span>;
        } else if (newPassword === '') {
            newHint = 'Can\'t be empty';
        }

        setConfirmPassed(confirmPassed);
        setConfirmHint(confirmHint);
        setNewHint(newHint);
    }, [currentPassword, newPassword, confirmPassword, strongPassword]);

    return <LandingTemplate onEnterKeyCallback={resetPassword}>
        <div className={styles.titleBlock}>
            <Title
                variant={'page'}
                style={{textAlign: 'center'}}
            >
                Change Password
            </Title>

            <Text style={{color: 'var(--clf-text-color-secondary)', textAlign: 'center'}}>
                Enter a new password for your account.
            </Text>
        </div>

        <div className={styles.form}>
            {error && <Banner
                severity={'critical'}
                variant={'block'}
                text={error}
                onClose={() => setError('')}
            />}

            <Label label={'Current Password'}>
                <TextInput
                    type="password"
                    value={currentPassword ?? ''}
                    invalid={currentPassword === ''}
                    onChange={(event) => setCurrentPassword(event.target.value)}
                    hint={currentPassword === '' ? 'Can\'t be empty' : ''}
                />
            </Label>

            <Label label={'Password'}>
                <TextInput
                    type="password"
                    value={newPassword ?? ''}
                    invalid={newPassword === '' || (!!newPassword && !strongPassword)}
                    onChange={(event) => setNewPassword(event.target.value)}
                    hint={newHint}
                    onFocus={(e) => setAnchor(e.target)}
                    onBlur={() => setAnchor(undefined)}
                />
            </Label>

            <PasswordStrength
                anchor={anchor}
                onClose={() => setAnchor(undefined)}
                password={newPassword ?? ''}
                setStrong={setStrongPassword}
            />

            <Label label={'Confirm Password'}>
                <TextInput
                    type="password"
                    value={confirmPassword ?? ''}
                    invalid={!!confirmHint && !confirmPassed}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    hint={confirmHint}
                />
            </Label>
        </div>

        <div className={styles.form}>
            <Button
                size={'big'}
                onClick={resetPassword}
            >
                Confirm
            </Button>

            <BackToLogin/>
        </div>
    </LandingTemplate>;
};

export default PasswordReset;
