import styles from '../../../clf/LandingTemplate/styles.module.css';
import {SuccessImage} from '../../../common/icons/TraverseIcons';
import {useAppDispatch, useAppSelector} from '../../../store';
import LandingTemplate from '../../../clf/LandingTemplate';
import Title from '../../../clf/Title';
import Text from '../../../clf/Text';
import Button from '../../../clf/form/Button';
import {skipMfaReminder} from '../../../common/components/data/authSlice';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';

const MfaConfirmed = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.auth.data.auth);

    const goNext = () => {
        dispatch(skipMfaReminder());
        navigate('/');
    };

    useEffect(() => {
        if (!auth?.authenticated || !auth.mfaStatus?.enabled || !auth.mfaStatus?.confirmed) {
            return navigate('/');
        }
    }, [auth]);

    return <LandingTemplate onEnterKeyCallback={goNext}>
        <div className={styles.titleBlock}>
            <SuccessImage/>

            <Title
                variant={'page'}
                style={{textAlign: 'center'}}
            >
                Success
            </Title>

            <Text style={{color: 'var(--clf-text-color-secondary)'}}>
                Your TOTP Authentication App is configured and you are now ready to use 2FA. You may be asked to use 2FA the next time you sign in.
            </Text>
        </div>

        <div className={styles.form}>
            <Button
                size={'big'}
                onClick={goNext}
            >
                Done
            </Button>
        </div>
    </LandingTemplate>;
};

export default MfaConfirmed;
