import './clf/clf.css';

import THEME from './common/theme';
import MfaModule from './components/mfa/Mfa';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {HashRouter, Route, Routes, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {SnackBarProvider} from './common/components/ui/snack-bar/SnackBarHook';
import GlobalNotification from './common/components/ui/snack-bar/GlobalNotification';
import LoginPage from './components/landing/LoginPage';
import AuthenticatedArea from './components/AuthenticatedArea';
import ItComplete from './components/it-complete/ItComplete';
import PrivateRoute from './components/PrivateRoute';
import {useEffect} from 'react';
import {initApp} from './common/components/data/appSlice';
import {showError} from './common/components/ui/snack-bar/notificationSlice';
import PasswordReset from './components/landing/PasswordReset';
import EmailVerification from './components/landing/EmailVerification';
import {useAppDispatch, useAppSelector} from './store';
import {getAuthStatus} from './common/components/data/authSlice';
import {genHref, isEmptyUrl} from './utils/UrlUtils';
import {initSession} from './common/components/data/sessionSlice';
import TraverseApiService from './services/TraverseApiService';

const LandingHelper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const {auth, skipMfaReminder, resetRepresentation} = useAppSelector(({auth}) => auth.data);
    const {user, landingPage} = useAppSelector(({session}) => session.data);

    useEffect(() => {
        const isBlankPage = isEmptyUrl(genHref(location, searchParams.toString()));

        if (user || !auth || !isBlankPage) {
            return;
        }

        if (!auth.loginUser && !auth.representedUser) {
            navigate('/login');
        } else if (auth.authenticated) {
            if (!skipMfaReminder && auth.mfaStatus?.mfaReminderEnabled) {
                navigate('/mfa/remind');
            } else if (auth.passwordResetEnabled) {
                navigate('/password-reset');
            } else if (!auth.emailVerified) {
                navigate('/email-verification');
            }
        } else if (auth.mfaStatus?.enabled) {
            if (auth.mfaStatus?.confirmed) {
                navigate('/mfa/verification');
            } else {
                navigate('/mfa/confirmation');
            }
        }
    }, [auth, user, skipMfaReminder, location, searchParams]);

    useEffect(() => {
        if (!auth?.authenticated || !auth.emailVerified || auth.passwordResetEnabled) {
            return;
        }

        if (!user) {
            dispatch(initSession(auth.representedUser));
        } else if (resetRepresentation || !landingPage) {
            const userLandingPage = TraverseApiService.user.getLandingPage(user);

            if (!isEmptyUrl(userLandingPage)) {
                navigate(userLandingPage);
            }
        } else {
            navigate(landingPage);
        }
    }, [auth, user]);

    return !auth
        ? null
        : <Routes>
            <Route element={<PrivateRoute/>}>
                <Route path="*" element={<AuthenticatedArea/>}/>
            </Route>

            <Route path="/login" element={<LoginPage/>}/>

            <Route path="/mfa/*" element={<MfaModule/>}/>

            <Route path="/it-complete/*" element={<ItComplete/>}/>

            <Route path="/password-reset/*" element={<PasswordReset/>}/>

            <Route path="/email-verification/*" element={<EmailVerification/>}/>
        </Routes>;
};

const App = () => {
    const dispatch = useAppDispatch();
    const app = useAppSelector(({app}) => app);

    useEffect(() => {
        // first check the license and some other stuff
        dispatch(initApp());
    }, []);

    useEffect(() => {
        if (app.error) {
            dispatch(showError('Failed to check license. Contact with Administrator.'));
        } else if (app.data.license?.expired) {
            window.location.replace('/html/licenseExpired.jsp');
        } else if (app.data.initiated) {
            dispatch(getAuthStatus());
        }
    }, [app.error, app.data.initiated, app.data.license?.expired]);

    return <HashRouter>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={THEME}>
                <CssBaseline/>

                <SnackBarProvider>
                    {app.data.initiated && <LandingHelper/>}

                    <GlobalNotification/>
                </SnackBarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </HashRouter>;
};

export default App;
