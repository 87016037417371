import ApiV1, {ApiV1Response} from './ApiV1';
import authApiService from './AuthApiService';
import bmsApiService from './BmsApiService';
import deviceApiService from './DeviceApiService';
import dgeApiService from './DgeApiService';
import discoveryApiService from './DiscoveryApiService';
import itcApiService from './ItcApiService';
import maintenanceApiService from './MaintenanceApiService';
import mfaApiService from './MfaApiService';
import mibApiService from './MibApiService';
import mixApiService from './MixApiService';
import ncmApiService from './NcmApiService';
import healthApiService from './HealthApiService';
import licenseApiService from './LicenseApiService';
import locationApiService from './LocationApiService';
import organizationApiService from './OrganizationApiService';
import permissionService from './PermissionService';
import perspectiveApiService from './PerspectiveApiService';
import reportApiService from './ReportApiService';
import templateApiService from './TemplateApiService';
import testApiService from './TestApiService';
import userApiService from './UserApiService';
import globalConfigurationApiService from './GlobalConfigurationApiService';
import {SeverityName} from '../utils/Severity';
import systemApiService from './SystemApiService';

export enum LogoMode {
    DEFAULT = 'DEFAULT',
    CUSTOM = 'CUSTOM',
}

export interface Logo {
    relativePath: string;
}

export interface ApplicationInfo {
    releaseInfo: {
        stage: string
        releaseVersion: string;
        buildVersion: string;
        buildDate: string;
    };
    saasInstance: boolean;
    whatsNewUrl: string;
}

export interface SeveritySummaryItem {
    severity: SeverityName;
    count: number;
    highlight: boolean;
}

class TraverseApiService {
    auth = authApiService;
    bms = bmsApiService;
    device = deviceApiService;
    dge = dgeApiService;
    discovery = discoveryApiService;
    itc = itcApiService;
    mfa = mfaApiService;
    mib = mibApiService;
    mix = mixApiService;
    maintenance = maintenanceApiService;
    ncm = ncmApiService;
    health = healthApiService;
    license = licenseApiService;
    location = locationApiService;
    organization = organizationApiService;
    permissions = permissionService;
    perspective = perspectiveApiService;
    report = reportApiService;
    system = systemApiService;
    test = testApiService;
    template = templateApiService;
    user = userApiService;
    globalConfiguration = globalConfigurationApiService;

    getLogo(logoType: string) {
        return ApiV1.get<ApiV1Response<Logo[]>>('/logo', {params: {logoType}});
    }

    saveLogos(formData: FormData) {
        return ApiV1.post<ApiV1Response<Logo[]>>('/logo', formData);
    }

    getApplicationInfo() {
        return ApiV1.get<ApiV1Response<ApplicationInfo[]>>('/application/info');
    }

    getDevicesSeveritiesSummary() {
        return ApiV1.get<ApiV1Response<SeveritySummaryItem[]>>('/devices/severities/summary');
    }

    getEventsSeveritiesSummary() {
        return ApiV1.get<ApiV1Response<SeveritySummaryItem[]>>('/events/severities/summary');
    }
}

export default new TraverseApiService();
