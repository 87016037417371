import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import App from './app';
import store from './store';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(isToday);
dayjs.extend(localeData);
dayjs.extend(LocalizedFormat);

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container);
    root.render(
        <Provider store={store}>
            <App/>
        </Provider>
    );
}
