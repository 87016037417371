import styles from './style.module.css';

import {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useAppSelector} from '../../store';
import IframeWrapper from '../../common/components/ui/IframeWrapper';
import LayoutHeader from '../../common/components/ui/LayoutHeader';
import SideMenu from '../../common/components/ui/SideMenu';
import SummaryPanel from '../../common/components/ui/SummaryPanel';
import Reports from '../reports/Reports';
import Superuser from '../superuser/Superuser';
import Status from '../status/Status';
import Administration from '../administration/Administration';
import PermissionRoute from '../PermissionRoute';
import {PermissionCategory, PermissionOperation} from '../../services/PermissionService';

const MibBrowser = lazy(() => import('../mib-browser/MibBrowser'));
const NcmModule = lazy(() => import('../ncm/Ncm'));
const DgeManagement = lazy(() => import('../superuser/DgeManagement'));

const AuthenticatedArea = () => {
    const {navigationVisible} = useAppSelector((state) => state.app.data);
    const session = useAppSelector(({session}) => session.data);

    return <>
        {navigationVisible && <>
            <LayoutHeader/>

            <SummaryPanel/>
        </>}

        {!!session.user && <div className={styles.middle}>
            {navigationVisible && <SideMenu/>}

            <div className={styles.content}>
                <Routes>
                    <Route path="administration/*" element={<Administration/>}/>
                    <Route path="reports/*" element={<Reports/>}/>
                    <Route path="status/*" element={<Status/>}/>

                    <Route path="mib-browser" element={
                        <Suspense fallback={'...'}>
                            <MibBrowser/>
                        </Suspense>
                    }/>

                    <Route path="ncm/*" element={
                        <Suspense fallback={'...'}>
                            <NcmModule/>
                        </Suspense>
                    }/>

                    <Route element={<PermissionRoute category={PermissionCategory.SUPER} operation={PermissionOperation.READ}/>}>
                        <Route path="dge-management/*" element={
                            <Suspense fallback={'...'}>
                                <DgeManagement/>
                            </Suspense>
                        }/>
                    </Route>

                    <Route element={<PermissionRoute category={PermissionCategory.SUPER} operation={PermissionOperation.READ}/>}>
                        <Route path="superuser/*" element={<Superuser/>}/>
                    </Route>

                    <Route path="*" element={<IframeWrapper/>}/>
                </Routes>
            </div>
        </div>}
    </>;
};

export default AuthenticatedArea;
