import ApiJson, {ApiJsonResponse} from './ApiJson';

export interface DeviceTypeInfo {
    description: string;
    type: number;
}

export interface LocaleInfo {
    country: string;
    displayName: string;
    language: string;
}

export interface TimeZoneInfo {
    id: string;
    name: string;
}

type GetDeviceTypesResponse = {
    deviceTypes: DeviceTypeInfo[];
}

type GetLocalesResponse = {
    localeInfoList: LocaleInfo[];
}

type GetTimeZonesResponse = {
    timeZones: TimeZoneInfo[];
}

class SystemApiService {
    getDeviceTypes() {
        return ApiJson.post<ApiJsonResponse<GetDeviceTypesResponse>>('/admin/system/getDeviceTypes', {});
    }

    getLocales() {
        return ApiJson.post<ApiJsonResponse<GetLocalesResponse>>('/admin/system/getLocales', {});
    }

    getTimeZones() {
        return ApiJson.post<ApiJsonResponse<GetTimeZonesResponse>>('/admin/system/getTimeZones', {});
    }
}

export default new SystemApiService();