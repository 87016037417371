import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService from '../../../../services/TraverseApiService';
import {showError} from '../../../../common/components/ui/snack-bar/notificationSlice';
import {ApiPagination, ApiSorting, NcmDevice, NcmDeviceConfigurationRevision} from '../../../../services/NcmApiService';
import {AppDispatch, RootState} from '../../../../store';

interface CompareRevisionsSlice {
    hasMore: boolean,
    devices: NcmDevice[];
    difference: string | null;
    firstDeviceId: number | null;
    firstRevision: NcmDeviceConfigurationRevision | null;
    firstRevisions: NcmDeviceConfigurationRevision[];
    secondDeviceId: number | null;
    secondRevision: NcmDeviceConfigurationRevision | null;
    secondRevisions: NcmDeviceConfigurationRevision[];
}

const initialState: CompareRevisionsSlice =  {
    hasMore: true,
    devices: [],
    difference: null,
    firstDeviceId: null,
    firstRevision: null,
    firstRevisions: [],
    secondDeviceId: null,
    secondRevision: null,
    secondRevisions: [],
};

const compareRevisionsSlice = createSlice({
    name: 'compareRevisions',
    initialState,
    reducers: {
        setDevices: (state, {payload}: PayloadAction<NcmDevice[]>) => {
            state.hasMore = payload.length > 0;
            state.devices = [...state.devices, ...payload];
        },
        setDifference: (state, action) => {
            state.difference = action.payload;
        },
        setFirstDeviceId: (state, action) => {
            state.firstDeviceId = action.payload;
        },
        setFirstRevision: (state, action) => {
            state.firstRevision = action.payload;
        },
        setFirstRevisions: (state, action) => {
            state.firstRevisions = action.payload;
        },

        setSecondDeviceId: (state, action) => {
            state.secondDeviceId = action.payload;
        },
        setSecondRevision: (state, action) => {
            state.secondRevision = action.payload;
        },
        setSecondRevisions: (state, action) => {
            state.secondRevisions = action.payload;
        },
    }
});

const r = compareRevisionsSlice.actions;

const getDevices = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {devices, hasMore}: CompareRevisionsSlice = getState().ncmCompareRevisions;

    if (!hasMore) {
        return;
    }

    const requestParams = {
        field: 'interfaceIpAddress',
        deviceQuery: ''
    };
    const pagination: ApiPagination = {
        skip: devices.length,
        limit: 100
    };
    const sorting: ApiSorting = {
        sortBy: 'deviceName',
        sortOrder: 'asc'
    };

    TraverseApiService.ncm.findDevices(requestParams, pagination, sorting)
        .then((response) => dispatch(r.setDevices(response.data.items)))
        .catch((response) => dispatch(showError('Failed to load devices', response)));
};

const setFirstDevice = (device: NcmDevice | null) => async (dispatch: AppDispatch) => {
    dispatch(r.setFirstDeviceId(device?.id || null));
    dispatch(r.setFirstRevision(null));
    dispatch(r.setFirstRevisions([]));

    if (device) {
        try {
            const data = await loadRevisions(device);
            dispatch(r.setFirstRevisions(data));
        } catch (e) {
            dispatch(showError('Failed to load revisions history', e));
        }
    }
};

const setFirstRevision = (revision: NcmDeviceConfigurationRevision | null) => (dispatch: AppDispatch) => {
    dispatch(r.setFirstRevision(revision));
};

const setSecondDevice = (device: NcmDevice | null) => async (dispatch: AppDispatch) => {
    dispatch(r.setSecondDeviceId(device?.id || ''));
    dispatch(r.setSecondRevision(null));
    dispatch(r.setSecondRevisions([]));

    if (device) {
        try {
            const data = await loadRevisions(device);
            dispatch(r.setSecondRevisions(data));
        } catch (e) {
            dispatch(showError('Failed to load revisions history', e));
        }
    }
};

const setSecondRevision = (revision: NcmDeviceConfigurationRevision | null) => (dispatch: AppDispatch) => {
    dispatch(r.setSecondRevision(revision));
};

const loadRevisions = async (device: NcmDevice) => {
    const requestParams = {
        ipAddress: device.ipAddress,
        emeraldDeviceSN: device.id,
        managedNetwork: device.managedNetwork
    };

    return TraverseApiService.ncm.getChangeLog(requestParams)
        .then((response) => response.data);
};

const loadContent = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {devices, firstDeviceId, firstRevision, secondDeviceId, secondRevision}: CompareRevisionsSlice = getState().ncmCompareRevisions;
    const firstDevice = devices.find((device) => device.id === firstDeviceId) || null;
    const secondDevice = devices.find((device) => device.id === secondDeviceId) || null;

    if (!firstDevice || !firstRevision || !secondDevice || !secondRevision) {
        return;
    }

    const requestParams = {
        ipAddress: firstDevice.ipAddress,
        emeraldDeviceSN: firstDevice.id,
        managedNetwork: firstDevice.managedNetwork,
        configPath: firstRevision.path,
        revisionDate: firstRevision.lastChangedMillis,
        secondIpAddress: secondDevice.ipAddress,
        secondEmeraldDeviceSN: secondDevice.id,
        secondManagedNetwork: secondDevice.managedNetwork,
        secondConfigPath: secondRevision.path,
        secondRevisionDate: secondRevision.lastChangedMillis
    };

    TraverseApiService.ncm.getRevisionsDiff(requestParams)
        .then((response) => dispatch(r.setDifference(response.data.unifiedDiff)))
        .catch((response) => dispatch(showError('Failed to load current revision', response)));
};

export {
    getDevices,
    setFirstDevice,
    setFirstRevision,
    setSecondDevice,
    setSecondRevision,
    loadContent
};

export default compareRevisionsSlice.reducer;