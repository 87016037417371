import ApiJson, {
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse,
    ApiJsonSearchedRequest, ApiJsonSearchOption,
    ApiJsonSortedRequest,
} from './ApiJson';
import ApiV1, {ApiV1Response} from './ApiV1';

export enum ReportType {
    ADHOC = 'ADHOC',
    QUERY = 'QUERY',
    SCHEDULED = 'SCHEDULED',
}

export type MyReportFilterName = ApiJsonSearchOption.FREEFORM | ApiJsonSearchOption.REPORT_TYPE;
export type MyReportFilters = Partial<Record<MyReportFilterName, string | undefined>>;

export type ScheduledReportFilterName = ApiJsonSearchOption.FREEFORM | ApiJsonSearchOption.SCHEDULED_REPORT_NAME | ApiJsonSearchOption.REPORT_QUERY_NAME;
export type ScheduledReportFilters = Partial<Record<ScheduledReportFilterName, string | undefined>>;

export interface ReportDescriptor {
    serialNumber: number;
    name: string;
    queryName: string;
    url: string;
    type: ReportType;
    suspended: boolean;
}

export enum ScheduledReportFrequencyOption {
    EVERY = 1,
    DAY_OF_MONTH,
    DAY_OF_WEEK,
    ONE_TIME
}

export enum ScheduledReportFrequencyOrder {
    FIRST = 1,
    LAST
}

export enum ScheduledReportSendOption {
    USE_DATETIME = 1,
    WHEN_GENERATED
}

export enum ScheduledReportPeriod {
    DAY = 1,
    WEEK,
    MONTH,
    HOUR
}

export enum ScheduledReportWeekday {
    MONDAY = 1,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
}

export interface ReportQueryLite {
    serialNumber: number;
    name: string;
}

export interface ScheduledReport {
    serialNumber?: number;
    name: string;
    suspended: boolean;
    reportQueryId: number;
    emailAddress: string;
    period: ScheduledReportPeriod;
    frequency: number;
    year: number;
    month: number;
    day: number;
    hour: number;
    minutes: number;
    sendOption: ScheduledReportSendOption;
    frequencyOption: ScheduledReportFrequencyOption;
    frequencyOrder: ScheduledReportFrequencyOrder;
    frequencyWeekday: ScheduledReportWeekday;
}

interface ListMyReportsResponse extends ApiJsonPagedData {
    reportsList: ReportDescriptor[];
}

type ListMyReportsRequestParams = ApiJsonPagedRequest & ApiJsonSearchedRequest & ApiJsonSortedRequest;

class ReportApiService {
    listMyReports(data: ListMyReportsRequestParams) {
        return ApiJson.post<ApiJsonResponse<ListMyReportsResponse>>('/reports/listMyReports', data);
    }

    deleteMyReports(names: string[], serialNumbers: number[]) {
        return ApiV1.delete<ApiV1Response<null>>('/reports/my-report', {data: {names, serialNumbers}});
    }

    listScheduledReports(data: ListMyReportsRequestParams) {
        return ApiJson.post<ApiJsonResponse<ListMyReportsResponse>>('/reports/listScheduledReports', data);
    }

    getReportQueriesLite() {
        return ApiV1.get<ApiV1Response<ReportQueryLite[]>>('/reports/report-query-lite');
    }

    getScheduledReport(id: number) {
        return ApiV1.get<ApiV1Response<ScheduledReport[]>>(`/reports/scheduled-report/${id}`);
    }

    createScheduledReport(scheduledReport: ScheduledReport) {
        return ApiV1.post<ApiV1Response<ScheduledReport[]>>('/reports/scheduled-report', scheduledReport);
    }

    updateScheduledReport(scheduledReport: Partial<ScheduledReport>) {
        return ApiV1.put<ApiV1Response<ScheduledReport[]>>('/reports/scheduled-report', scheduledReport);
    }

    deleteScheduledReports(data: number[]) {
        return ApiV1.delete<ApiV1Response<null>>('/reports/scheduled-report', {data});
    }
}

export default new ReportApiService();
