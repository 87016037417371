import ApiJson, {
    ApiJsonMessagedResponse,
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse,
    ApiJsonSearchedRequest, ApiJsonSortedRequest,
} from './ApiJson';

export interface ServerHealth {
    serverId: string;
    ipAddress: string;
    version: string;
    osName: string;
    serverName: string;
    timeOffset: string;
    versionOffset: string;
    warning: boolean;
    showSynchronize: boolean;
}

export interface ComponentHealth {
    name: string;
    lastHeartbeat: string;
    lastHeartbeatTimestamp: number;
    remark: string;
    severity: number;
}

type GetServerHealthRequest = ApiJsonPagedRequest & ApiJsonSearchedRequest & ApiJsonSortedRequest;

interface GetServerHealthResponse extends ApiJsonPagedData {
    servers: ServerHealth[];
}

interface ComponentHealthStatusResponse {
    components: ComponentHealth[];
}

class HealthApiService {
    getServerStatus(data: GetServerHealthRequest) {
        return ApiJson.post<ApiJsonResponse<GetServerHealthResponse>>('/admin/component/getServerStatus', data);
    }

    getComponentHealthStatus(serverId: string, serverIpAddress: string) {
        return ApiJson.post<ApiJsonResponse<ComponentHealthStatusResponse>>('/admin/component/getComponentHealthStatus', {serverId, serverIpAddress});
    }

    reloadConfiguration(serverId: string, serverIpAddress: string) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/component/reloadConfiguration', {serverId, serverIpAddress});
    }

    remove(serverId: string, serverIpAddress: string) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/component/remove',{serverId, serverIpAddress});
    }

    synchronize(serverId: string, serverIpAddress: string) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/component/synchronize', {serverId, serverIpAddress});
    }
}

export default new HealthApiService();
