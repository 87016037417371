import {createContext, useContext, useState} from 'react';
import {Alert} from '@mui/material';
import {Slide, Snackbar} from '@mui/material';
import {AlertColor} from '@mui/material/Alert/Alert';

const GlobalSnackBar = ({notification, hideNotification}: FixType) => {
    const isValidationError = () => {
        return notification.validationError && notification.validationError.response.status === 400;
    };

    const hasMessages = (data: FixType) => {
        return data && data.properties && data.properties.length;
    };

    let message = notification.message;

    if (isValidationError() && hasMessages(notification.validationError.response.data)) {
        const {data} = notification.validationError.response;
        const detailedError = data.properties.map((property: FixType) => `${property.path}: ${property.message}`).join('\n');
        message = `${message}.\n${detailedError}`;
    }

    return (
        <Snackbar open={notification.show}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            autoHideDuration={5000}
            TransitionComponent={(props) => <Slide {...props} direction="left"/>}
            onClose={hideNotification}>
            <Alert onClose={hideNotification}
                severity={notification.severity}
                variant="filled"
                style={{whiteSpace: 'pre-line', minWidth: '15rem'}}>
                {message}
            </Alert>
        </Snackbar>
    );
};

const SnackBarContext = createContext((notification: FixType) => {
    console.log(notification);
});

interface SnackBarNotification {
    show: boolean;
    severity: AlertColor;
    message?: string;
    validationError?: FixType;
}

export function SnackBarProvider({children}: FixType) {
    const [notification, setNotification] = useState<SnackBarNotification>({show: false, severity: 'success'});

    const hideNotification = () => setNotification((prevState) => ({...prevState, show: false}));

    const showError = (notification: string, validationError: FixType) => setNotification({
        message: notification,
        show: true,
        severity: 'error',
        validationError
    });

    const showSuccess = (notification: string) => setNotification({
        message: notification,
        show: true,
        severity: 'success'
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <SnackBarContext.Provider value={{showError, showSuccess}}>
        {children}
        <GlobalSnackBar notification={notification} hideNotification={hideNotification}/>
    </SnackBarContext.Provider>;
}

const useNotification = () => useContext(SnackBarContext);
export default useNotification;

