import ApiJson, {
    ApiJsonMessagedResponse,
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse, ApiJsonSearchCriterion,
    ApiJsonSearchedRequest, ApiJsonSearchOption,
    ApiJsonSortedRequest,
} from './ApiJson';

export enum TemplateType {
    AUTOMATION_PROFILE = 'AUTOMATION_PROFILE',
    LINKED = 'LINKED',
    STATIC = 'STATIC',
}

export const getTemplateTypeName = (templateType: TemplateType): string => {
    switch (templateType) {
    case TemplateType.AUTOMATION_PROFILE:
        return 'Automation Profile';
    case TemplateType.LINKED:
        return 'Linked';
    case TemplateType.STATIC:
        return 'Static';
    }
};

export enum RuleOperator {
    AND = 'AND',
    OR = 'OR',
}

export const getRuleOperatorName = (ruleOperator: RuleOperator): string => {
    switch (ruleOperator) {
    case RuleOperator.AND:
        return 'And';
    case RuleOperator.OR:
        return 'Or';
    }
};

export enum RuleProperty {
    NAME = 'NAME',
    TYPE = 'TYPE',
    SUBTYPE = 'SUBTYPE',
    INTERVAL = 'INTERVAL',
    UNITS = 'UNITS',
}

export const getRulePropertyName = (ruleProperty: RuleProperty): string => {
    switch (ruleProperty) {
    case RuleProperty.NAME:
        return 'Name';
    case RuleProperty.TYPE:
        return 'Type';
    case RuleProperty.SUBTYPE:
        return 'Subtype';
    case RuleProperty.INTERVAL:
        return 'Interval';
    case RuleProperty.UNITS:
        return 'Units';
    }
};

export enum RuleActionType {
    SET_INTERVAL = 'SET_INTERVAL',
    SET_THRESHOLDS = 'SET_THRESHOLDS',
    SET_UNITS = 'SET_UNITS',
    SET_ACTION_PROFILE = 'SET_ACTION_PROFILE',
    SET_ACTION_PROFILE_BY_NAME = 'SET_ACTION_PROFILE_BY_NAME',
    DISCARD = 'DISCARD',
}

export const getRuleActionTypeName = (ruleActionType: RuleActionType): string => {
    switch (ruleActionType) {
    case RuleActionType.SET_INTERVAL:
        return 'Set Polling Interval';
    case RuleActionType.SET_THRESHOLDS:
        return 'Set Thresholds';
    case RuleActionType.SET_UNITS:
        return 'Set Units';
    case RuleActionType.SET_ACTION_PROFILE:
        return 'Set Action Profile';
    case RuleActionType.SET_ACTION_PROFILE_BY_NAME:
        return 'Set Action Profile by Name';
    case RuleActionType.DISCARD:
        return 'Discard Test';
    }
};

export enum ConditionOperator {
    EQUALS = 'EQUALS',
    CONTAINS = 'CONTAINS',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
    LESS_THAN = 'LESS_THAN',
    LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS',
    IS_EMPTY = 'IS_EMPTY',
    IN = 'IN',
}

export type RuleActionInfo = {
    ruleActionType: RuleActionType;
    interval?: number;
    warningThreshold?: string;
    criticalThreshold?: string;
    units?: string;
    actionProfileSerialNumber?: number;
    actionProfileName?: string;
}

export type RuleConditionInfo = {
    propertyName: RuleProperty;
    conditionOperator: ConditionOperator;
    negated: boolean;
    value: string;
};

export type AutomationRule = {
    serialNumber: number;
    ruleOperator: RuleOperator;
    ruleConditions: RuleConditionInfo[];
    ifActions: RuleActionInfo[];
    elseActions: RuleActionInfo[];
};

export type SimpleNetworkDeviceInfo = {
    serialNumber: number;
    name: string;
    accountSerialNumber: number;
    accountName: string;
};

export type TemplateInfo  = {
    templateType: TemplateType;
    serialNumber: number;
    name: string;
    description: string;
    accountSerialNumber: number;
    accountName: string;

    // Exclusive properties for static template
    profileHash: number;
    dirProperty: string;
    originalDeviceSerialNumber: number;
    originalDeviceCreationTime: number;

    // Exclusive properties for linked template
    masterDevice: SimpleNetworkDeviceInfo;
    linkedDevices: SimpleNetworkDeviceInfo[];
    numOfLinkedDevices: number;
    testScope: boolean;
    actionProfileScope: boolean;
    scheduleScope: boolean;

    // Exclusive properties for SmartTemplate
    scope: string;
    searchCriterias: ApiJsonSearchCriterion[];
    automationRules: AutomationRule[];
    invalid: boolean;
};

export type TemplateFilterName
    = ApiJsonSearchOption.FREEFORM
    | ApiJsonSearchOption.TEMPLATE_TYPE
    | ApiJsonSearchOption.DEPARTMENT_SERIAL_NUMBER
    | ApiJsonSearchOption.DEVICE_SERIAL_NUMBER;
    // todo reverse-engineering shows that these should be available as well. Implement them someday
    // | ApiJsonSearchOption.DEVICE_SERIAL_NUMBER
    // | ApiJsonSearchOption.DEVICE_NAME
    // | ApiJsonSearchOption.DEVICE_ADDRESS
    // | ApiJsonSearchOption.DEVICE_TYPE
    // | ApiJsonSearchOption.LOCATION_SERIAL_NUMBER
    // | ApiJsonSearchOption.DEVICE_MODEL
    // | ApiJsonSearchOption.VENDOR_NAME
    // | ApiJsonSearchOption.TAG_1
    // | ApiJsonSearchOption.TAG_2
    // | ApiJsonSearchOption.TAG_3
    // | ApiJsonSearchOption.TAG_4
    // | ApiJsonSearchOption.TAG_5
    // | ApiJsonSearchOption.TEST_TYPE
    // | ApiJsonSearchOption.TEST_SUB_TYPE
    // | ApiJsonSearchOption.TEMPLATE_MASTER_DEVICE_SERIAL_NUMBER
    // | ApiJsonSearchOption.TEMPLATE_LINKED_DEVICE_SERIAL_NUMBER

export type TemplateFilters = Partial<Record<TemplateFilterName, string | undefined>>;

type GetTemplatesRequest = ApiJsonPagedRequest & ApiJsonSearchedRequest & ApiJsonSortedRequest;

type GetTemplatesResponse = ApiJsonPagedData & {
    templates: TemplateInfo[];
};

export type CreateTemplateRequest = {
    templateType: TemplateType;
    name: string;
    description: string;

    // Exclusive properties for static template
    originalDeviceSerialNumber?: number;

    // Exclusive properties for linked template
    masterDeviceSerialNumber?: number;
    linkedDeviceSerialNumbers?: number[];
    testScope?: boolean;
    actionProfileScope?: boolean;
    scheduleScope?: boolean;

    // Exclusive properties for SmartTemplate
    accountSerialNumber?: number;
    searchCriterias?: ApiJsonSearchCriterion[];
    automationRules?: AutomationRule[];
};

export type UpdateTemplateRequest = {
    serialNumber?: number;
    templateType: TemplateType;
    name: string;

    // Exclusive properties for static template
    profileHash?: number;

    // Exclusive properties for SmartTemplate
    searchCriterias?: ApiJsonSearchCriterion[];
    automationRules?: AutomationRule[];
};

type CreateTemplateResponse = {
    template: TemplateInfo;
};

export type CloneTemplateRequest = {
    sourceTemplateSerialNumber: number;
    destName: string;
    destAccountSerialNumber: number;
};

type CloneTemplateResponse = {
    template: TemplateInfo;
    actionsTaken: string[];
};

type UpdateTemplateResponse = {
    template: TemplateInfo;
};

type DeleteTemplatesRequest = ApiJsonSearchedRequest;

class TemplateApiService {
    getTemplates(data: GetTemplatesRequest) {
        return ApiJson.post<ApiJsonResponse<GetTemplatesResponse>>('/admin/template/list', data);
    }

    createTemplate(data: CreateTemplateRequest) {
        return ApiJson.post<ApiJsonResponse<CreateTemplateResponse>>('/admin/template/create', data);
    }

    updateTemplate(data: UpdateTemplateRequest) {
        return ApiJson.post<ApiJsonResponse<UpdateTemplateResponse>>('/admin/template/update', data);
    }

    cloneTemplate(data: CloneTemplateRequest) {
        return ApiJson.post<ApiJsonResponse<CloneTemplateResponse>>('/admin/template/clone', data);
    }

    deleteTemplates(data: DeleteTemplatesRequest) {
        return ApiJson.post<ApiJsonResponse<ApiJsonMessagedResponse>>('/admin/template/delete', data);
    }
}

export default new TemplateApiService();