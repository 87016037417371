import {createSlice} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {addResult} from '../results-panel/resultsSlice';
import {AppDispatch, RootState} from '../../../store';
import {NcmDevice, NcmNeighbor} from '../../../services/NcmApiService';

interface NeighborsSlice {
    collectNeighborsDialogOpened: boolean;
    collectNeighborsInProgress: boolean;
    selectedDevice?: NcmDevice;
}

const initialState: NeighborsSlice = {
    collectNeighborsDialogOpened: false,
    collectNeighborsInProgress: false,
    selectedDevice: undefined,
};

const neighborsSlice = createSlice({
    name: 'ncmNeighbors',
    initialState,
    reducers: {
        openCollectNeighborsDialog: (state, action) => {
            state.collectNeighborsDialogOpened = true;
            state.selectedDevice = action.payload;
        },
        startNeighborsCollecting: (state,) => {
            state.collectNeighborsInProgress = true;
        },
        finishNeighborsCollecting: (state,) => {
            state.collectNeighborsInProgress = false;
            state.collectNeighborsDialogOpened = false;
            state.selectedDevice = undefined;
        }
    }
});

const {
    startNeighborsCollecting,
    openCollectNeighborsDialog,
    finishNeighborsCollecting
} = neighborsSlice.actions;

const collectNeighborData = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const device = getState().ncmNeighbors.selectedDevice;

    if (!device) {
        return;
    }

    dispatch(startNeighborsCollecting());
    TraverseApiService.ncm.collectNeighboursData(device.id)
        .then(() => dispatch(finishNeighborsCollecting()))
        .catch(() => dispatch(finishNeighborsCollecting()));
};

const getNeighborsData = (device: NcmDevice) => (dispatch: AppDispatch) => {
    TraverseApiService.ncm.getDeviceNeighbors(device.id, device.managedNetwork)
        .then((response) => addNeighborsResult(device, response.data, dispatch))
        .catch((response) => dispatch(showError('Failed to get neighborsData', response)));
};

const addNeighborsResult = (device: NcmDevice, neighbors: NcmNeighbor[], dispatch: AppDispatch) => {
    const result = {
        id: `Neighbour-${device.ipAddress}`,
        name: `Neighbors of ${device.ipAddress}`,
        type: 'NEIGHBORS_DETAILS',
        payload: {
            ipAddress: device.ipAddress,
            managedNetwork: device.managedNetwork,
            neighbors
        }
    };
    dispatch(addResult(result));
};

export {
    collectNeighborData,
    getNeighborsData,
    openCollectNeighborsDialog,
    finishNeighborsCollecting,
    startNeighborsCollecting
};

export default neighborsSlice.reducer;
