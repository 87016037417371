import ApiV1, {ApiV1Response} from './ApiV1';

export interface Dge {
    serialNumber: number;
    dataCollector: boolean;
    name: string;
    desc: string;
    host: string;
    softLimit: number;
    hardLimit: number;
    testCount: number;
    deviceCount: number;
    locationId: number | null;
    dgeId: number | null;
    accountIds: number[] | null;
}

export interface DgeAuditReportDevices {
    name: string;
    total: number;
}

export interface DgeAuditReportTests {
    name: string;
    type: string;
    suspended: number;
    active: number;
    total: number;
}

export interface DgeAuditReport {
    devicesCountByType: DgeAuditReportDevices[];
    testsCountByType: DgeAuditReportTests[];
}

class DgeApiService {
    getAll() {
        return ApiV1.get<ApiV1Response<Dge[]>>('/dges');
    }

    getOne(id: number) {
        return ApiV1.get<ApiV1Response<Dge[]>>(`/dges/${id}`);
    }

    create(dge: Dge) {
        return ApiV1.post<ApiV1Response<Dge[]>>('/dges', dge);
    }

    update(dge: Dge) {
        return ApiV1.put<ApiV1Response<Dge[]>>('/dges', dge);
    }

    delete(id: number) {
        return ApiV1.delete<ApiV1Response<Dge[]>>(`/dges/${id}`);
    }

    getAuditReport(id: number) {
        return ApiV1.get<ApiV1Response<DgeAuditReport[]>>(`/dges/${id}/audit-report`);
    }
}

export default new DgeApiService();
