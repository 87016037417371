import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {refreshDevices} from '../devices/devicesSlice';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {NcmDevice} from '../../../services/NcmApiService';
import {AppDispatch, RootState} from '../../../store';

interface BackupsSlice {
    result: FixType[];
    selectedDevices: NcmDevice[];
    resultsDialogOpened: boolean;
    confirmationOpened: boolean;
    executionId: number;
}

const initialState: BackupsSlice = {
    result: [],
    selectedDevices: [],
    resultsDialogOpened: false,
    confirmationOpened: false,
    executionId: 0
};

const backupSlice = createSlice({
    name: 'ncmBackup',
    initialState,
    reducers: {
        openDialog: (state) => {
            state.result = [];
            state.resultsDialogOpened = true;
        },
        closeDialog: (state) => {
            state.resultsDialogOpened = false;
            state.result = [];
            state.executionId = 0;
        },
        setResult: (state, action) => {
            state.result = action.payload;
        },
        openConfirmation: (state, action) => {
            state.confirmationOpened = true;
            state.selectedDevices = action.payload;
        },
        closeConfirmation: (state) => {
            state.confirmationOpened = false;
            state.selectedDevices = [];
        },
        setExecutionId: (state, {payload}: PayloadAction<number>) => {
            state.executionId = payload;
        }
    }
});

const {
    openDialog,
    closeDialog,
    openConfirmation,
    closeConfirmation,
    setExecutionId,
    setResult
} = backupSlice.actions;

const createBackup = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const devices = getState().ncmBackup.selectedDevices;
    const deviceIds: number[] = devices.map((device: NcmDevice) => device.id);
    TraverseApiService.ncm.createBackup(deviceIds)
        .then((response) => {
            dispatch(closeConfirmation());
            dispatch(setExecutionId(response.data));
            dispatch(openDialog());
        })
        .catch((response) => {
            dispatch(showError('Failed to create backup', response));
            dispatch(closeConfirmation());
        });
};

const waitForBackupFinished = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const devices = getState().ncmBackup.selectedDevices;
    TraverseApiService.ncm.waitForBackupFinished(devices.length)
        .then((response) => {
            dispatch(setResult(response.data));
            dispatch(refreshDevices());
        })
        .catch((response) => {
            dispatch(setResult(response.data));
            dispatch(refreshDevices());
        });
};

const cancelBackup = () => (dispatch: AppDispatch, getState: () => RootState) => {
    const executionId = getState().ncmBackup.executionId;
    TraverseApiService.ncm.cancelBackup(executionId);
};

const showBackupResults = (device: NcmDevice) => (dispatch: AppDispatch) => {
    dispatch(refreshDevices());
    dispatch(openDialog());
    TraverseApiService.ncm.getDeviceStatus(device.id)
        .then((response) => dispatch(setResult([{
            deviceAddress: response.data.ipAddress,
            message: response.data.backupStatusMessage,
            result: response.data.backupStatus
        }])))
        .catch(() => dispatch(setResult([{
            deviceAddress: device.ipAddress,
            message: 'Failed to get backup status',
            result: 'ERROR'
        }])));
};

export {
    closeDialog,
    openConfirmation,
    closeConfirmation,
    createBackup,
    waitForBackupFinished,
    cancelBackup,
    showBackupResults
};

export default backupSlice.reducer;