import {useNavigate} from 'react-router-dom';
import LicenseKey from '../../../utils/LicenseKey';
import {useMemo} from 'react';
import {useAppSelector} from '../../../store';
import ContentWrap from '../../../clf/ContentWrap';
import Title from '../../../clf/Title';
import Container from '../../../clf/Container';
import Text from '../../../clf/Text';
import Lines from '../../../clf/layout/Lines';
import {NavigationItem} from '../../../common/components/ui/LayoutHeader/slice';

const GlobalConfig = () => {
    const navigate = useNavigate();
    const scope = useAppSelector(({session}) => session.data.scope);

    const menu: NavigationItem[] = useMemo(() => {
        if (!scope) {
            return [];
        }

        const tmp: NavigationItem[] = [
            {
                name: 'Integration Settings',
                desc: 'Configuration parameters for integrating Traverse UI with external applications',
                href: 'integration-settings',
            },
            {
                name: 'Data Gathering Engine',
                desc: 'Configuration parameters for various monitors on DGE/DGE extension',
                href: '/admin/suDGESettings.jsp',
            },
            {
                name: 'NetFlow Collector',
                desc: 'Enable/disable or configure NetFlow data collection on DGE/DGE extension',
                href: '/common/manageFlow.jsp',
                license: [
                    LicenseKey.key.FLOW_ANALYSIS
                ],
            },
            {
                name: 'Alarm Receiver/Message Handler',
                desc: 'Configuration parameters for SNMP trap, Syslog, Windows Events receivers',
                href: '/admin/suMessageHandlerManagement.jsp',
            },
            {
                name: 'User Settings',
                desc: 'Configure global settings for users',
                href: '/superuser/user-management',
            }
        ];

        return tmp.filter((i) => LicenseKey.checkScope(scope, i.license));
    }, [scope]);

    return <ContentWrap style={{maxWidth: '900px'}}>
        <Title variant={'page'}>Global Configuration Settings</Title>

        <Lines>
            {menu.map((item) => <Container
                key={item.href}
                style={{cursor: 'pointer'}}
                onClick={() => navigate(item.href)}
            >
                <Title variant={'block'}>{item.name}</Title>

                <Text>{item.desc}</Text>
            </Container>)}
        </Lines>
    </ContentWrap>;
};

export default GlobalConfig;
