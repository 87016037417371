import ApiV1, {ApiV1Response} from './ApiV1';
import {UserRole} from './UserApiService';

export type MfaGlobalSettings = {
    daysToRememberDevice: number;
    mfaRequiredForAdmins: boolean;
    mfaRequiredForUsers: boolean;
}

export type BmsIntegrationSettings = {
    bmsIntegrationEnabled: boolean;
    serverUrl: string;
    companyName: string;
    username: string;
    password?: string;
    bmsCreateAssetsEnabled: boolean;
    bmsServiceDeskEnabled: boolean;
    bmsTicketPriority: string;
    bmsTicketStatusNew: string;
    bmsTicketStatusCompleted: string;
    bmsTicketQueue: string;
    bmsTicketType: string;
    bmsNotificationEmail: string;
}

export type VsaIntegrationSettings = {
    vsaServiceDesk: boolean;
    liveConnect: boolean;
    vsaServiceUrl: string;
    vsaUserName: string;
    vsaPassword?: string;
    vsaLiveConnectClientId: string;
    vsaLiveConnectClientSecret?: string;
}

export type SamlIntegrationSettings = {
    samlEnabled: boolean;
    samlCertificate: string;
}

export type JitSettings = {
    enabled: boolean;
    organizationId: number;
    role: UserRole;
}

export type ItCompleteGlobalSettings = {
    itCompleteSsoEnabled: boolean;
    jitSettings: JitSettings;
}

export type GlobalConfiguration = VsaIntegrationSettings & SamlIntegrationSettings & {
    externalTicketing: boolean;
    cooperInsightsEnabled: boolean;
    userRepresentationEnabled: boolean;
    mfaGlobalSettings: MfaGlobalSettings;
    itCompleteGlobalSettings: ItCompleteGlobalSettings;
    bmsIntegrationSettings: BmsIntegrationSettings;
}

class GlobalConfigurationApiService {
    preparePatch<T>(patch: DeepPartial<T>, origin: T) {
        Object.entries(patch).forEach(([key, value]) => {
            const originalValue = origin[key as keyof T];

            if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                this.preparePatch(value, originalValue);
            } else if ((originalValue === undefined && value === '********') || (originalValue === value)) {
                delete patch[key as keyof T];
            }
        });
    }

    get() {
        return ApiV1.get<ApiV1Response<GlobalConfiguration[]>>('/global-config');
    }

    patch(patch: DeepPartial<GlobalConfiguration>) {
        return ApiV1.patch<ApiV1Response<GlobalConfiguration[]>>('/global-config', patch);
    }

    registerItCompleteOidcClient() {
        return ApiV1.post<ApiV1Response<undefined>>('/it-complete/client');
    }

    updateItCompleteOidcClient() {
        return ApiV1.put<ApiV1Response<undefined>>('/it-complete/client');
    }

    testVsaConnection(serverUrl: string, username: string, password: string) {
        return ApiV1.post<ApiV1Response<undefined>>('/vsa/test-connection', {serverUrl, username, password});
    }
}

export default new GlobalConfigurationApiService();
