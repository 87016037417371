import {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import PermissionRoute from '../PermissionRoute';
import {PermissionCategory, PermissionOperation} from '../../services/PermissionService';

const Tests = lazy(() => import('./tests/Tests'));
const Devices = lazy(() => import('./devices/Devices'));
const Discovery = lazy(() => import('./discovery/Discovery'));
const Templates = lazy(() => import('./templates/Templates'));
const Organizations = lazy(() => import('./organizations/Organizations'));
const Users = lazy(() => import('./users/Users'));
const User = lazy(() => import('./users/User'));

const Administration = () => {
    return <Routes>
        <Route element={<PermissionRoute category={PermissionCategory.DEVICE} operation={PermissionOperation.READ}/>}>
            <Route path="devices/*" element={
                <Suspense fallback={'...'}>
                    <Devices/>
                </Suspense>
            }/>
        </Route>

        <Route element={<PermissionRoute category={PermissionCategory.TEST} operation={PermissionOperation.READ}/>}>
            <Route path="tests/*" element={
                <Suspense fallback={'...'}>
                    <Tests/>
                </Suspense>
            }/>
        </Route>

        <Route element={<PermissionRoute category={PermissionCategory.DEVICE} operation={PermissionOperation.READ}/>}>
            <Route path="discovery/*" element={
                <Suspense fallback={'...'}>
                    <Discovery/>
                </Suspense>
            }/>
        </Route>

        <Route element={<PermissionRoute category={PermissionCategory.DEVICE} operation={PermissionOperation.READ}/>}>
            <Route path="templates/*" element={
                <Suspense fallback={'...'}>
                    <Templates/>
                </Suspense>
            }/>
        </Route>

        <Route element={<PermissionRoute category={PermissionCategory.DEPARTMENT} operation={PermissionOperation.READ}/>}>
            <Route path="organizations/*" element={
                <Suspense fallback={'...'}>
                    <Organizations/>
                </Suspense>
            }/>
        </Route>

        <Route element={<PermissionRoute category={PermissionCategory.USER} operation={PermissionOperation.READ}/>}>
            <Route path="users/*" element={
                <Suspense fallback={'...'}>
                    <Users/>
                </Suspense>
            }/>
        </Route>

        <Route path="user/*" element={
            <Suspense fallback={'...'}>
                <User/>
            </Suspense>
        }/>
    </Routes>;
};

export default Administration;