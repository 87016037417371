import styles from './styles.module.css';
import {ButtonHTMLAttributes} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: IconDefinition;
    rightIcon?: IconDefinition;
    size?: 'big' | 'medium' | 'small' | 'bulk' | 'wrap';
    variant?: 'primary' | 'secondary' | 'tertiary';
    severity?: 'default' | 'warning' | 'critical';
    ignored?: boolean;
    loading?: boolean;
    toggled?: boolean;
    value?: string;
}

export default function Button({
    icon,
    rightIcon,
    children,
    size = 'medium',
    severity = 'default',
    variant = 'primary',
    ignored = false,
    loading = false,
    toggled = false,
    ...props
}: ButtonProps) {
    if (loading) {
        return <button
            {...props}
            className={`${styles.main} ${styles[size]} ${styles[severity]} ${styles[variant]} ${styles.ignored} ${props.className ?? ''}`}
        >
            <FontAwesomeIcon icon={faSpinnerThird} spin={true} fixedWidth={true}/>
        </button>;
    }

    return <button
        {...props}
        className={`${styles.main} ${styles[size]} ${styles[severity]} ${styles[variant]} ${ignored && styles.ignored} ${toggled && styles.hover} ${props.className ?? ''}`}
    >
        {icon && <FontAwesomeIcon icon={icon} fixedWidth={true}/>}
        {children}
        {rightIcon && <FontAwesomeIcon icon={rightIcon} fixedWidth={true}/>}
    </button>;
}