export type LicenseScopePermission = Record<string, boolean>;

class LicenseKey {
    readonly key = {
        ADHOC_REPORTS: 'adhocreports',
        ADVANCED_REPORTS: 'advancedreports',
        APPLICATION_PROFILE: 'applicationprofile',
        AUTO_REDISCOVERY: 'autorediscovery',
        BASELINE: 'baseline',
        BVE_API: 'bveapi',
        CONFIG_MGMT: 'configmgmt',
        CONTAINER_ACTION: 'containeraction',
        CUSTOM_REPORTS: 'customreports',
        DASHBOARD: 'dashboard',
        DATA_COLLECTOR: 'datacollector',
        DEVICE_CONTAINER: 'devicecontainer',
        EVENT_MANAGER: 'eventmanager',
        FEDERATED_USER_MODEL: 'federatedusermodel',
        FLOW_ANALYSIS: 'flowanalysis',
        INTEROP_SEA: 'interopsea',
        INTEROP_SEV: 'interopsev',
        MONITORING_PROFILE: 'monitoringprofile',
        NESTED_CONTAINER: 'nestedcontainer',
        NETWORK_MAPS: 'networkmaps',
        NUM_DASHBOARDS: 'numdashboards',
        OEM_BRANDING: 'oembranding',
        PANORAMA: 'panorama',
        PROCESS_MONITOR: 'processmonitor',
        REMOTE_ACCESS: 'remoteaccess',
        REPORTS: 'reports',
        RULE_MEMBER_CONTAINER: 'rulemembercontainer',
        SAAS_INSTANCE: 'saasinstance',
        SCHEDULED_MAINTENANCE: 'scheduledmaintenance',
        SCHEDULED_REPORTS: 'scheduledreports',
        SEVERITY_RULE_CONTAINER: 'severityrulecontainer',
        SLA_MONITOR: 'slamonitor',
        SOFTWARE_DIST: 'softwaredist',
        SUMMARY_REPORTS: 'summaryreports',
        TEST_CONTAINER: 'testcontainer',
        TOPOLOGY_SERVICE: 'topologyservice',
        WEB_SERVICE: 'webservice',
    };

    checkScope(scope?: LicenseScopePermission, requiredScopes: string[] = []): boolean {
        return requiredScopes.length === 0 || requiredScopes.some((i) => scope?.[i] ?? false);
    }
}

export default new LicenseKey();