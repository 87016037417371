import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MibEntry, MibFileContent, MibSnmpEntry} from '../../services/MibApiService';

interface AppSlice {
    files: MibFileContent[];
    selectedMibItem: MibEntry | null;
    selectedOid: string;
    snmpItems: MibSnmpEntry[];
}

const initialState: AsyncSlice<AppSlice> = {
    loading: false,
    data: {
        files: [],
        selectedMibItem: null,
        selectedOid: '',
        snmpItems: [],
    },
};

const mibSlice = createSlice({
    name: 'mib',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setFiles: (state, {payload}: PayloadAction<MibFileContent[]>) => {
            state.data.files = payload;
        },
        addFile: (state, {payload}: PayloadAction<MibFileContent>) => {
            state.data.files = [
                ...state.data.files.filter((i) => i.response.mib.id !== payload.response.mib.id),
                payload
            ];
        },
        setSelectedMibItem: (state, {payload}: PayloadAction<MibEntry | null>) => {
            state.data.selectedMibItem = payload;
        },
        setSelectedOid: (state, {payload}: PayloadAction<string>) => {
            state.data.selectedOid = payload;
        },
        setSnmpItems: (state, {payload}: PayloadAction<MibSnmpEntry[]>) => {
            state.data.snmpItems = payload;
        },
        reset: (state) => {
            state.data = {
                files: [],
                selectedMibItem: null,
                selectedOid: '',
                snmpItems: [],
            };
        },
    },
});

const actions = mibSlice.actions;

export const {
    setLoading,
    setFiles,
    addFile,
    setSelectedMibItem,
    setSelectedOid,
    setSnmpItems,
    reset
} = actions;

export default mibSlice.reducer;