import styles from './styles.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {ComponentSeverity} from '../index';
import {
    faCircleCheck,
    faCircleExclamation,
    faSquareInfo,
    faTriangleExclamation,
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {ReactNode} from 'react';

interface BannerProps {
    variant?: 'page' | 'block';
    severity?: ComponentSeverity;
    title?: string;
    text?: string | ReactNode;
    hasBorder?: boolean;
    actions?: Record<string, () => void>;
    link?: Record<string, string>;
    onClose?: () => void;
}

const severityToIconMapping: Record<ComponentSeverity, IconDefinition> = {
    main: faSquareInfo,
    default: faSquareInfo,
    information: faSquareInfo,
    success: faCircleCheck,
    warning: faCircleExclamation,
    critical: faTriangleExclamation,
};

const Banner = ({
    variant = 'page',
    severity = 'information',
    title,
    text,
    link,
    onClose,
    hasBorder = true,
}: BannerProps) => <div className={`${styles.main} ${styles[variant]} ${styles[severity]} ${hasBorder ? styles.hasBorder : ''}`}>
    <FontAwesomeIcon
        className={styles.icon}
        icon={severityToIconMapping[severity]}
        width={20}
        style={{color: `var(--clf-color-${severity})`, fontSize: '20px'}}
    />

    <div className={styles.text}>
        {title ? <b>{title}</b> : ''} {text ?? ''} {link && Object.entries(link).map(([name, href]) => <a key={name} href={href}>{name}</a>)}
    </div>

    {onClose && <FontAwesomeIcon
        className={styles.close}
        icon={faXmark}
        fixedWidth={true}
        onClick={onClose}
    />}
</div>;

export default Banner;