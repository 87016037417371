import {useMemo} from 'react';
import {Outlet} from 'react-router-dom';
import {RouteProps} from 'react-router/dist/lib/components';
import {PermissionCategory, PermissionOperation} from '../services/PermissionService';
import TraverseApiService from '../services/TraverseApiService';

type PermissionRouteProps = RouteProps & {
    category: PermissionCategory;
    operation: PermissionOperation;
};

const PermissionRoute = ({category, operation}: PermissionRouteProps) => {
    const allowed = useMemo(() => TraverseApiService.permissions.check(category, operation), []);

    return allowed
        ? <Outlet/>
        : null;
};

export default PermissionRoute;