import ApiJson, {
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse,
    ApiJsonSearchedRequest, ApiJsonSearchOption,
    ApiJsonSortedRequest,
} from './ApiJson';

export enum MonitorConfigurationType {
    SNMP = 'snmp',
    VMWARE = 'vmware',
    WMI = 'wmi',
}

export interface ActionProfile {
    name: string;
    serialNumber: number;
}
interface GetActionProfilesResponse extends ApiJsonPagedData {
    actionProfiles: ActionProfile[];
}

interface Container {
    containerName: string;
    containerSerialNumber: number;
    departmentName: string;
}
interface GetContainersResponse extends ApiJsonPagedData {
    containers: Container[];
}

interface Dge {
    name: string;
    serialNumber: number;
}
interface GetDgesResponse extends ApiJsonPagedData {
    dges: Dge[];
}

interface DeviceInfo {
    deviceName: string;
    serialNumber: number;
}
interface GetDevicesResponse extends ApiJsonPagedData {
    devices: DeviceInfo[];
}

export interface TestType {
    testType: string;
    testSubType: string;
    typeDisplayName: string;
    subTypeDisplayName: string;
}
interface GetTestTypesResponse extends ApiJsonPagedData {
    testTypes: TestType[];
}

export interface WeeklySchedule {
    serialNumber: number;
    name: string;
    description: string;
    departmentSerialNumber: number;
    departmentName: string;
}
interface GetWeeklySchedulesResponse extends ApiJsonPagedData {
    weeklySchedules: WeeklySchedule[];
}

export interface ApplicationProfile {
    serialNumber: number;
    name: string;
    description: string;
    departmentSerialNumber: number;
    departmentName: string;
}
interface GetApplicationProfilesResponse extends ApiJsonPagedData {
    applicationProfiles: ApplicationProfile[] | null;
}

export interface MonitorConfiguration {
    accountName: string;
    created: string;
    default: boolean;
    description: string;
    name: string;
    serialNumber: number;
    type: MonitorConfigurationType;
}
export interface ConfigurationAccount {
    accountName: string;
    accountSerialNumber: number;
    monitorConfigs: MonitorConfiguration[];
}
interface GetMonitorConfigurationsResponse {
    configAccounts: ConfigurationAccount[];
}

type GetActionProfilesRequest = ApiJsonPagedRequest & ApiJsonSortedRequest & {
    includeNoAction?: boolean;
    includeUserClassActionProfiles?: boolean;
    onlyRepresentedUserDepartment?: boolean;
    departmentSerialNumbers?: number[];
}

type GetGroupsResponse = {
    groupDescriptors: GroupDescriptor[];
}

export type GroupDescriptor = {
    name: string;
    comment: string;
    numAccounts: number;
    updatePrivilege: boolean;
    deletePrivilege: boolean;
    mappingsPrivilege: boolean;
}

class MixApiService {
    getActionProfiles(data: GetActionProfilesRequest) {
        return ApiJson.post<ApiJsonResponse<GetActionProfilesResponse>>('/admin/action/list', data);
    }

    getContainers() {
        return ApiJson.post<ApiJsonResponse<GetContainersResponse>>('/container/getStatuses', {
            searchCriterias: [{searchOption: ApiJsonSearchOption.FREEFORM, searchTerms: ''}],
            paging: {limit: 500, page: 0},
            sorting: {sortDirection: 'ASC', sortField: 'containerName'}
        });
    }

    getDges() {
        return ApiJson.post<ApiJsonResponse<GetDgesResponse>>('/location/getDges', {
            serialNumbers: []
        });
    }

    getDevices() {
        return ApiJson.post<ApiJsonResponse<GetDevicesResponse>>('/device/getStatuses', {
            searchCriterias: [{searchOption: ApiJsonSearchOption.FREEFORM, searchTerms: ''}],
            sorting: {sortDirection: 'ASC', sortField: 'deviceName'}
        });
    }

    getTestTypes(onlyProvisionedTest = true) {
        return ApiJson.post<ApiJsonResponse<GetTestTypesResponse>>('/test/getTestTypes', {onlyProvisionedTest});
    }

    getWeeklySchedules(departmentSerialNumbers: number[]) {
        return ApiJson.post<ApiJsonResponse<GetWeeklySchedulesResponse>>('/admin/weeklySchedule/getWeeklySchedules', {
            departmentSerialNumbers,
            sorting: {sortDirection: 'ASC', sortField: 'departmentName'}
        });
    }

    getApplicationProfiles(departmentSerialNumber: number | null) {
        return ApiJson.post<ApiJsonResponse<GetApplicationProfilesResponse>>('/applicationProfile/getApplicationProfiles', {
            departmentSerialNumber,
            sorting: {sortDirection: 'ASC', sortField: 'name'}
        });
    }

    getMonitorConfigurations(data: ApiJsonSearchedRequest) {
        return ApiJson.post<ApiJsonResponse<GetMonitorConfigurationsResponse>>('/admin/monitorConfig/list', data);
    }

    listAdminGroups() {
        return ApiJson.post<ApiJsonResponse<GetGroupsResponse>>('/admin/group/listAdminGroups', {});
    }

    listUserGroups() {
        return ApiJson.post<ApiJsonResponse<GetGroupsResponse>>('/admin/group/listUserGroups', {});
    }
}

export default new MixApiService();