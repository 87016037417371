import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError, showSuccess} from '../ui/snack-bar/notificationSlice';
import {AuthStatus} from '../../../services/AuthApiService';
import {AppDispatch} from '../../../store';
import {clearSession} from './sessionSlice';

interface AuthSlice {
    skipMfaReminder: boolean;
    resetRepresentation: boolean;
    auth?: AuthStatus;
}

const initialState: AsyncSlice<AuthSlice> = {
    data: {
        skipMfaReminder: false,
        resetRepresentation: false,
        auth: undefined,
    },
};

export const getAuthStatus = createAsyncThunk('auth/get', async (): Promise<AuthStatus> => {
    return (await TraverseApiService.auth.getStatus()).data.result[0];
});

export const login = createAsyncThunk('auth/post', async (payload: {username: string, password: string}): Promise<AuthStatus> => {
    const result = (await TraverseApiService.auth.login(payload)).data;

    if (!result.success) {
        throw new Error(result.error.message);
    }

    return result.result[0];
});

export const logout = createAsyncThunk('auth/delete', async (redirectTo: string | undefined, thunkAPI): Promise<{redirectTo?: string, auth: AuthStatus}> => {
    const auth = (await TraverseApiService.auth.logout()).data.result[0];
    if (auth.authenticated) {
        thunkAPI.dispatch(clearSession());
    }

    return {redirectTo, auth};
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthStatus: (state, {payload}: PayloadAction<AuthStatus>) => {
            state.data.auth = payload;
            state.data.resetRepresentation = true;
        },
        patchAuthStatus: (state, {payload}: PayloadAction<Partial<AuthStatus>>) => {
            if (state.data.auth) {
                state.data.auth = Object.entries(state.data.auth)
                    .reduce((a, [k, v]) => {
                        return {...a, [k]: payload[k as keyof AuthStatus] ?? v};
                    }, {} as AuthStatus);
            }
        },
        skipMfaReminder: (state) => {
            state.data.skipMfaReminder = true;
        },
        clearError: (state) => {
            state.error = undefined;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getAuthStatus.pending, (state) => {
                state.error = undefined;
            })
            .addCase(getAuthStatus.fulfilled, (state, {payload}) => {
                state.data.skipMfaReminder = true;
                state.data.auth = payload;
            })
            .addCase(getAuthStatus.rejected, (state, action) => {
                state.error = action.error;
            })

            .addCase(login.pending, (state) => {
                state.error = undefined;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.data.auth = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.error = action.error;
            })

            .addCase(logout.pending, (state) => {
                state.error = undefined;
            })
            .addCase(logout.fulfilled, (state, {payload}) => {
                if (payload.auth.authenticated) {
                    state.data.auth = payload.auth;
                    state.data.resetRepresentation = true;
                } else {
                    window.location.assign(payload.redirectTo || '/');
                }
            })
            .addCase(logout.rejected, (state, action) => {
                state.error = action.error;
                state.data.auth = undefined;

                window.location.assign('/');
            });
    }
});

export const {setAuthStatus, patchAuthStatus, skipMfaReminder, clearError} = authSlice.actions;

export const represent = (representedUserId: number) => async (dispatch: AppDispatch) => {
    try {
        const {success, error, result} = (await TraverseApiService.auth.setRepresentedUser({representedUserId})).data;

        if (success) {
            dispatch(clearSession());
            dispatch(setAuthStatus(result[0]));
            dispatch(showSuccess('Representing the user'));
        } else if (error?.message) {
            dispatch(showError(error.message));
        }
    } catch (e) {
        dispatch(showError('Failed to represent the user', e));
    }
};

export default authSlice.reducer;