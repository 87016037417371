import styles from './styles.module.css';
import {CSSProperties, InputHTMLAttributes, ReactNode, useMemo, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    hint?: ReactNode;
    invalid?: boolean;
    pseudoReadOnly?: boolean;
    type?: 'text' | 'email' | 'password';
    value?: string;
    wrapStyle?: CSSProperties;
    icon?: IconDefinition;
}

export default function TextInput({
    hint,
    pseudoReadOnly,
    type = 'text',
    invalid = false,
    icon,
    wrapStyle,
    ...props
}: TextInputProps) {
    const [toggleableType, setToggleableType] = useState(type);
    const canToggle = type === 'password';

    const faIcon = useMemo(() => {
        if (icon) {
            return <FontAwesomeIcon
                className={styles.iconButton}
                icon={icon}
                fixedWidth={true}
            />;
        } else if (canToggle) {
            return <FontAwesomeIcon
                className={styles.iconButton}
                icon={toggleableType === 'password' ? faEye : faEyeSlash}
                fixedWidth={true}
                onClick={() => setToggleableType(toggleableType === 'password' ? 'text' : 'password')}
            />;
        } else {
            return null;
        }
    }, [icon, canToggle, toggleableType]);

    return <label className={styles.wrap} style={wrapStyle} data-required={props.required}>
        <input
            {...props}
            type={toggleableType}
            className={`${styles.main} ${invalid && styles.invalid} ${pseudoReadOnly && styles.pseudoReadOnly} ${faIcon && styles.withIcon}`}
        />
        {hint && <small className={styles.hint}>{hint}</small>}
        {faIcon}
    </label>;
}