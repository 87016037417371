import ApiJson, {
    ApiJsonPagedData,
    ApiJsonPagedRequest,
    ApiJsonResponse,
    ApiJsonSearchedRequest,
    ApiJsonSortedRequest, ApiJsonSearchOption,
} from './ApiJson';

export type TestStatusRequestedField
    = 'ACTION_PROFILE_NAME'
    | 'CRITICAL_THRESHOLD'
    | 'DEPARTMENT_NAME'
    | 'DEPARTMENT_SERIAL_NUMBER'
    | 'DEVICE_NAME'
    | 'DEVICE_SERIAL_NUMBER'
    | 'EXPORTED_TEST'
    | 'MONITOR_INSTANCE'
    | 'MONITOR_INSTANCE_URL'
    | 'SUSPENDED'
    | 'TEST_INTERVAL'
    | 'TEST_SCHEDULE_NAME'
    | 'TEST_SCHEDULE_SERIAL_NUMBER'
    | 'UNITS'
    | 'WARNING_THRESHOLD';

export type TestFilterName
    = ApiJsonSearchOption.ACTION_PROFILE_SERIAL_NUMBER
    | ApiJsonSearchOption.CONTAINER_NAME
    | ApiJsonSearchOption.CONTAINER_SERIAL_NUMBER
    | ApiJsonSearchOption.DEPARTMENT_SERIAL_NUMBER
    | ApiJsonSearchOption.DEVICE_ADDRESS
    | ApiJsonSearchOption.DEVICE_MODEL
    | ApiJsonSearchOption.DEVICE_NAME
    | ApiJsonSearchOption.DEVICE_SERIAL_NUMBER
    | ApiJsonSearchOption.DEVICE_STATUS
    | ApiJsonSearchOption.DEVICE_TYPE
    | ApiJsonSearchOption.DGE_SERIAL_NUMBER
    | ApiJsonSearchOption.FREEFORM
    | ApiJsonSearchOption.LOCATION_SERIAL_NUMBER
    | ApiJsonSearchOption.SUSPEND
    | ApiJsonSearchOption.TAG_1
    | ApiJsonSearchOption.TAG_2
    | ApiJsonSearchOption.TAG_3
    | ApiJsonSearchOption.TAG_4
    | ApiJsonSearchOption.TAG_5
    | ApiJsonSearchOption.TEST_NAME
    | ApiJsonSearchOption.TEST_STATUS
    | ApiJsonSearchOption.TEST_SUB_TYPE
    | ApiJsonSearchOption.TEST_TYPE
    | ApiJsonSearchOption.TIME_IN_STATE;

export type TestBulkUpdatePropertyName
    = 'CRITICAL_THRESHOLD'
    | 'FLAP_PREVENTION_WAIT_CYCLES'
    | 'POLLING_INTERVAL'
    | 'SUSPEND'
    | 'TEST_SCHEDULE'
    | 'UNIT'
    | 'USER_ACTION_PROFILE'
    | 'WARNING_THRESHOLD';

export type TestFilters = Partial<Record<TestFilterName, string | undefined>>;

type GetStatusesRequestParams = ApiJsonPagedRequest & ApiJsonSearchedRequest & ApiJsonSortedRequest & {
    fieldsRequested?: TestStatusRequestedField[];
}

interface GetStatusesResponse extends ApiJsonPagedData {
    tests: TestStatus[];
}

export interface TestStatus {
    suspended: boolean;
    actionProfileName: string;
    testScheduleName: string;
    testScheduleSerialNumber: number;
    testInterval: number;
    departmentSerialNumber: number;
    departmentName: string;
    deviceSerialNumber: number;
    deviceName: string;
    testSerialNumber: number;
    testName: string;
    units: string;
    warningThreshold: string;
    criticalThreshold: string;
    monitorInstance: string;
    monitorInstanceUrl: string;
    exportedTest: boolean;
}

export type TestBulkUpdateProperties = Partial<Record<TestBulkUpdatePropertyName, string | undefined>>;

export interface TestBulkUpdateProperty {
    propertyName: TestBulkUpdatePropertyName;
    propertyValue: string;
}

class TestApiService {
    getStatuses(data: GetStatusesRequestParams) {
        return ApiJson.post<ApiJsonResponse<GetStatusesResponse>>('/test/getStatuses', data);
    }

    updateTests(ids: number[], testUpdateProperties: TestBulkUpdateProperty[]) {
        return ApiJson.post<ApiJsonResponse<null>>('/admin/test/update', {
            searchCriterias: [{searchOption: ApiJsonSearchOption.TEST_SERIAL_NUMBER, searchTerms: ids.join(',')}],
            testUpdateProperties
        });
    }

    deleteTests(ids: number[]) {
        return ApiJson.post<ApiJsonResponse<null>>('/admin/test/delete', {
            searchCriterias: [{searchOption: ApiJsonSearchOption.TEST_SERIAL_NUMBER, searchTerms: ids.join(',')}]
        });
    }
}

export default new TestApiService();