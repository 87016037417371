import styles from '../../../clf/LandingTemplate/styles.module.css';
import {useEffect, useState} from 'react';
import TraverseApiService from '../../../services/TraverseApiService';
import {showError} from '../../../common/components/ui/snack-bar/notificationSlice';
import {patchAuthStatus} from '../../../common/components/data/authSlice';
import Checkbox from '../../../clf/form/Checkbox';
import LandingTemplate from '../../../clf/LandingTemplate';
import Title from '../../../clf/Title';
import Text from '../../../clf/Text';
import Label from '../../../clf/form/Label';
import TextInput from '../../../clf/form/TextInput';
import Button from '../../../clf/form/Button';
import {useAppDispatch, useAppSelector} from '../../../store';
import {MfaGlobalSettings} from '../../../services/GlobalConfigurationApiService';
import BackToLogin from '../../../common/components/ui/buttons/BackToLogin';
import {useNavigate} from 'react-router-dom';

const MfaVerification = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [mfaConfig, setMfaConfig] = useState<MfaGlobalSettings>({} as MfaGlobalSettings);
    const [token, setToken] = useState<string>('');
    const [tokenError, setTokenError] = useState<string>('');
    const [remember, setRemember] = useState(false);
    const auth = useAppSelector((state) => state.auth.data.auth);

    const getMfaConfig = () => {
        TraverseApiService.mfa.getGlobalConfig()
            .then((response) => setMfaConfig(response.data.result[0]))
            .catch((response) => dispatch(showError('Failed to load 2FA global config', response)));
    };

    const verifyMfa = () => {
        if (!token) {
            return setTokenError('Can\'t be empty');
        }

        TraverseApiService.mfa.verify(token)
            .then(() => {
                if (auth?.mfaStatus) {
                    dispatch(patchAuthStatus({authenticated: true}));
                }

                if (remember) {
                    TraverseApiService.mfa.rememberDevice()
                        .then(() => {
                            if (auth?.mfaStatus) {
                                dispatch(patchAuthStatus({mfaStatus: {...auth.mfaStatus, trustedDevice: true}}));
                            }
                        })
                        .catch((response) => dispatch(showError('Failed to remember this device', response)));
                }
            })
            .catch((response) => dispatch(showError('Failed to verify authentication code', response)));
    };

    useEffect(() => {
        if (!auth?.representedUser || !auth.mfaStatus?.confirmed || auth.authenticated) {
            return navigate('/');
        } else {
            getMfaConfig();
        }
    }, [auth]);

    useEffect(() => setTokenError(''), [token]);

    return <LandingTemplate onEnterKeyCallback={verifyMfa}>
        <div className={styles.titleBlock}>
            <Title
                variant={'page'}
                style={{textAlign: 'center'}}
            >
                Verification
            </Title>

            <Text style={{color: 'var(--clf-text-color-secondary)', textAlign: 'center'}}>
                Enter the 6-digit code from your 2FA App.
            </Text>
        </div>

        <div className={styles.form}>
            <Label label={'Authentication Code'}>
                <TextInput
                    style={{textAlign: 'center', fontWeight: 'bold'}}
                    value={token || ''}
                    invalid={!!tokenError}
                    onChange={(event) => setToken(event.target.value)}
                    hint={tokenError}
                />
            </Label>

            {mfaConfig.daysToRememberDevice && <Checkbox
                label={`Don't ask again for next ${mfaConfig.daysToRememberDevice} days`}
                checked={remember}
                onChange={() => setRemember(!remember)}
            />}
        </div>

        <div className={styles.form}>
            <Button
                size={'big'}
                onClick={verifyMfa}
            >
                Verify
            </Button>

            <BackToLogin/>
        </div>
    </LandingTemplate>;
};

export default MfaVerification;
