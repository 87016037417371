import {Alert, AlertTitle} from '@mui/material';
import {Slide, Snackbar, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {hideNotification} from './notificationSlice';
import {useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../store';

const useStyles = makeStyles(() => ({
    snackBarSize: {
        minWidth: '15rem'
    },
    title: {
        fontSize: 14
    },
    message: {
        fontSize: 12
    }
}));

const GlobalNotification = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const notification = useAppSelector(state => state.notification);

    const snackbar = useMemo(() => !notification.show ? null : (
        <Snackbar open={true}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            autoHideDuration={4000}
            TransitionComponent={(props) => <Slide {...props} direction="left"/>}
            onClose={() => dispatch(hideNotification())}>
            <Alert
                onClose={() => dispatch(hideNotification())}
                className={classes.snackBarSize}
                severity={notification.severity}
                variant="filled"
                style={{whiteSpace: 'pre-line'}}
            >
                <AlertTitle className={classes.title}>
                    <strong>{notification.message}</strong>
                </AlertTitle>
                <Typography className={classes.message}>
                    {notification.details}
                </Typography>
            </Alert>
        </Snackbar>
    ), [notification]);

    return snackbar;
};

export default GlobalNotification;

