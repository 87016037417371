import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {ComponentSeverity} from '../clf';
import {OrganizationStatus} from '../services/OrganizationApiService';
import {UserGroup} from '../services/AuthApiService';
import {UserInfo} from '../services/UserApiService';
import {
    faBan, faCircleCaretDown,
    faCircleCheck, faCircleExclamation,
    faCircleQuestion,
    faCircleWaveformLines,
    faCircleXmark, faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';

export enum SeverityLevel {
    Unconfigured = 0x00000008,
    Suspended    = 0x00000080,
    Ok           = 0x00000800,
    Transient    = 0x00004000,
    Unknown      = 0x00008000,
    Unreachable  = 0x00080000,
    Warning      = 0x00800000,
    Critical     = 0x08000000,
    Fail         = 0x40000000,
}

export type SeverityName = keyof typeof SeverityLevel;

// todo: bring into the consistency with SeverityName
export interface UserShowSeverities {
    showOk: boolean;
    showTransient: boolean;
    showWarning: boolean;
    showCritical: boolean;
    showSuspended: boolean;
    showUnknown: boolean;
    showUnconfigured: boolean;
    showUnreachable: boolean;
}

export class Severity {
    static getSeverityObjects(): {name: SeverityName, value: number}[] {
        return (Object.entries(SeverityLevel) as [SeverityName, number][])
            .filter(([, value]) => typeof value === 'number')
            .map(([name, value]) => ({name, value}));
    }

    static genSeverityLevel(flags: UserShowSeverities): number {
        let severities = 0;

        if (flags.showOk)
            severities |= SeverityLevel.Ok;
        if (flags.showTransient)
            severities |= SeverityLevel.Transient;
        if (flags.showWarning)
            severities |= SeverityLevel.Warning;
        if (flags.showCritical)
            severities |= SeverityLevel.Critical;
        if (flags.showSuspended)
            severities |= SeverityLevel.Suspended;
        if (flags.showUnconfigured)
            severities |= SeverityLevel.Unconfigured;
        if (flags.showUnreachable)
            severities |= SeverityLevel.Unreachable;
        if (flags.showUnknown)
            severities |= SeverityLevel.Unknown;

        return severities;
    }

    static getSeverityIcon(severity: number): {icon: IconDefinition, severity: ComponentSeverity} {
        switch (severity) {
        case SeverityLevel.Unconfigured:
            return {severity: 'warning',     icon: faBan};
        case SeverityLevel.Suspended:
            return {severity: 'information', icon: faCircleXmark};
        case SeverityLevel.Ok:
            return {severity: 'success',     icon: faCircleCheck};
        case SeverityLevel.Transient:
            return {severity: 'default',     icon: faCircleWaveformLines};
        case SeverityLevel.Unknown:
            return {severity: 'default',     icon: faCircleQuestion};
        case SeverityLevel.Unreachable:
            return {severity: 'information', icon: faCircleCaretDown};
        case SeverityLevel.Warning:
            return {severity: 'warning',     icon: faCircleExclamation};
        case SeverityLevel.Critical:
            return {severity: 'critical',    icon: faTriangleExclamation};
        case SeverityLevel.Fail:
            return {severity: 'critical',    icon: faCircleQuestion};
        default:
            return {severity: 'default',     icon: faCircleQuestion};
        }
    }

    static genEventManagerUrl(group: UserGroup, userInfo: UserInfo) {
        return '/common/jqEventManager.jsp'
            + '?attribute=newTimestamp'
            + '&sortBy=1'
            + '&clearDeviceRegExp=true'
            + '&deviceRegExp='
            + `&accountName=${userInfo.departmentName}`
            + `&accountId=${group === UserGroup.SUPER ? 'all' : userInfo.departmentSerialNumber}`
            + `&severityLevel=${Severity.genSeverityLevel(userInfo.showSeverities)}`
            + '&paging=inactive';
    }

    static genEventManagerUrlForOrganizationStatus(organization: OrganizationStatus) {
        return '/common/jqEventManager.jsp'
            + '?attribute=newTimestamp'
            + '&sortBy=1'
            + '&clearDeviceRegExp=false'
            + '&deviceRegExp='
            + `&accountName=${organization.departmentName}`
            + `&accountId=${organization.departmentSerialNumber}`
            + `&severityLevel=${organization.eventStatus}`
            + '&paging=inactive'
            + '&messageOrigins=message_events';
    }

    static genEventStatusUrl(group: UserGroup, userInfo: UserInfo, severity?: SeverityName) {
        let url = Severity.genEventManagerUrl(group, userInfo);

        if (severity) {
            url = url.replace(/&severityLevel=.*$/, `&severityLevel=${severity ? SeverityLevel[severity] : ''}&archiveOnly=0`);
        }

        return `${url}&messageOrigins=message_events&messageOrigins=test_events`;
    }

    static genDeviceStatusUrl(severity?: SeverityName) {
        return `/html5/DeviceStatus/launch.jsp?severity=${severity ? SeverityLevel[severity] : ''}&filter=false`;
    }
}