import {ReactNode, useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';

interface PortalProps {
    children?: ReactNode;
}

const Portal = ({children}: PortalProps) => {
    const portal = useMemo(() => {
        const portal = document.createElement('div');
        document.body.appendChild(portal);

        return portal;
    }, []);

    useEffect(() => {
        return () => {
            portal?.remove();
        };
    }, [portal]);

    return createPortal(children, portal);
};

export default Portal;