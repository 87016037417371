import styles from './style.module.css';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {genHref, isEmptyUrl} from '../../../../utils/UrlUtils';
import {useAppDispatch, useAppSelector} from '../../../../store';
import {setCurrent as setCurrentMenuItem} from '../LayoutHeader/slice';
import {showError, showSuccess} from '../snack-bar/notificationSlice';

interface IframeMessage {
    type: string;
    payload: FixType;
}

const IframeWrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const [currentHref, setCurrentHref] = useState('about:blank');
    const auth = useAppSelector(({auth}) => auth.data.auth);

    useEffect(() => {
        // add a `postMessage` handler that will server as a bridge between the React app and the content of the iframe

        // TODO separate all this logic from the component
        const handler = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) {
                return console.log(`message from unknown origin ${event.origin}`, event.data);
            }

            try {
                const {type, payload}: IframeMessage = event.data;

                if (type === 'error') {
                    dispatch(showError(payload));
                }

                if (type === 'success') {
                    dispatch(showSuccess(payload));
                }

                if (type === 'navigate') {
                    navigate(payload.replace(/^\/#\//, '/'));
                }
            } catch (e) {
                console.log('unhandled message:', event, e);
            }
        };

        window.addEventListener('message', handler);

        const customWindow = window as FixType;

        // below goes a temporary trash that is needed to prevent the iframe content from throwing various messages and errors
        customWindow.getNavUserIds = () => {
            console.log('!!! remove getNavUserIds call in monitoring');
            return [auth?.loginUser?.userId, auth?.representedUser?.userId].join('.');
        };
        customWindow.isMenuLoaded = () => {
            console.log('!!! remove isMenuLoaded call in monitoring');
            return true;
        };
        customWindow.showMenuById = () => {
            console.log('!!! remove showMenuById call in monitoring');
            return true;
        };
        customWindow.getSavedUISetting = () => {
            console.log('!!! remove getSavedUISetting call in monitoring');
        };
        customWindow.setPageUserIds = () => {
            console.log('!!! remove getSavedUISetting call in monitoring');
        };

        // clean up
        return () => {
            window.removeEventListener('message', handler);
            customWindow.getNavUserIds && delete customWindow.getNavUserIds;
            customWindow.isMenuLoaded && delete customWindow.isMenuLoaded;
            customWindow.showMenuById && delete customWindow.showMenuById;
            customWindow.getSavedUISetting && delete customWindow.getSavedUISetting;
            customWindow.setPageUserIds && delete customWindow.setPageUserIds;
        };
    }, []);

    const [iframeSrc, iframeKey] = useMemo(() => {
        let href = genHref(location, searchParams.toString());

        if (isEmptyUrl(href)) {
            href = 'about:blank';
        }

        setCurrentHref(href);
        return [href, location.key];
    }, [location, searchParams]);

    const objectOnload = useCallback(
        (e: SyntheticEvent<HTMLObjectElement>) => {
            const newLocation = (e.target as HTMLObjectElement).contentDocument?.location;

            if (!newLocation || newLocation.pathname === '/') {
                if (newLocation?.hash) {
                    return navigate(newLocation?.hash.substring(1) ?? '', {replace: true});
                } else {
                    window.location.assign('/');
                }
            }

            const href = newLocation?.href.replace(newLocation?.origin, '') || '';
            if (href && href !== currentHref) {
                history.replaceState(undefined, '', `#${href}`);
                dispatch(setCurrentMenuItem(href));
            }

            setCurrentHref(href);
        },
        [location]
    );

    return iframeSrc.includes('html5/dashboard')
        ? <iframe
            className={styles.iframe}
            key={iframeKey}
            src={iframeSrc}
        />
        : <object
            className={styles.iframe}
            type="text/html"
            key={iframeKey}
            data={iframeSrc}
            onLoad={objectOnload}
        />;
};

export default IframeWrapper;
