import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import LicenseKey, {LicenseScopePermission} from '../../../../utils/LicenseKey';
import {Severity} from '../../../../utils/Severity';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {UserGroup} from '../../../../services/AuthApiService';
import {UserInfo} from '../../../../services/UserApiService';
import {
    faArrowRightArrowLeft,
    faBook, faBuilding,
    faBusinessTime,
    faCabinetFiling, faChartPie,
    faCrown,
    faDatabase, faDiagramProject,
    faEthernet, faFileChartPie,
    faFileWaveform,
    faFolderMagnifyingGlass,
    faGear, faInboxOut,
    faKey,
    faLaptopMobile, faLightbulbExclamationOn,
    faListTree,
    faLocationCrosshairs,
    faMagnifyingGlassChart,
    faMap,
    faPaintbrushFine,
    faPanorama, faRadar,
    faScrewdriverWrench,
    faSensorTriangleExclamation, faUser, faUserGear,
    faUserTag, faWrench,
} from '@fortawesome/pro-regular-svg-icons';
import {faUsers} from '@fortawesome/pro-solid-svg-icons';

export interface NavigationItem {
    name: string;
    title?: string;
    href: string;
    aliases?: string[];
    desc?: string;
    links?: NavigationItem[];
    license?: string[];
    icon?: IconDefinition;
}

interface HeaderSlice {
    menu: NavigationItem[];
    submenu: NavigationItem[];
    curMenu?: string;
    curSubmenu?: string;
    shortName: string;
    fullName: string;
    email?: string;
    iconStyle: string;
    sideMenuCollapsed: boolean;
}

const initialState: HeaderSlice = {
    menu: [],
    submenu: [],
    curMenu: undefined,
    curSubmenu: undefined,
    shortName: 'Guest',
    fullName: 'Guest',
    email: undefined,
    iconStyle: '',
    sideMenuCollapsed: !!localStorage.getItem('sideMenuCollapsed'),
};

const recursiveScopeFilter = (scope: LicenseScopePermission, menu: NavigationItem[] = []): NavigationItem[] => {
    return menu
        .map((i) => ({...i, links: recursiveScopeFilter(scope, i.links)}))
        .filter((i) => LicenseKey.checkScope(scope, i.license) && (i.href || i.links?.length));
};

export const genMenu = (group?: UserGroup, scope?: LicenseScopePermission, userInfo?: UserInfo): NavigationItem[] => {
    if (!group || !scope || !userInfo) {
        return [];
    }

    const statusSection: NavigationItem = {
        name: 'Status',
        href: '/html5/ContainerStatus/launch.jsp',
        links: [
            {
                name: 'Containers',
                icon: faCabinetFiling,
                title: 'Container Summary',
                href: '/html5/ContainerStatus/launch.jsp',
                license: [
                    LicenseKey.key.TEST_CONTAINER,
                    LicenseKey.key.EVENT_MANAGER,
                    LicenseKey.key.NESTED_CONTAINER,
                    LicenseKey.key.SCHEDULED_MAINTENANCE,
                    LicenseKey.key.SEVERITY_RULE_CONTAINER
                ]
            },
            {
                name: 'Devices',
                icon: faLaptopMobile,
                title: 'Device Summary',
                href: '/html5/DeviceStatus/launch.jsp',
                aliases: ['/html5/DeviceDetails/launch.jsp']
            },
            {
                name: 'SLA',
                icon: faBusinessTime,
                title: 'SLA Status Summary',
                href: '/common/slaStatusSummary.jsp',
                license: [
                    LicenseKey.key.SLA_MONITOR
                ]
            },
            {
                name: 'Events',
                icon: faSensorTriangleExclamation,
                title: 'Event manager',
                href: Severity.genEventManagerUrl(group, userInfo),
                aliases: ['/common/jqEventManager.jsp'],
                license: [
                    LicenseKey.key.EVENT_MANAGER
                ]
            },
            {
                name: 'Panorama',
                icon: faPanorama,
                title: 'Panorama',
                href: '/html5/panorama/launch.jsp#/panorama',
                license: [
                    LicenseKey.key.PANORAMA
                ]
            },
            {
                name: 'Maps',
                icon: faMap,
                title: 'Overlay Maps',
                href: '/html5/OverlayMap/launch.jsp',
                license: [
                    LicenseKey.key.NETWORK_MAPS
                ]
            }
        ]
    };

    const dashboardSection: NavigationItem = {
        name: 'Dashboard',
        href: '/html5/dashboard/launch.jsp',
        license: [
            LicenseKey.key.DASHBOARD
        ]
    };

    const superSection: NavigationItem = {
        name: 'Superuser',
        href: '/dge-management',
        links: [
            {
                name: 'DGE Management',
                icon: faDatabase,
                href: '/dge-management',
            },
            {
                name: 'Health',
                icon: faFileWaveform,
                title: 'Component Status',
                href: '/superuser/health'
            },
            {
                name: 'Admin Class',
                icon: faCrown,
                href: '/admin/suAdminGroups.jsp',
                license: [
                    LicenseKey.key.FEDERATED_USER_MODEL
                ],
                aliases: [
                    '/admin/createAdminGroup.jsp',
                    '/admin/updateAdminGroup.jsp',
                    '/admin/deleteAdminGroup.jsp',
                    '/admin/suServiceClassMappings.jsp',
                    '/admin/createUserGroupMapping.jsp',
                    '/admin/updateUserGroupMapping.jsp',
                    '/admin/deleteUserGroupMapping.jsp',
                ]
            },
            {
                name: 'User Class',
                icon: faUserTag,
                href: '/admin/suServiceClasses.jsp',
                license: [
                    LicenseKey.key.FEDERATED_USER_MODEL
                ],
                aliases: [
                    '/admin/createUserGroup.jsp',
                    '/admin/updateUserGroup.jsp',
                    '/admin/deleteUserGroup.jsp',
                ]
            },
            {
                name: 'Global Config',
                icon: faScrewdriverWrench,
                href: '/superuser/',
                aliases: [
                    '/admin/suMessageHandlerManagement.jsp',
                    '/admin/updateMessageHandler.jsp',
                    '/admin/deleteMessageHandler.jsp',
                ]
            }
        ]
    };

    const ncmSection: NavigationItem = {
        name: 'Config Management',
        href: '/ncm/configuration/devices',
        license: [
            LicenseKey.key.CONFIG_MGMT
        ],
        links: [
            {
                name: 'Devices',
                icon: faLaptopMobile,
                title: 'Configure Devices',
                href: '/ncm/configuration/devices'
            },
            {
                name: 'Config Search',
                icon: faFolderMagnifyingGlass,
                title: 'Config Search',
                href: '/ncm/configuration/config-search'
            },
            {
                name: 'Compare Revisions',
                icon: faArrowRightArrowLeft,
                title: 'Compare Revisions',
                href: '/ncm/configuration/compare-revisions'
            },
            {
                name: 'Switch Port Search',
                icon: faEthernet,
                href: '/ncm/tools/closest-switch'
            },
            {
                name: 'ARP Search',
                icon: faLocationCrosshairs,
                href: '/ncm/tools/arp-search'
            },
            {
                name: 'Data Query',
                icon: faMagnifyingGlassChart,
                href: '/ncm/tools/data-query'
            },
            {
                name: 'Credentials',
                icon: faKey,
                href: '/ncm/settings/credentials'
            },
            {
                name: 'Protocols',
                icon: faListTree,
                href: '/ncm/settings/protocols'
            },
            {
                name: 'Settings',
                icon: faGear,
                href: '/ncm/settings/other'
            }
        ]
    };

    const reportsSection: NavigationItem = {
        name: 'Reports',
        href: '/common/quickReports.jsp',
        links: [
            {
                name: 'Advanced',
                icon: faBook,
                href: '/common/quickReports.jsp',
                license: [
                    LicenseKey.key.ADVANCED_REPORTS
                ],
                aliases: [
                    '/common/quickReport.do'
                ]
            },
            {
                name: 'Custom',
                icon: faPaintbrushFine,
                href: `/${group === UserGroup.USER ? 'user' : 'admin'}/customReports.jsp`,
                license: [
                    LicenseKey.key.CUSTOM_REPORTS
                ],
                aliases: [
                    '/admin/faultReportInput.jsp',
                    '/admin/performanceReportInput.jsp',
                    '/admin/eventLogInput.jsp',
                    '/admin/messageLogInput.jsp',
                    '/admin/availabilityReportInput.jsp',
                    '/admin/inventoryReport.jsp',
                    '/admin/showLogins.jsp',
                    '/common/eventAckInput.jsp',
                    '/admin/faultReport.do',
                    '/admin/performanceReport.do',
                    '/admin/eventLog.do',
                    '/admin/messageLog.do',
                    '/admin/availabilityReport.do',
                    '/admin/deviceDataReport.do',
                    '/common/eventAck.do'
                ]
            },
            {
                name: 'SLA',
                icon: faBusinessTime,
                href: '/common/slaReportInput.jsp',
                license: [
                    LicenseKey.key.SLA_MONITOR
                ],
                aliases: [
                    '/common/slaReport.do'
                ]
            },
            {
                name: 'My Reports',
                icon: faChartPie,
                title: 'My Reports Management',
                href: '/reports/my-reports',
                license: [
                    LicenseKey.key.ADHOC_REPORTS
                ],
                aliases: [
                    '/user/generateReport.do'
                ]
            },
            {
                name: 'Visualizer',
                icon: faUser,
                href: '/common/listVisualizer.jsp',
                license: [
                    LicenseKey.key.INTEROP_SEV
                ]
            },
            {
                name: 'Emailed',
                icon: faInboxOut,
                title: 'Scheduled Reports Management',
                href: '/reports/scheduled-reports',
                license: [
                    LicenseKey.key.SCHEDULED_REPORTS
                ]
            }
        ]
    };

    const adminSection: NavigationItem = {
        name: 'Administration',
        href: '/administration/devices',
        links: [
            {
                name: 'Devices',
                icon: faLaptopMobile,
                title: 'Device Management',
                href: '/administration/devices',
                aliases: [
                    '/user/baselineManagement.jsp',
                    '/user/manageMonitorConfigs.jsp',
                ]
            },
            {
                name: 'Tests',
                icon: faFileChartPie,
                title: 'Test Management',
                href: '/administration/tests',
                aliases: [
                    '/common/updateBatchTest.do',
                    '/common/addStandardTests.jsp',
                    '/common/configureCustomTest.jsp',
                    '/common/updateTest.jsp',
                    '/user/updateTest.jsp',
                ]
            },
            {
                name: 'Containers',
                icon: faCabinetFiling,
                title: 'Container Management',
                href: '/html5/ContainerAdmin/launch.jsp',
                license: [
                    LicenseKey.key.TEST_CONTAINER,
                    LicenseKey.key.EVENT_MANAGER,
                    LicenseKey.key.NESTED_CONTAINER,
                    LicenseKey.key.SCHEDULED_MAINTENANCE,
                    LicenseKey.key.SEVERITY_RULE_CONTAINER
                ]
            },
            {
                name: 'SLA',
                icon: faBusinessTime,
                title: 'SLA Management',
                href: '/common/manageSLAMeasurements.do',
                license: [
                    LicenseKey.key.SLA_MONITOR
                ],
                aliases: [
                    '/common/createSLAMeasurement.jsp',
                    '/common/deleteSLAMeasurement.jsp',
                    '/common/updateSLAMeasurement.jsp',
                    '/common/updateSLAValue.jsp',
                ]
            },
            {
                name: 'Actions',
                icon: faLightbulbExclamationOn,
                title: 'Action Management',
                href: `/${group === UserGroup.USER ? 'user' : 'admin'}/manageActions.jsp`,
                license: [
                    LicenseKey.key.APPLICATION_PROFILE
                ],
                aliases: [
                    '/user/createActionProfile.jsp',
                    '/user/deleteActionProfile.jsp',
                    '/user/updateActionProfile.jsp',
                    '/admin/createActionProfile.jsp',
                    '/admin/deleteActionProfile.jsp',
                    '/admin/updateActionProfile.jsp',
                    '/user/selectDevicesForMessageAction.jsp',
                    '/user/assignActionProfileSetup.do'
                ]
            },
            {
                name: 'Automation',
                icon: faDiagramProject,
                title: 'Template Management',
                href: '/administration/templates',
                aliases: [
                    '/common/updateDeviceTemplate.jsp',
                ]
            },
            {
                name: 'Discovery',
                icon: faRadar,
                title: 'Discovery Management',
                href: '/administration/discovery',
                aliases: [
                    '/common/csvServerDiscovery.jsp',
                ]
            },
            {
                name: 'Other',
                icon: faWrench,
                href: `/${group === UserGroup.USER ? 'user' : 'admin'}/manageLinks.jsp`,
                aliases: [
                    '/html5/MonitorConfigAdmin/launch.jsp',
                    '/common/manageScheduledMaintenance.jsp',
                    '/common/createScheduledMaintenance.jsp',
                    '/common/updateScheduledMaintenance.jsp',
                    '/common/assignScheduledMaintenance.do',
                    '/common/deleteScheduledMaintenance.jsp',
                    '/common/disableScheduledMaintenance.do',
                    '/admin/manageSchedules.jsp',
                    '/admin/createSchedule.jsp',
                    '/admin/updateSchedule.jsp',
                    '/admin/deleteSchedule.jsp',
                    '/common/eventRuleEditor.jsp',

                    '/user/manageSchedules.jsp',
                    '/user/createSchedule.jsp',
                    '/user/updateSchedule.jsp',
                    '/user/deleteSchedule.jsp',
                    '/user/assignScheduleSetup.do',

                    '/user/manageApplicationProfiles.jsp',
                    '/user/modifyApplicationProfile.jsp',
                    '/user/manageApplicationProfilesAction.do',
                    '/user/updateTestRediscoveryOptions.jsp',
                    '/user/updateConfigBackupOptions.jsp',
                    '/user/manageMessageFilters.jsp',
                    '/user/manageDeviceAliases.jsp',
                    '/user/manageMessageNotifications.jsp',

                    '/user/manageSubnets.jsp',
                    '/user/createSubnet.jsp',
                    '/user/updateSubnet.jsp',
                    '/user/deleteSubnet.jsp',
                    '/user/manageDeepWebTestScripts.jsp',
                    '/user/getDeepWebProxy.jsp',
                    '/user/getDeepWebFirstUrl.jsp',
                    '/common/nextSteps.jsp',
                ]
            },
            {
                name: 'User Settings',
                icon: faUserGear,
                title: 'User Settings',
                href: '/administration/user/information',
                aliases: [
                    '/administration/user/settings'
                ]
            }
        ]
    };

    if (group !== UserGroup.USER) {
        statusSection.links?.splice(1, 0, {
            name: 'Organizations',
            icon: faBuilding,
            title: 'Organization Summary',
            href: '/status/organizations'
        });

        adminSection.href = '/administration/organizations';
        adminSection.links?.unshift({
            name: 'Organizations',
            icon: faBuilding,
            title: 'Organization Management',
            href: '/administration/organizations',
            aliases: [
                '/admin/configureLogoTheme.jsp'
            ]
        }, {
            name: 'Users',
            icon: faUsers,
            title: 'User Management',
            href: '/administration/users'
        });
        adminSection.links?.splice(-2, 0, {
            name: 'User Class',
            icon: faUserTag,
            href: '/admin/manageServiceClasses.jsp',
            license: [
                LicenseKey.key.FEDERATED_USER_MODEL
            ],
            aliases: [
                '/admin/updateGroupDefaultThresholds.jsp',
                '/admin/updateGroupPrivileges.jsp',
                '/admin/updateGroupShadowThresholds.jsp',
                '/admin/manageUserGroupActionProfiles.jsp',
                '/admin/createUserGroupActionProfile.jsp',
            ]
        });
    }

    const menu: NavigationItem[] = [
        statusSection,
        dashboardSection,
        ncmSection,
        reportsSection,
        adminSection,
        ...(group == UserGroup.SUPER ? [superSection] : []),
    ];

    return recursiveScopeFilter(scope, menu);
};

const isMenuCurrent = (menuItem: NavigationItem, url: string) => {
    return url.startsWith(menuItem.href) || menuItem.aliases?.some((alias) => url.startsWith(alias));
};

const headerSlice = createSlice({
    name: 'headerSlice',
    initialState,
    reducers: {
        setUser: (state, {payload}) => {
            state.shortName = payload.shortName;
            state.fullName = payload.fullName;
            state.email = payload.email;
            state.iconStyle = payload.iconStyle;
        },
        setCurrent: (state, {payload}: PayloadAction<string>) => {
            let newMainSel = undefined;
            let newSubSel = undefined;
            let newSubmenu = undefined;
            let newTitle = 'Traverse';

            for (const main of state.menu) {
                if (isMenuCurrent(main, payload)) {
                    newSubmenu = main.links;
                    newMainSel = main.href;
                    newTitle = `Traverse - ${main.title || main.name}`;
                }

                const subItem = main.links?.find((i) => isMenuCurrent(i, payload));

                if (subItem) {
                    newSubmenu = main.links;
                    newMainSel = main.href;
                    newSubSel = subItem.href;
                    const subTitle = subItem.title || `${main.name} - ${subItem.name}`;
                    newTitle = `Traverse - ${subTitle}`;
                }
            }

            if (newMainSel) {
                state.curMenu = newMainSel;
                state.curSubmenu = newSubSel;
                state.submenu = newSubmenu ?? [];
            }

            document.title = newTitle;
        },
        setMenu: (state, {payload}: PayloadAction<{group?: UserGroup, scope?: LicenseScopePermission, userInfo?: UserInfo}>) => {
            state.menu = genMenu(payload.group, payload.scope, payload.userInfo);
        },
        setSubmenu: (state, {payload}) => {
            state.submenu = payload;
        },
        toggleSideMenuCollapsed: (state) => {
            state.sideMenuCollapsed = !state.sideMenuCollapsed;
            localStorage.setItem('sideMenuCollapsed', state.sideMenuCollapsed ? '1' : '');
        },
    }
});

export const {setCurrent, setMenu, setUser, toggleSideMenuCollapsed} = headerSlice.actions;

export default headerSlice.reducer;