import LandingTemplate from '../../clf/LandingTemplate';
import styles from '../../clf/LandingTemplate/styles.module.css';
import Button from '../../clf/form/Button';
import Title from '../../clf/Title';
import Label from '../../clf/form/Label';
import TextInput from '../../clf/form/TextInput';
import {useEffect, useState} from 'react';
import {showError, showSuccess} from '../../common/components/ui/snack-bar/notificationSlice';
import {useAppDispatch, useAppSelector} from '../../store';
import Text from '../../clf/Text';
import {patchAuthStatus} from '../../common/components/data/authSlice';
import TraverseApiService from '../../services/TraverseApiService';
import BackToLogin from '../../common/components/ui/buttons/BackToLogin';
import {useNavigate} from 'react-router-dom';

const EmailVerification = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector(({auth}) => auth.data.auth);
    const [hasCode, setHasCode] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);

    const verify = () => {
        setLoading(true);
        TraverseApiService.user.verify(emailAddress, code)
            .then(() => {
                dispatch(patchAuthStatus({emailVerified: true}));
                dispatch(showSuccess('Email verified!'));
            })
            .catch((response) => dispatch(showError('Failed to verify', response)))
            .finally(() => setLoading(false));
    };

    const sendCode = () => {
        setLoading(true);
        TraverseApiService.user.sendCode(emailAddress)
            .then(() => {
                dispatch(showSuccess(`Verification code is sent to ${emailAddress}`));
                setHasCode(true);
            })
            .catch((response) => dispatch(showError('Failed to send verification code', response)))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!auth?.authenticated || auth.emailVerified) {
            navigate('/');
        } else {
            setEmailAddress(auth.representedUser?.emailAddress ?? '');
        }
    }, [auth]);

    return <LandingTemplate onEnterKeyCallback={() => hasCode ? verify : sendCode}>
        <div className={styles.titleBlock}>
            <Title
                variant={'page'}
                style={{textAlign: 'center'}}
            >
                Verify your email address
            </Title>

            <Text style={{color: 'var(--clf-text-color-secondary)', textAlign: 'center'}}>
                Enter email address and related verification code.
            </Text>
        </div>

        <form
            id={'verificationForm'}
            className={styles.form}
            onSubmit={(e) => {
                e.preventDefault();

                const action = (e.nativeEvent as SubmitEvent).submitter?.getAttribute('name');

                if (action === 'next') {
                    setHasCode(true);
                } else if (action === 'send' || (!action && !hasCode)) {
                    sendCode();
                } else if (action === 'verify' || (!action && hasCode)) {
                    verify();
                }
            }}
        >
            <Label label={'Email Address'}>
                <TextInput
                    key={`${hasCode}`}
                    autoFocus={!hasCode}
                    readOnly={loading || hasCode}
                    required
                    type="email"
                    value={emailAddress}
                    onChange={(event) => setEmailAddress(event.target.value)}
                />
            </Label>

            {hasCode && <Label label={'Verification Code'}>
                <TextInput
                    style={{textAlign: 'center', fontWeight: 700}}
                    readOnly={loading}
                    autoFocus={hasCode}
                    required
                    pattern={'^\\d{6}$'}
                    value={code}
                    onChange={(event) => setCode(event.target.value.replace(/\D/g, '').slice(0, 6))}
                />
            </Label>}

            <div/>

            {!hasCode && <>
                <Button
                    size={'big'}
                    name={'send'}
                    loading={loading}
                >
                    Send Verification Code
                </Button>

                <Button
                    size={'big'}
                    name={'next'}
                    variant={'secondary'}
                    disabled={loading}
                >
                    Verify Using Code
                </Button>
            </>}

            {hasCode && <>
                <Button
                    size={'big'}
                    name={'verify'}
                    loading={loading}
                >
                    Verify
                </Button>

                <Button
                    size={'big'}
                    variant={'secondary'}
                    disabled={loading}
                    onClick={() => {
                        setCode('');
                        setHasCode(false);
                    }}
                >
                    Re-Send Verification Code
                </Button>
            </>}

            <BackToLogin/>
        </form>
    </LandingTemplate>;
};

export default EmailVerification;