import ApiJson, {ApiJsonResponse} from './ApiJson';
import ApiV1, {ApiV1Response} from './ApiV1';

export type LicenseStatus = {
    expired: boolean;
}

export type LicenseScope = {
    licensedFeatures: Record<string, boolean>;
    licensedMessageSources: Record<string, boolean>;
    licensedMonitors: Record<string, boolean>;
}

export type LicenseAuditReport = {
    lastAuditDate: string;
    licenseAuditRecords: LicenseAuditRecord[];
}

export type LicenseAuditRecord = {
    id: string;
    parentId: string | null;
    name: string;
    entitled: string;
    actual: string;
    compliant: string;
}

export type LicenseAvailableDevices = {
    availableDevices: number;
}

class LicenseApiService {
    getLicenseStatus() {
        return ApiV1.get<ApiV1Response<LicenseStatus[]>>('/license/status');
    }

    getLicenseScope() {
        return ApiV1.get<ApiV1Response<LicenseScope[]>>('/license/scope');
    }

    getLicenseAuditReport() {
        return ApiV1.get<ApiV1Response<LicenseAuditReport[]>>('/license/audit-report');
    }

    getAvailableDevices() {
        return ApiJson.post<ApiJsonResponse<LicenseAvailableDevices>>('/license/getAvailableDevices', {});
    }
}

export default new LicenseApiService();